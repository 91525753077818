import React from "react";
import "./homebody.css";
import journalImage from "../assets/journal.png";
import submitImage from "../assets/submit.png";
import conferenceImage from "../assets/conference.png";
import libraryImage from "../assets/library.png";
import journal from "../assets/book.png";
import libraryCard from "../assets/librarycard.png";
import { Link } from "react-router-dom";

class HomeBody extends React.Component {
    render() {
        return (
            <div>
                <div className="container mt-5">
                    <div className="card p-20">
                        <div className="card-body">
                            <h2 className="card-title text-center">
                                Kanzuliman Research at a glance
                            </h2>
                            <hr className="mt-4" />
                            <p className="card-text">
                                At Kanzuliman Foundation we ensure the creation
                                of an arena that supplements the knowledge world
                                with a high grade quality of publication of peer
                                reviewed and refereed international journal from
                                diverse fields. The major objectives of our
                                research divison are to establish a platform for
                                keen researchers, learned academicians,
                                professional, practitioners and students to
                                generate and create knowledge in the form of
                                well-articulated, quality wise empirical and
                                theoretical research papers, case studies,
                                literature reviews and book reviews.
                            </p>
                            <hr className="mt-4" />
                            <div className="text-center">
                                <div className="row mt-3">
                                    <div className="col">
                                        <img
                                            src={journalImage}
                                            alt=""
                                            height="50"
                                        />
                                    </div>
                                    <div className="col">
                                        <img
                                            src={submitImage}
                                            alt=""
                                            height="50"
                                        />
                                    </div>
                                    <div className="col">
                                        <img
                                            src={libraryImage}
                                            alt=""
                                            height="50"
                                        />
                                    </div>
                                    <div className="col">
                                        <img
                                            src={conferenceImage}
                                            alt=""
                                            height="50"
                                        />
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className="col">
                                        <Link
                                            to="/research/papers"
                                            className="card-link link-color-black"
                                        >
                                            <h6>Research Journal</h6>
                                        </Link>
                                    </div>
                                    <div className="col">
                                        <Link
                                            className="card-link link-color-black"
                                            to="/authors/online-paper-submission"
                                        >
                                            <h6>Submit Research</h6>
                                        </Link>
                                    </div>
                                    <div className="col">
                                        <a
                                            href="https://www.kanzuliman.org"
                                            className="card-link link-color-black"
                                        >
                                            <h6>Research Library</h6>
                                        </a>
                                    </div>
                                    <div className="col">
                                        <Link
                                            to="/conferences"
                                            className="card-link link-color-black"
                                        >
                                            <h6>Academic Conference</h6>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="card-alt p-20">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-9 pt-5">
                                    <h3 className="pt-4">
                                        World’s first academic research journal
                                        on the subject of Razawiyāt
                                    </h3>
                                    <h5 className="pt-2">
                                        Kanzuliman Academic International
                                        Journal on Razawiyāt
                                    </h5>
                                    <p className="card-text">
                                        Razawiyāt – a field of study that deals
                                        with the research and development on
                                        Sunni Islam in the light of ideas,
                                        ideology and writings of Aalahazrat Imam
                                        Ahmad Raza Khan Fazil-e-Barelvi
                                        <span className="urdu-20">
                                            {" "}
                                            رَضِيَ ٱللّٰهُ عَنْهُ‎.
                                        </span>{" "}
                                        Garnering academic excellence in the
                                        field of Razawiyāt, Kanzuliman
                                        Foundation has set up world’s first
                                        research library on this subject. We
                                        invite students, academic scholars and
                                        industry professionals to collaborate
                                        with our research team for projects on
                                        Razawiyāt.
                                    </p>
                                </div>
                                <div className="col">
                                    <img
                                        src={journal}
                                        alt=""
                                        width="100%"
                                        height="120%"
                                        className="image-left"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="card p-20">
                        <div className="card-body">
                            <h5 className="card-title text-center">
                                SUBMIT RESEARCH
                            </h5>
                            <h3 className="card-title">Call for Papers</h3>
                            <p className="card-text">
                                Kanzuliman Publication team is delighted to
                                announce call for multi-lingual research, review
                                papers and short communications for the first
                                issue of Kanzuliman Academic International
                                Journal on Razawiyāt (KAIJOR) I Online: ISSN:
                                (Applied)
                            </p>
                            <div className="row mt-4">
                                <div className="col">
                                    <Link
                                        to="/authors/guidelines"
                                        className="card-link link-color-black"
                                    >
                                        <h6>View Guidelines</h6>
                                    </Link>
                                </div>
                                <div className="col">
                                    <Link
                                        to="/authors/online-paper-submission"
                                        className="card-link link-color-black"
                                    >
                                        <h6>Submit Paper</h6>
                                    </Link>
                                </div>
                                <div className="col">
                                    <h6>Research Helpline</h6>
                                    <p>+91 9506215324 or +91-884-059-3363</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="card-alt p-20">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-7 pt-4">
                                    <h3 className="pt-4">
                                        LIBRARY ON RAZAWIYĀT AND CORRESPONDING
                                        SUBJECTS
                                    </h3>
                                    <h5 className="pt-2">
                                        Kanzuliman Academic International
                                        Journal on Razawiyāt
                                    </h5>
                                    <p className="card-text">
                                        To support the global academic community
                                        with authentic content, literature, and
                                        research material Kanzuliman research
                                        brings you an open access online and
                                        offline research library at our HQ.
                                    </p>
                                    <h6>What can I expect in this library?</h6>
                                    <p>
                                        Research Papers, Ph.D thesis,
                                        Periodicals and Journals
                                    </p>

                                    <h6>Who all can access?</h6>
                                    <p>
                                        Scholars, Academicians, Professionals
                                        and Students
                                    </p>

                                    <h6>How can i get access credentials?</h6>
                                    <p>
                                        Submit a request for login
                                        <span className="text-blue">
                                            kaijor.kanzuliman@gmail.com
                                        </span>
                                    </p>

                                    <h6>Is there a fee?</h6>
                                    <p>
                                        Access to library is open for
                                        individuals with genuine case requests.
                                    </p>
                                </div>
                                <div className="col pt-5 pl-4 ml-5">
                                    <img
                                        src={libraryCard}
                                        alt=""
                                        width="105%"
                                        height="100%"
                                        className="image-left"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <br />
                    <br />
                    <div className="card p-20">
                        <div className="card-body">
                            <h4 className="card-title text-center">
                                ACADEMIC CONFERENCE
                            </h4>
                            {/* <h3 className="card-title">Call for Papers</h3>     */}
                            <p className="card-text text-center">
                                Garnering academic excellence we invite
                                scholars, academicians, professionals and
                                students to our <br />
                                <b>
                                    {" "}
                                    <i>
                                        Annual Academic Conference on Razawiyāt
                                    </i>
                                </b>
                                <br />
                                <hr width="50%" />
                                <br />
                                Our research activities are highly standard in
                                nature and goverened by the rules of academic
                                publication standards. Over the span of years
                                since we started this academic initiative, there
                                have been significant progress. Here’s a glimpse
                                of our academic achivements in the field of{" "}
                                <i>Razawiyāt</i>
                            </p>
                            <div className="row mt-4 text-center">
                                <div className="col">
                                    <p className="large-font">100+</p>
                                    <span className="color-coral">
                                        <b>RESEARCH PAPERS</b>
                                    </span>
                                </div>
                                <div className="col">
                                    <p className="large-font">50+</p>
                                    <span className="color-coral">
                                        <b>PHD THESIS</b>
                                    </span>
                                </div>
                                <div className="col">
                                    <p className="large-font">10+</p>
                                    <span className="color-coral">
                                        <b>ACADEMIC CONFERENCES</b>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default HomeBody;
