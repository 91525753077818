import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";

import Number from "../../../formGroup/mobile";
import Files from "../../../formGroup/files";

import Field from "../../../formGroup/Field";
import Datetime from "../../../formGroup/datetime";
import countries from "../../../formGroup/utilities/countries";
import { db, storage } from "../../../../firebase";
import { v4 } from "uuid";

import Utils from "../../../authors/utilities";

export class EditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.data.name,
            address: this.props.data.address,
            city: this.props.data.city,
            state: this.props.data.state,
            country: this.props.data.country,
            email: this.props.data.email,
            email1: this.props.data.email1,
            email2: this.props.data.email2,
            number:
                this.props.data.mobile !== ""
                    ? this.props.data.mobile.split(" ")[1]
                    : "",
            number1:
                this.props.data.mobile1 !== ""
                    ? this.props.data.mobile1.split(" ")[1]
                    : "",
            number2:
                this.props.data.mobile2 !== ""
                    ? this.props.data.mobile2.split(" ")[1]
                    : "",
            code:
                this.props.data.mobile !== ""
                    ? this.props.data.mobile.split(" ")[0]
                    : "",
            code1:
                this.props.data.mobile1 !== ""
                    ? this.props.data.mobile1.split(" ")[0]
                    : "",
            code2:
                this.props.data.mobile2 !== ""
                    ? this.props.data.mobile2.split(" ")[0]
                    : "",
            affiliation: this.props.data.institute,
            profession: this.props.data.profession,
            areaOfStudy: this.props.data.areaOfStudy,
            title: this.props.data.title,
            language: this.props.data.language,
            type: this.props.data.type,
            abstract: this.props.data.abstract,
            keywords: this.props.data.keywords,
            refrences: this.props.data.refrences,
            conclusion: this.props.data.conclusion,
            pdfFile: "",
            wordFile: "",
            reviewerForm: "",
            copyrightForm: "",
            disable: false,
            showError: false,
            showSpinner: false,
            DOI: this.props.data.DOI,
            paperID: this.props.data.paperID,
            releasedWith: this.props.data.releasedWith,
            downloadLink: this.props.data.downloadLink,
            revisionDate: this.props.data.revisionDate || undefined,
            acceptedDate: this.props.data.acceptedDate || "NA",
            timestamp: this.props.data.timestamp,
            digiproveURL: this.props.data.digiproveURL,
            pages: this.props.data.pages,
        };
    }

    eventHandler = async () => {
        await this.setState({ disable: true, showSpinner: true });
        try {
            const requestSent = {
                name: this.state.name,
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                email: this.state.email,
                email1: this.state.email1,
                email2: this.state.email2,
                mobile: `${this.state.code} ${this.state.number}`,
                mobile1: `${this.state.code1} ${this.state.number1}`,
                mobile2: `${this.state.code2} ${this.state.number2}`,
                profession: this.state.profession,
                institute: this.state.affiliation,
                areaOfStudy: this.state.areaOfStudy,
                title: this.state.title,
                language: this.state.language,
                type: this.state.type,
                abstract: this.state.abstract,
                keywords: this.state.keywords,
                refrences: this.state.refrences,
                conclusion: this.state.conclusion,
                pdfFile: this.props.data.pdfFile
                    ? this.props.data.pdfFile.name
                    : "",
                wordFile: this.props.data.wordFile
                    ? this.props.data.wordFile.name
                    : "",
                reviewerForm: this.props.data.reviewerForm
                    ? this.props.data.reviewerForm.name
                    : "",
                copyrightForm: this.props.data.copyrightForm
                    ? this.props.data.copyrightForm.name
                    : "",
                DOI: this.state.DOI,
                paperID: this.state.paperID,
                status: this.props.data.status,
                releasedWith: this.state.releasedWith,
                downloadLink: this.state.downloadLink,
                revisionDate: this.state.revisionDate || Date.now(),
                acceptedDate: this.state.acceptedDate,
                timestamp: this.state.timestamp,
                digiproveURL: this.state.digiproveURL,
                pages: this.state.pages,
            };

            if (requestSent.acceptedDate === undefined)
                delete requestSent.acceptedDate;

            /// checking and updating if new pdf file is choosed
            if (
                this.state.pdfFile !== "" &&
                !Utils.invalidFile(this.state.pdfFile, [".pdf"])
            ) {
                let pdfFile = new File(
                    [this.state.pdfFile],
                    v4() + Utils.getFileExtension(this.state.pdfFile.name)
                );
                requestSent.pdfFile = pdfFile.name;
                await storage
                    .ref()
                    .child(`archives/PdfFile/${pdfFile.name}`)
                    .put(pdfFile, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(pdfFile.name)
                        ),
                    });
                try {
                    if (
                        this.props.data.pdfFile !== undefined &&
                        this.props.data.pdfFile !== ""
                    ) {
                        await storage
                            .ref()
                            .child(
                                `archives/PdfFile/${this.props.data.pdfFile}`
                            )
                            .delete();
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                requestSent.pdfFile = this.props.data.pdfFile;
            }

            /// checking and updating if new word file is choosed
            if (
                this.state.wordFile !== "" &&
                !Utils.invalidFile(this.state.wordFile, [".docx", ".doc"])
            ) {
                let wordFile = new File(
                    [this.state.wordFile],
                    v4() + Utils.getFileExtension(this.state.wordFile.name)
                );
                requestSent.wordFile = wordFile.name;
                await storage
                    .ref()
                    .child(`archives/wordFile/${wordFile.name}`)
                    .put(wordFile, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(wordFile.name)
                        ),
                    });
                try {
                    if (
                        this.props.data.wordFile !== undefined &&
                        this.props.wordFile !== ""
                    ) {
                        await storage
                            .ref()
                            .child(
                                `archives/wordFile/${this.props.data.wordFile}`
                            )
                            .delete();
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                requestSent.wordFile = this.props.data.wordFile;
            }

            /// checking and updating if new copyright form file is choosed
            if (
                this.state.copyrightForm !== "" &&
                !Utils.invalidFile(this.state.copyrightForm, [
                    ".docx",
                    ".pdf",
                    ".doc",
                ])
            ) {
                let copyrightForm = new File(
                    [this.state.copyrightForm],
                    v4() + Utils.getFileExtension(this.state.copyrightForm.name)
                );
                requestSent.copyrightForm = copyrightForm.name;
                await storage
                    .ref()
                    .child(`archives/copyrightForm/${copyrightForm.name}`)
                    .put(copyrightForm, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(copyrightForm.name)
                        ),
                    });
                try {
                    if (
                        this.props.data.copyrightForm !== undefined &&
                        this.props.data.copyrightForm !== ""
                    ) {
                        await storage
                            .ref()
                            .child(
                                `archives/copyrightForm/${this.props.data.copyrightForm}`
                            )
                            .delete();
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                requestSent.copyrightForm = this.props.data.copyrightForm;
            }

            /// checking and updating if new reviewer form file is choosed
            if (
                this.state.reviewerForm !== "" &&
                !Utils.invalidFile(this.state.reviewerForm, [
                    ".docx",
                    ".pdf",
                    ".doc",
                ])
            ) {
                let reviewerForm = new File(
                    [this.state.reviewerForm],
                    v4() + Utils.getFileExtension(this.state.reviewerForm.name)
                );
                requestSent.reviewerForm = reviewerForm.name;
                await storage
                    .ref()
                    .child(`archives/reviewerForm/${reviewerForm.name}`)
                    .put(reviewerForm, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(reviewerForm.name)
                        ),
                    });
                try {
                    if (
                        this.props.data.reviewerForm !== undefined &&
                        this.props.data.reviewerForm !== ""
                    ) {
                        await storage
                            .ref()
                            .child(
                                `archives/reviewerForm/${this.props.data.reviewerForm}`
                            )
                            .delete();
                    }
                } catch (err) {
                    console.log(err);
                }
            } else {
                requestSent.reviewerForm = this.props.data.reviewerForm;
            }
            /// updating firestore database as per the new entries
            await db
                .collection("Archives")
                .doc(this.props.data.id)
                .set(requestSent);

            this.props.update();
            await this.setState({
                disable: false,
                showError: false,
                showSpinner: false,
            });
            this.props.hide();
        } catch (err) {
            await this.setState({
                disable: false,
                showError: true,
                showSpinner: false,
            });
        }
    };

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hide}
                centered
                scrollable
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className={this.props.data.language}>
                        Edit {this.props.data.title}'s details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Field
                            cols="6"
                            validate={false}
                            onData={(name) => this.setState({ name })}
                            value={this.state.name}
                            label="Name "
                            type="text"
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(email) => this.setState({ email })}
                            label="Email Address "
                            value={this.state.email}
                            type="email"
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(email1) => this.setState({ email1 })}
                            label="Email Address 1"
                            value={this.state.email1}
                            type="email"
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(email2) => this.setState({ email2 })}
                            label="Email Address 2"
                            value={this.state.email2}
                            type="email"
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Number
                            onNumber={(number) => this.setState({ number })}
                            onCode={(code) => this.setState({ code })}
                            cols="6"
                            validate={false}
                            dataNumber={this.state.number}
                            dataCode={this.state.code}
                            emitError={(x) => x}
                        />
                        <Number
                            onNumber={(number1) => this.setState({ number1 })}
                            onCode={(code1) => this.setState({ code1 })}
                            cols="6"
                            validate={false}
                            dataNumber={this.state.number1}
                            dataCode={this.state.code1}
                            emitError={(x) => x}
                        />
                        <Number
                            onNumber={(number2) => this.setState({ number2 })}
                            onCode={(code2) => this.setState({ code2 })}
                            cols="6"
                            validate={false}
                            dataNumber={this.state.number2}
                            dataCode={this.state.code2}
                            emitError={(x) => x}
                        />
                        <Datetime
                            label="Submission Date"
                            cols="6"
                            datetime={this.state.timestamp}
                            onData={(timestamp) => this.setState({ timestamp })}
                        />
                        <Datetime
                            label="Accepted Date"
                            cols="6"
                            datetime={this.state.acceptedDate}
                            onData={(acceptedDate) =>
                                this.setState({ acceptedDate })
                            }
                        />
                        <Datetime
                            label="Revision Date"
                            cols="6"
                            datetime={this.state.revisionDate}
                            onData={(revisionDate) =>
                                this.setState({ revisionDate })
                            }
                        />
                        <Field
                            cols="12"
                            validate={false}
                            onData={(address) => this.setState({ address })}
                            value={this.state.address}
                            onChange={(x) => x}
                            label="Address *"
                            validations={[]}
                            placeholder="Address "
                            type="text"
                        />
                        <Field
                            cols="4"
                            validate={false}
                            onData={(city) => this.setState({ city })}
                            value={this.state.city}
                            onChange={(x) => x}
                            label="City*"
                            validations={[]}
                            type="text"
                        />
                        <Field
                            cols="4"
                            validate={false}
                            onData={(state) => this.setState({ state })}
                            value={this.state.state}
                            onChange={(x) => x}
                            label="State *"
                            validations={[]}
                            type="text"
                        />
                        <Field
                            cols="4"
                            validate={false}
                            onData={(country) => this.setState({ country })}
                            value={this.state.country}
                            onChange={(x) => x}
                            label="Country *"
                            validations={[]}
                            type="select"
                            options={countries}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(affiliation) =>
                                this.setState({ affiliation })
                            }
                            label="Affiliation (University/College/Company)"
                            type="text"
                            value={this.state.affiliation}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(profession) =>
                                this.setState({ profession })
                            }
                            label="Profession"
                            type="text"
                            value={this.state.profession}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(areaOfStudy) =>
                                this.setState({ areaOfStudy })
                            }
                            value={this.state.areaOfStudy}
                            label="Area of Study"
                            type="text"
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(releasedWith) =>
                                this.setState({ releasedWith })
                            }
                            value={this.state.releasedWith}
                            label="Released In"
                            type="select"
                            options={this.props.arrayJournalRelease}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(type) => this.setState({ type })}
                            value={this.state.type}
                            label="Manuscript Type"
                            type="select"
                            options={[
                                "Research Book",
                                "Case Study",
                                "Review",
                                "Research Thesis",
                                "Research Paper",
                                "Special Issue",
                                "Other",
                            ]}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(title) => this.setState({ title })}
                            type="text"
                            label="Manuscript Title "
                            value={this.state.title}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(DOI) => this.setState({ DOI })}
                            type="text"
                            label="Date of Issue (DOI)"
                            value={this.state.DOI}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(paperID) => this.setState({ paperID })}
                            type="text"
                            label="Paper ID"
                            value={this.state.paperID}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(digiproveURL) =>
                                this.setState({ digiproveURL })
                            }
                            type="text"
                            label="Digiprove URL"
                            value={this.state.digiproveURL}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(pages) => this.setState({ pages })}
                            type="text"
                            label="Pages Rage in Hardcopy"
                            value={this.state.pages}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(downloadLink) =>
                                this.setState({ downloadLink })
                            }
                            label="Download Link"
                            type="select"
                            options={["enable", "disable"]}
                            value={this.state.downloadLink}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(language) => this.setState({ language })}
                            label="Manuscript Language *"
                            type="select"
                            options={[
                                "English",
                                "Persian",
                                "Hindi",
                                "Urdu",
                                "Arabic",
                            ]}
                            value={this.state.language}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="12"
                            validate={false}
                            onData={(abstract) => this.setState({ abstract })}
                            label="Manuscript Abstract"
                            type="textarea"
                            value={this.state.abstract}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="12"
                            validate={false}
                            onData={(keywords) => this.setState({ keywords })}
                            label="Keywords"
                            type="textarea"
                            value={this.state.keywords}
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="12"
                            validate={false}
                            onData={(conclusion) =>
                                this.setState({ conclusion })
                            }
                            label="Conclusion"
                            value={this.state.conclusion}
                            type="textarea"
                            validations={[]}
                            onChange={(x) => x}
                        />
                        <Field
                            cols="12"
                            validate={false}
                            onData={(refrences) => this.setState({ refrences })}
                            label="References *"
                            validations={[]}
                            onChange={(x) => x}
                            type="textarea"
                            value={this.state.refrences}
                        />
                        <Files
                            label="PDF file of Manuscript *"
                            onData={(pdfFile) => this.setState({ pdfFile })}
                        />
                        <Files
                            label="Word file of Manuscript *"
                            onData={(wordFile) => this.setState({ wordFile })}
                        />
                        <Files
                            label="Reviewer Form *"
                            onData={(reviewerForm) =>
                                this.setState({ reviewerForm })
                            }
                        />
                        <Files
                            label="Copyright Form *"
                            onData={(copyrightForm) =>
                                this.setState({ copyrightForm })
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.hide}>
                        Close
                    </Button>
                    <Button
                        variant="primary"
                        onClick={this.eventHandler}
                        disabled={this.state.disable}
                    >
                        {!this.state.showSpinner && <span>Save</span>}
                        {this.state.showSpinner && (
                            <i className="fa fa-spinner fa-spin fa-1x" />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            disable: false,
            showError: false,
        };
    }

    deleteEventHandler = async (id) => {
        try {
            await this.setState({ showSpinner: true, disable: true });
            await db.collection("Archives").doc(id).delete();

            try {
                if (this.props.data.pdfFile) {
                    await storage
                        .ref()
                        .child(`archives/PdfFile/${this.props.data.pdfFile}`)
                        .delete();
                }
            } catch (err) {
                console.log(err);
            }
            try {
                if (this.props.data.wordFile) {
                    await storage
                        .ref()
                        .child(`archives/wordFile/${this.props.data.wordFile}`)
                        .delete();
                }
            } catch (err) {
                console.log(err);
            }
            try {
                if (this.props.data.reviewerForm) {
                    await storage
                        .ref()
                        .child(
                            `archives/reviewerForm/${this.props.data.reviewerForm}`
                        )
                        .delete();
                }
            } catch (err) {
                console.log(err);
            }
            try {
                if (this.props.data.copyrightForm) {
                    await storage
                        .ref()
                        .child(
                            `archives/copyrightForm/${this.props.data.copyrightForm}`
                        )
                        .delete();
                }
            } catch (err) {
                console.log(err);
            }
        } catch (err) {
            await this.setState({
                showSpinner: false,
                disable: false,
                showError: true,
            });
        } finally {
            this.props.update();
            await this.setState({
                disable: false,
                showError: false,
                showSpinner: false,
            });
            this.props.hide();
        }
    };

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hide}
                centered
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className={this.props.data.language}>
                        Delete {this.props.data.name}'s details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.showError && (
                        <div className="alert alert-danger" role="alert">
                            Some error has occured, please check your network
                            connection and try again!
                        </div>
                    )}
                    Are you sure you want to delete this?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.hide}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() =>
                            this.deleteEventHandler(this.props.data.id)
                        }
                        disabled={this.state.disable}
                    >
                        {!this.state.showSpinner && <span>Delete</span>}
                        {this.state.showSpinner && (
                            <i className="fa fa-spinner fa-spin fa-1x" />
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export class VerifyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSpinner: false,
            disable: false,
            showError: false,
        };
    }

    verifyEventHandler = async (id) => {
        try {
            this.props.verify
                ? (this.props.data.status = "VERIFIED")
                : (this.props.data.status = "NOT_VERIFIED");

            this.props.data.acceptedDate = Date.now();

            await db
                .collection("Archives")
                .doc(this.props.data.id)
                .set(this.props.data);

            this.props.update();
            await this.setState({
                disable: false,
                showError: false,
                showSpinner: false,
            });
            this.props.hide();
        } catch (err) {
            await this.setState({
                showSpinner: false,
                disable: false,
                showError: true,
            });
        }
    };

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hide}
                centered
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className={this.props.data.language}>
                        {this.props.verify
                            ? `Verify ${this.props.data.name}`
                            : `UnVerify ${this.props.data.name}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {this.state.showError && (
                        <div className="alert alert-danger" role="alert">
                            Some error has occured, please check your network
                            connection and try again!
                        </div>
                    )}
                    Are you sure you want to
                    {this.props.verify ? "verify" : "unverify"} this Archive?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.hide}>
                        Cancel
                    </Button>
                    <Button
                        variant={this.props.verify ? "success" : "danger"}
                        onClick={() =>
                            this.verifyEventHandler(this.props.data.id)
                        }
                    >
                        {this.props.verify ? "Verify" : "Unverify"}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export class InfoModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: "",
            loading: false,
        };
    }

    camelToSentence = (word) => {
        const result = word.replace(/([A-Z])/g, " $1");
        return result.charAt(0).toUpperCase() + result.slice(1);
    };

    capitalize = (word) =>
        typeof word !== "string"
            ? ""
            : word.charAt(0).toUpperCase() + word.slice(1);

    async componentDidMount() {
        this.setState({ loading: true });
        try {
            if (this.props.data.pdfFile) {
                const pdfUrl = await storage
                    .ref()
                    .child(`archives/PdfFile/${this.props.data.pdfFile}`)
                    .getDownloadURL();
                this.setState({ pdfUrl });
            }
        } catch (err) {
            console.log(err);
        }
        try {
            if (this.props.data.wordFile) {
                const wordUrl = await storage
                    .ref()
                    .child(`archives/wordFile/${this.props.data.wordFile}`)
                    .getDownloadURL();
                this.setState({ wordUrl });
            }
        } catch (err) {
            console.log(err);
        }
        try {
            if (this.props.data.reviewerForm) {
                const reviewerUrl = await storage
                    .ref()
                    .child(
                        `archives/reviewerForm/${this.props.data.reviewerForm}`
                    )
                    .getDownloadURL();
                this.setState({ reviewerUrl });
            }
        } catch (err) {
            console.log(err);
        }
        try {
            if (this.props.data.copyrightForm) {
                const copyrightUrl = await storage
                    .ref()
                    .child(
                        `archives/copyrightForm/${this.props.data.copyrightForm}`
                    )
                    .getDownloadURL();
                this.setState({ copyrightUrl });
            }
        } catch (err) {
            console.log(err);
        }
        this.setState({ loading: false });
    }

    render() {
        let details = {};
        const priority = [
            "name",
            "email",
            "email1",
            "email2",
            "mobile",
            "mobile1",
            "mobile2",
            "address",
            "city",
            "state",
            "country",
            "institute",
            "profession",
            "areaOfStudy",
            "type",
            "title",
            "language",
            "abstract",
            "keywords",
            "conclusion",
            "refrences",
            "pdfFile",
            "wordFile",
            "reviewerForm",
            "copyrightForm",
            "DOI",
            "digiproveURL",
            "timestamp",
            "revisionDate",
            "acceptedDate",
            "releasedWith",
            "paperID",
            "status",
        ];

        priority.forEach((value) => (details[value] = this.props.data[value]));

        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hide}
                centered
                scrollable
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title className={details.language}>
                        {details.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!this.state.loading ? (
                        <table className="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">Attribute</th>
                                    <th scope="col">Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.keys(details).map((key) => {
                                    if (key === "pdfFile") {
                                        return (
                                            <tr>
                                                <td>PDF File Uploaded</td>
                                                <td
                                                    className={details.language}
                                                >
                                                    <a
                                                        href={this.state.pdfUrl}
                                                        target="blank"
                                                    >
                                                        {details.title}
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    } else if (key === "wordFile") {
                                        return (
                                            <tr>
                                                <td>Word File Uploaded</td>
                                                <td
                                                    className={details.language}
                                                >
                                                    <a
                                                        href={
                                                            this.state.wordUrl
                                                        }
                                                        target="blank"
                                                    >
                                                        {details.title}
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    } else if (key === "reviewerForm") {
                                        return (
                                            <tr>
                                                <td>Reviewer Form Uploaded</td>
                                                <td
                                                    className={details.language}
                                                >
                                                    <a
                                                        href={
                                                            this.state
                                                                .reviewerUrl
                                                        }
                                                        target="blank"
                                                    >
                                                        Reviewer Form
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    } else if (key === "copyrightForm") {
                                        return (
                                            <tr>
                                                <td>Copyright Form Uploaded</td>
                                                <td
                                                    className={details.language}
                                                >
                                                    <a
                                                        href={
                                                            this.state
                                                                .copyrightUrl
                                                        }
                                                        target="blank"
                                                    >
                                                        Copyright Form
                                                    </a>
                                                </td>
                                            </tr>
                                        );
                                    } else if (key === "timestamp") {
                                        return (
                                            <tr>
                                                <td>Date of Submission</td>
                                                <td>
                                                    {details.timestamp
                                                        ? `${new Date(
                                                              details.timestamp
                                                          ).toDateString()}`
                                                        : "No timestamp"}
                                                </td>
                                            </tr>
                                        );
                                    } else if (key === "revisionDate") {
                                        return (
                                            <tr>
                                                <td>Date of Revision</td>
                                                <td>
                                                    {details.revisionDate
                                                        ? `${new Date(
                                                              details.revisionDate
                                                          ).toLocaleString()}`
                                                        : "Not yet revised"}
                                                </td>
                                            </tr>
                                        );
                                    } else if (key === "acceptedDate") {
                                        return (
                                            <tr>
                                                <td>Date of Acceptance</td>
                                                <td>
                                                    {details.acceptedDate &&
                                                    details.acceptedDate !==
                                                        "NA"
                                                        ? `${new Date(
                                                              details.acceptedDate
                                                          ).toLocaleString()}`
                                                        : "Not yet accepted"}
                                                </td>
                                            </tr>
                                        );
                                    } else if (
                                        key === "_id" ||
                                        key === "__v" ||
                                        key === "id"
                                    ) {
                                        return null;
                                    }
                                    return (
                                        <tr>
                                            <td>
                                                {this.camelToSentence(
                                                    this.capitalize(key)
                                                )}
                                            </td>
                                            {this.capitalize(key) === "Name" ||
                                            this.capitalize(key) ===
                                                "Abstract" ||
                                            this.capitalize(key) ===
                                                "Keywords" ||
                                            this.capitalize(key) ===
                                                "Conclusion" ||
                                            this.capitalize(key) ===
                                                "Refrences" ||
                                            this.capitalize(key) === "Title" ? (
                                                <td
                                                    className={details.language}
                                                >
                                                    {details[key]}
                                                </td>
                                            ) : (
                                                <td>{details[key]}</td>
                                            )}
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </table>
                    ) : (
                        <div className="text-center ">
                            <span
                                className="spinner-border text-success"
                                style={{ width: "3rem", height: "3rem" }}
                                role="status"
                            />
                        </div>
                    )}
                </Modal.Body>
            </Modal>
        );
    }
}
