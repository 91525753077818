import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import TopNav from "../layout/topnav";
import Header from "../layout/header";

export default function Login(props) {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login, admin } = useAuth();
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setError("");
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);
            if (admin) history.push("/admin");
            else history.push("/");
        } catch {
            setError("Failed to log in");
        }

        setLoading(false);
    }

    return (
        <>
            <TopNav />
            <Header
                heading="Kanzuliman Research"
                tagline="Kanzuliman Academic International Journal on Razawiyāt (KAIJOR) world's first academic research journal on the subject of Razawiyāt"
            />
            <Container
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: "80vh" }}
            >
                <div className="w-100" style={{ maxWidth: "700px" }}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">Log In</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {props.location.state !== undefined && (
                                <Alert variant="info">
                                    {props.location.state.message}
                                </Alert>
                            )}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        ref={emailRef}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        ref={passwordRef}
                                        required
                                    />
                                </Form.Group>
                                <Button
                                    disabled={loading}
                                    className="w-100 btn-dark"
                                    type="submit"
                                >
                                    Log In
                                </Button>
                            </Form>
                            <div className="w-100 text-center mt-3">
                                <Link
                                    to="/forgotpassword"
                                    style={{ textDecoration: "none" }}
                                >
                                    Forgot Password?
                                </Link>
                            </div>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                        No Account?{" "}
                        <Link to="/signup" style={{ textDecoration: "none" }}>
                            SignUp
                        </Link>
                    </div>
                </div>
            </Container>
        </>
    );
}
