export const appConfig = {
    emailEndpoint: "https://kanzuliman-research-dev.herokuapp.com/",
    developmentConfig: {
        apiKey: "AIzaSyDQHGHx5k4VuCm_lwhdxwuXOTLVbwlngXY",
        authDomain: "research-dev-4c18d.firebaseapp.com",
        projectId: "research-dev-4c18d",
        storageBucket: "research-dev-4c18d.appspot.com",
        messagingSenderId: "885488147816",
        appId: "1:885488147816:web:d6103d839e001b20058add",
    },
    productionConfig: {
        apiKey: "AIzaSyB3Ge6NfkUzk2HvegdAu917Nx9rCBMQbhE",
        authDomain: "research-2831b.firebaseapp.com",
        projectId: "research-2831b",
        storageBucket: "research-2831b.appspot.com",
        messagingSenderId: "718760438361",
        appId: "1:718760438361:web:1fe7c38761a0fc7aef1f48",
        measurementId: "G-HNN0ZYL6QV",
    },
};
