import React from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";

export class success extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Successfully submitted"
                    tagline="Kanzuliman Research Publication"
                />
                <center className="main">
                    <h1>
                        Thank You! Your form has been submitted successfully!{" "}
                    </h1>
                </center>
            </div>
        );
    }
}

export default success;
