import React from "react";
import { DeleteModal, EditModal, VerifyModal, InfoModal } from "./modals";

export class tableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
            showViewModal: false,
            showDeleteModal: false,
            showVerifyModal: false,
        };
    }

    async componentDidMount() {}

    render() {
        let rowStyle;
        if (this.props.data.isVerified) {
            rowStyle = {
                backgroundColor: "rgb(100, 180, 23)",
            };
        } else {
            rowStyle = {
                backgroundColor: "rgb(190, 97, 51)",
            };
        }
        return (
            <>
                <tr style={rowStyle}>
                    <td>{this.props.data.name}</td>
                    <td>{this.props.data.designation}</td>
                    <td>
                        <span
                            style={{ color: "Black" }}
                            className="col"
                            title="Edit"
                            onClick={() => {
                                this.setState({
                                    showEditModal: true,
                                });
                            }}
                        >
                            <div
                                title="Click to edit record"
                                className="tooltip-test fa fa-pencil"
                                aria-hidden="true"
                            />
                        </span>
                        <span
                            style={{ color: "Black" }}
                            className="col"
                            onClick={() =>
                                this.setState({
                                    showViewModal: true,
                                })
                            }
                        >
                            <i
                                className="tooltip-test fa fa-eye"
                                title="Click to view record"
                                aria-hidden="true"
                            />
                        </span>
                        <span
                            style={{ color: "Black" }}
                            className="col"
                            onClick={() =>
                                this.setState({
                                    showDeleteModal: true,
                                })
                            }
                        >
                            <i
                                className="tooltip-test fa fa-trash"
                                title="Click to Delete"
                                aria-hidden="true"
                            />
                        </span>
                        {!this.props.data.isVerified && (
                            <span
                                style={{ color: "Black" }}
                                className="col"
                                onClick={() =>
                                    this.setState({
                                        showVerifyModal: true,
                                    })
                                }
                            >
                                <i
                                    className="tooltip-test fa fa-sign-in"
                                    title="Click to Verify"
                                    aria-hidden="true"
                                />
                            </span>
                        )}
                        {this.props.data.isVerified && (
                            <span
                                style={{ color: "Black" }}
                                className="col"
                                onClick={() =>
                                    this.setState({
                                        showVerifyModal: true,
                                    })
                                }
                            >
                                <i
                                    className="tooltip-test fa fa-times"
                                    title="Click to UnVerify"
                                    aria-hidden="true"
                                />
                            </span>
                        )}
                    </td>
                </tr>
                {this.state.showEditModal && (
                    <EditModal
                        show={this.state.showEditModal}
                        hide={() => this.setState({ showEditModal: false })}
                        data={this.props.data}
                        updateComponentData={this.props.updateComponentData}
                    />
                )}
                {this.state.showDeleteModal && (
                    <DeleteModal
                        show={this.state.showDeleteModal}
                        hide={() => this.setState({ showDeleteModal: false })}
                        data={this.props.data}
                        updateComponentData={this.props.updateComponentData}
                    />
                )}
                {this.state.showVerifyModal && (
                    <VerifyModal
                        show={this.state.showVerifyModal}
                        hide={() => this.setState({ showVerifyModal: false })}
                        data={this.props.data}
                        updateComponentData={this.props.updateComponentData}
                    />
                )}
                {this.state.showViewModal && (
                    <InfoModal
                        show={this.state.showViewModal}
                        hide={() => this.setState({ showViewModal: false })}
                        data={this.props.data}
                        updateComponentData={this.props.updateComponentData}
                    />
                )}
            </>
        );
    }
}

export default tableRow;
