import React, { Component } from "react";
import validations from "./utilities/validations";

class TextField extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value ? this.props.value : "",
            errorMessage: "",
        };
    }

    async componentDidUpdate(prevProps, prevState) {
        const { value, errorMessage } = this.state;
        const { value: prevValue, errorMessage: prevError } = prevState;
        const { validate, validations } = this.props;
        const { validate: prevValidate } = prevProps;
        if (prevValue !== value || prevError !== errorMessage) {
            this.props.onChange(!!errorMessage);
        }
        if (prevValidate !== validate) {
            let msg = "";
            validations.every((validator) => {
                msg = this.basicValidation(validator);
                if (msg.length > 0) return false;
                else return true;
            });
            await this.setState({ errorMessage: msg });
        }
    }

    basicValidation(validator) {
        const { value } = this.state;
        const { label } = this.props;
        switch (validator[0]) {
            case "required":
                return validations.isNull(value) !== false
                    ? ""
                    : !validator[1]
                    ? `Please provide your ${label.toLowerCase().split("*")[0]}`
                    : validator[1];
            case "email":
                return validations.email(value)
                    ? ""
                    : "Please enter a valid email";
            case "min":
                return validations.minimumLength(value, validator[1])
                    ? ""
                    : !validator[2]
                    ? `${label.split("*")[0]} needs to have at least ${
                          validator[1]
                      } characters`
                    : validator[2];
            case "max":
                return validations.maximumLength(value, validator[1])
                    ? ""
                    : !validator[2]
                    ? `${label.split("*")[0]} cannot have more than ${
                          validator[1]
                      } characters`
                    : validator[2];
            default:
                return "";
        }
    }

    onChange = async (event) => {
        let msg = "";
        await this.setState({ value: event.target.value });
        const { validations } = this.props;
        validations.every((validator) => {
            msg = this.basicValidation(validator);
            if (msg.length > 0) return false;
            else return true;
        });
        await this.setState({ errorMessage: msg });
        this.props.onData(this.state.value);
    };

    render() {
        const { value, errorMessage } = this.state;
        const { type, label, validate, cols, options, rows, placeholder } =
            this.props;

        var inputClass = validate
            ? !errorMessage.length
                ? "form-control is-valid"
                : "form-control is-invalid"
            : "form-control";
        const mainClass = `col-lg-${cols} form-group`;
        const row = rows || "5";

        if (type === "select") inputClass += " form-select";

        if (type !== "textarea")
            return (
                <div className={mainClass}>
                    <label>{label}</label>
                    {type === "select" && (
                        <select
                            onChange={this.onChange}
                            className={inputClass}
                            value={value}
                        >
                            <option disabled value="">
                                --SELECT--
                            </option>

                            {options.map((singleOption) => {
                                return (
                                    <option value={singleOption}>
                                        {singleOption}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    {type !== "select" && (
                        <input
                            onChange={this.onChange}
                            type={type || "text"}
                            className={inputClass}
                            value={value}
                            placeholder={placeholder}
                        />
                    )}
                    {!!errorMessage && validate && (
                        <div className="invalid-feedback">{errorMessage}</div>
                    )}
                </div>
            );
        else
            return (
                <div className={mainClass}>
                    <label>{label}</label>
                    <textarea
                        onChange={this.onChange}
                        type="textarea"
                        rows={row}
                        className={inputClass}
                        value={value}
                        placeholder={placeholder}
                    />
                    {!!errorMessage && validate && (
                        <div className="invalid-feedback">{errorMessage}</div>
                    )}
                </div>
            );
    }
}

export default TextField;
