import React from "react";
import { db } from "../../firebase";
import Information from "./utilities/information";
import Table from "./utilities/table";

export class content extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            array: [],
        };
    }

    getType = () => {
        switch (this.props.option) {
            case "books":
                return "Research Book";
            case "thesis":
                return "Research Thesis";
            case "papers":
                return "Research Paper";
            case "caseStudies":
                return "Case Study";
            case "reviews":
                return "Review";
            case "others":
                return "Other";
            default:
                return undefined;
        }
    };

    async componentDidUpdate(prevProps) {
        if (prevProps.option !== this.props.option) {
            this.componentDidMount();
        }
    }

    render() {
        if (this.state.array !== undefined)
            return (
                <div>
                    <Table array={this.state.array} />
                </div>
            );
        else return <Information id={this.props.option} />;
    }

    componentDidMount = async () => {
        const option = this.getType();
        if (option === undefined) {
            await this.setState({ array: undefined });
            return;
        }
        const response = await db
            .collection("Archives")
            .where("type", "==", option)
            .get();
        let array = [];
        response.forEach((doc) => {
            let temp = {};
            temp = doc.data();
            temp.id = doc.id;
            if (temp.status === "VERIFIED") {
                array.push(temp);
            }
        });
        this.setState({ array });
    };
}

export default content;
