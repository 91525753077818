import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import TopNav from "../layout/topnav";
import Header from "../layout/header";

export default function Signup() {
    const emailRef = useRef();
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const { signup, logout } = useAuth();
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    async function handleSubmit(e) {
        e.preventDefault();

        if (passwordRef.current.value !== confirmPasswordRef.current.value) {
            return setError("Passwords do not match");
        }

        try {
            setError("");
            setMessage("");
            setLoading(true);
            await signup(emailRef.current.value, passwordRef.current.value);
            await logout();
            setMessage("Account Created You can Login Now");
            history.push({
                pathname: "/login",
                state: {
                    message: "Your registration was successful, please log in.",
                },
            });
        } catch {
            setMessage("");
            setError("Failed to create an account");
        }

        setLoading(false);
    }

    return (
        <>
            <TopNav />
            <Header
                heading="Kanzuliman Research"
                tagline="Kanzuliman Academic International Journal on Razawiyāt (KAIJOR) world's first academic research journal on the subject of Razawiyāt"
            />
            <Container
                className="d-flex align-items-center justify-content-center"
                style={{ minHeight: "80vh" }}
            >
                <div className="w-100" style={{ maxWidth: "700px" }}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">SignUp</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {!!message && (
                                <Alert variant="info">{message}</Alert>
                            )}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        ref={emailRef}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group id="password">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        ref={passwordRef}
                                        required
                                    />
                                </Form.Group>
                                <Form.Group id="confirm-password">
                                    <Form.Label>Confirm Password</Form.Label>
                                    <Form.Control
                                        type="password"
                                        ref={confirmPasswordRef}
                                        required
                                    />
                                </Form.Group>
                                <Button
                                    disabled={loading}
                                    className="w-100 btn-dark"
                                    type="submit"
                                >
                                    SignUp
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                    <div className="w-100 text-center mt-2">
                        Already have an account?{" "}
                        <Link to="/login" style={{ textDecoration: "none" }}>
                            Log In
                        </Link>
                    </div>
                </div>
            </Container>
        </>
    );
}
