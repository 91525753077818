import React from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import Content from "./content";

export class research extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            thesis: false,
            books: false,
            papers: false,
            review: false,
            other: false,
            case_study: false,
            information: false,
            dataSent: [],
            heading: "",
        };
    }

    getHeading = (option) => {
        if (option === "thesis") return "Research Thesis";
        else if (option === "books") return "Research Books";
        else if (option === "papers") return "Research Papers";
        else if (option === "reviews") return "Research Reviews";
        else if (option === "others") return "Other Publicaitons";
        else if (option === "caseStudies") return "Case Studies";
    };

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading={
                        this.getHeading(this.props.match.params.option) ||
                        "Archives"
                    }
                    tagline="Kanzuliman Research Publication"
                />
                <div className="container">
                    <Content option={this.props.match.params.option} />
                </div>
            </div>
        );
    }
}

export default research;
