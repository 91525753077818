import React from "react";
import TopNav from "../layout/navbar";
import Header from "../../layout/header";
import NewEvent from "./NewEvent";
import { db } from "../../../firebase";
import EventCard from "../../activities/EventCard";
import { Modal } from "react-bootstrap";

class Events extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showAddEventModal: false,
            events: [],
            loading: false,
        };
        this.fetchEventsData = this.fetchEventsData.bind(this);
    }

    async fetchEventsData() {
        try {
            const response = await db.collection("events").get();
            let events = [];
            response.forEach((record) => {
                let temp = {};
                temp = record.data();
                temp.id = record.id;
                events.push(temp);
            });
            events.sort((first, second) => {
                if (first.eventID > second.eventID) return -1;
                else return 1;
            });
            this.setState({ events });
        } catch (err) {
            console.log("Error fetching events data: ", err);
        }
    }

    async componentDidMount() {
        this.setState({ loading: true });
        await this.fetchEventsData();
        this.setState({ loading: false });
    }

    render() {
        return (
            <>
                <TopNav />
                <Header
                    heading="Event Management"
                    tagline="Kanzuliman Research Publication"
                />
                <div
                    className="container"
                    style={{ marginTop: "100px", marginBottom: "400px" }}
                >
                    <button
                        className="btn btn-dark"
                        onClick={() =>
                            this.setState({ showAddEventModal: true })
                        }
                    >
                        + Add Event
                    </button>
                    <NewEvent
                        show={this.state.showAddEventModal}
                        hide={() => {
                            this.setState({ showAddEventModal: false });
                        }}
                        data={undefined}
                        update={false}
                        updateComponentData={this.fetchEventsData}
                    />
                    <div className="mt-5" />
                    {this.state.loading ? (
                        <div className="text-center">
                            <div
                                class="spinner-border text-success"
                                style={{
                                    width: "5rem",
                                    height: "5rem",
                                    marginTop: "10rem",
                                }}
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    ) : (
                        <table className="table table-responsive-sm">
                            <thead className="table-dark">
                                <tr>
                                    <th>EventID</th>
                                    <th>Event Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody className="table-info">
                                {this.state.events.map((event, index) => {
                                    return (
                                        <TableRow
                                            event={event}
                                            key={index}
                                            updateComponentData={
                                                this.fetchEventsData
                                            }
                                        />
                                    );
                                })}
                            </tbody>
                        </table>
                    )}
                </div>
            </>
        );
    }
}

class TableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deletingEvent: false,
            editRecord: false,
            currentRecord: "",
            viewEvent: false,
            verifyingEvent: false,
        };
    }

    render() {
        return (
            <>
                <tr>
                    <td>{this.props.event.eventID}</td>
                    <td>{this.props.event.eventTitle}</td>
                    <td>
                        <div className="row">
                            <span
                                style={{ color: "Black" }}
                                className="col-2"
                                title="Edit"
                                onClick={() => {
                                    this.setState({
                                        editRecord: true,
                                        currentRecord: this.props.event,
                                    });
                                }}
                            >
                                <div
                                    title="Click to edit record"
                                    className="tooltip-test fa fa-pencil"
                                    aria-hidden="true"
                                />
                            </span>
                            {this.state.deletingEvent ? (
                                <span>
                                    <i
                                        style={{ color: "Red" }}
                                        className="fa fa-spinner fa-spin fa-1x"
                                    />
                                </span>
                            ) : (
                                <span
                                    style={{ color: "Red" }}
                                    className="col-2"
                                    onClick={async () => {
                                        this.setState({ deletingEvent: true });
                                        try {
                                            await db
                                                .collection("events")
                                                .doc(this.props.event.id)
                                                .delete();
                                            await this.props.updateComponentData();
                                        } catch (err) {
                                            console.log(
                                                "Unable to delte event:",
                                                err
                                            );
                                        }
                                        this.setState({ deletingEvent: false });
                                    }}
                                >
                                    <i
                                        className="tooltip-test fa fa-trash"
                                        title="Click to Delete"
                                        aria-hidden="true"
                                    />
                                </span>
                            )}
                            <span
                                style={{ color: "green" }}
                                className="col-2"
                                onClick={() =>
                                    this.setState({
                                        viewEvent: true,
                                        currentRecord: this.props.event,
                                    })
                                }
                            >
                                <i
                                    className="tooltip-test fa fa-eye"
                                    title="View Event Details"
                                    aria-hidden="true"
                                />
                            </span>
                            {this.state.verifyingEvent ? (
                                <span>
                                    <i
                                        style={{ color: "Red" }}
                                        className="fa fa-spinner fa-spin fa-1x"
                                    />
                                </span>
                            ) : (
                                <span
                                    className="col"
                                    onClick={async () => {
                                        this.setState({ verifyingEvent: true });
                                        try {
                                            const requestSent =
                                                this.props.event;
                                            requestSent.eventVisibility =
                                                !this.props.event
                                                    .eventVisibility;
                                            await db
                                                .collection("events")
                                                .doc(this.props.event.id)
                                                .set(requestSent);
                                            await this.props.updateComponentData();
                                        } catch (err) {
                                            console.log(
                                                "Unable to verify event:",
                                                err
                                            );
                                        }
                                        this.setState({
                                            verifyingEvent: false,
                                        });
                                    }}
                                >
                                    {this.props.event.eventVisibility ? (
                                        <i
                                            style={{ color: "green" }}
                                            className="tooltip-test fa fa-check"
                                            title="View Event Details"
                                            aria-hidden="true"
                                        />
                                    ) : (
                                        <i
                                            style={{ color: "red" }}
                                            className="tooltip-test fa fa-times"
                                            title="View Event Details"
                                            aria-hidden="true"
                                        />
                                    )}
                                </span>
                            )}
                        </div>
                    </td>
                </tr>
                {this.state.editRecord && (
                    <NewEvent
                        show={this.state.editRecord}
                        hide={() => {
                            this.setState({ editRecord: false });
                        }}
                        data={this.state.currentRecord}
                        update={true}
                        updateComponentData={this.props.updateComponentData}
                    />
                )}
                {this.state.viewEvent && (
                    <Modal
                        show={this.state.viewEvent}
                        onHide={() => {
                            this.setState({ viewEvent: false });
                        }}
                        dialogClassName="modal-90w"
                        size="xl"
                    >
                        <Modal.Body>
                            <EventCard event={this.state.currentRecord} />
                        </Modal.Body>
                    </Modal>
                )}
            </>
        );
    }
}

export default Events;
