import React from "react";
import { Link } from "react-router-dom";
import Header from "../layout/header";
import TopNav from "../layout/topnav";
import { db } from "../../firebase";
import Table from "./utilities/table";

class SpecialIssue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            specialIssues: [],
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        try {
            const response = await db
                .collection("Archives")
                .where("type", "==", "Special Issue")
                .get();
            let array = [];
            response.forEach((res) => {
                let temp = {};
                temp = res.data();
                temp.id = res.id;
                if (temp.status === "VERIFIED") {
                    array.push(temp);
                }
            });
            this.setState({ specialIssues: array });
        } catch (err) {
            console.log("Error fetching volume data: ", err);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Special Issues"
                    tagline="Kanzuliman Research Publications"
                />
                <div className="container mt-5">
                    <strong>
                        Kanzuliman: The Excellence of Quaranic Translation
                    </strong>
                    <br />
                    <br />
                    <p>
                        <strong>
                            <i>KAIJOR</i>
                        </strong>{" "}
                        plans on releasing a special edition on magnificent
                        translation of the Holy Qur’an by <i>Imam Ahmad Raza</i>{" "}
                        known as <i>"Kanzul Iman"</i>. If you have written on
                        the same or want to suggest some sub-themes, do let us
                        know about your thoughts at the email below.
                        <br />
                    </p>
                    <span className="text-blue">
                        kaijor.kanzuliman@gmail.com
                    </span>
                    <br />
                    <br />
                    <Link
                        to="/authors/online-paper-submission"
                        style={{ textDecoration: "none" }}
                    >
                        Submit Your Article
                    </Link>
                </div>
                <Table array={this.state.specialIssues} />
            </div>
        );
    }
}

export default SpecialIssue;
