import React from "react";
import Header from "./layout/header";
import TopNav from "./layout/topnav";

class PageNotExist extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Kanzuliman Research"
                    tagline="Kanzuliman Academic International Journal on Razawiyāt (KAIJOR) world's first academic research journal on the subject of Razawiyāt"
                />
                <div
                    className="container"
                    style={{ marginTop: "400px", marginBottom: "400px" }}
                >
                    <h1 className="text-muted">
                        404 Error sorry, this page doesn't exist :(
                    </h1>
                </div>
            </div>
        );
    }
}

export default PageNotExist;
