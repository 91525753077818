import React from "react";
import "./footer.css";
import facebook from "../../assets/facebook.png";
import insta from "../../assets/insta.png";
import youtube from "../../assets/youtube.png";
import twitter from "../../assets/twitter.png";

class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <div className="box">
                    <h5 className="text-white text-center">
                        Copyright &#xa9; Kanzuliman Foundation
                    </h5>
                    <h6 className="text-center text-white">
                        <i>Kanzuliman Research Division</i>
                    </h6>
                </div>
                <div className="container pt-4">
                    <div className="row">
                        <div className="col-md-3">
                            <h4 className="text-white"> </h4>
                            <p className="text-white ml-2 mr-5">
                                Bareilly, India 243005
                            </p>
                            <h4 className="text-white"> </h4>
                            <p className="text-white ml-2 mr-5">
                                +91 950-621-5324
                            </p>
                            <h4 className="text-white"> </h4>
                            <p className="text-white ml-2">
                                Email : info@kanzuliman.com
                            </p>
                        </div>
                        <div className="col-md-6" />
                        <div className="col-md-3">
                            <h5 className="text-white mb-3 mt-5">
                                Get Social With Us
                            </h5>
                            <a
                                href="https://www.facebook.com/kanzulimanLibrary/"
                                target="blank"
                            >
                                <img
                                    src={facebook}
                                    className="social-logo"
                                    alt=""
                                />
                            </a>
                            <a
                                href="https://www.instagram.com/kanzuliman/"
                                target="blank"
                            >
                                <img
                                    src={insta}
                                    className="social-logo"
                                    alt=""
                                />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCpE8EbAzmI5VAEj5kOL0Yaw"
                                target="blank"
                            >
                                <img
                                    src={youtube}
                                    className="social-logo"
                                    alt=""
                                />
                            </a>
                            <a
                                href="https://twitter.com/kanzuliman92"
                                target="blank"
                            >
                                <img
                                    src={twitter}
                                    className="social-logo"
                                    alt=""
                                />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
