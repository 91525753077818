import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/layout/footer";

import About from "./components/about/about";
import Mission from "./components/about/mission";
import President from "./components/about/president";

import Volunteer from "./components/join/volunteer";
import ApplicationForm from "./components/join/applicationForm";
import Success from "./components/join/success";
import Contact from "./components/join/contact";
import Journal from "./components/join/sponsorship";

import Home from "./components/home";
import Board from "./components/board/board";
import Signup from "./components/Authentication/signup";
import { AuthProvider } from "./contexts/AuthContext";
import Login from "./components/Authentication/login";
import UserProfile from "./components/Authentication/UserProfile";
import PrivateRoute, {
    UnLoggedRoute,
    AdminRoute,
} from "./components/Authentication/PrivateRoute";
import UpdateProfile from "./components/Authentication/UpdateProfile";
import ForgotPassword from "./components/Authentication/ForgotPassword";
import Guidelines from "./components/authors/guidelines";
import OnlinePaperSubmission from "./components/authors/onlinePaperSubmission";

import Research from "./components/research/research";
import SpecialIssue from "./components/research/SpecialIssues";
import PastPublications from "./components/research/PastPublications";

import RolePanel from "./components/Admin/rolePanel/rolePanel";
import HomeAdmin from "./components/Admin/home";
import ArchivesAdmin from "./components/Admin/archives/archives";
import Events from "./components/Admin/Events/Events";
import OfficialDownloads from "./components/research/OfficialDownloads";
import PageNotExist from "./components/doesNotExist";
import Volumes from "./components/research/volumes";
import SpecificVolume from "./components/research/specificVolume";
import ManageJournalRelease from "./components/Admin/archives/ManageJournalRelease";
import ViewEvent from "./components/activities/ViewEvents";
import ContactList from "./components/Admin/ContactList";

require("dotenv").config();

class App extends React.Component {
    componentDidUpdate() {
        window.scrollTo(0, 500);
    }

    componentDidMount() {
        this.componentDidUpdate();
    }

    render() {
        return (
            <Router>
                <AuthProvider>
                    <Switch>
                        <PrivateRoute
                            path="/userprofile"
                            component={UserProfile}
                        />
                        <PrivateRoute
                            path="/updateprofile"
                            component={UpdateProfile}
                        />
                        <Route path="/" exact component={Home} />
                        <UnLoggedRoute path="/signUp" component={Signup} />
                        <UnLoggedRoute path="/login" component={Login} />
                        <UnLoggedRoute
                            path="/forgotpassword"
                            component={ForgotPassword}
                        />
                        <Route path="/about" component={About} />
                        <Route path="/mission" component={Mission} />
                        <Route path="/president" component={President} />
                        <Route path="/join/volunteer" component={Volunteer} />
                        <Route
                            path="/join/application/:applicationMode"
                            component={ApplicationForm}
                        />
                        <Route path="/join/success" component={Success} />
                        <Route path="/join/contact" component={Contact} />
                        <Route path="/join/journal" component={Journal} />
                        <Route path="/board/:option" component={Board} />
                        <Route
                            path="/authors/guidelines"
                            component={Guidelines}
                        />
                        <Route
                            path="/authors/online-paper-submission"
                            component={OnlinePaperSubmission}
                        />
                        <Route
                            path="/activities/view-events"
                            component={ViewEvent}
                        />
                        <Route path="/research/volumes" component={Volumes} />
                        <Route
                            path="/research/volume/:volumeName"
                            component={SpecificVolume}
                        />
                        <Route
                            path="/research/specialIssues"
                            component={SpecialIssue}
                        />
                        <Route
                            path="/research/pastPublications"
                            component={PastPublications}
                        />
                        <Route
                            path="/research/officialDownloads"
                            component={OfficialDownloads}
                        />

                        <AdminRoute path="/admin" exact component={HomeAdmin} />
                        <AdminRoute
                            path="/admin/archives"
                            component={ArchivesAdmin}
                        />
                        <AdminRoute
                            path="/admin/journalReleases"
                            component={ManageJournalRelease}
                        />
                        <AdminRoute path="/admin/events" component={Events} />
                        <AdminRoute
                            path="/admin/contactList"
                            component={ContactList}
                        />
                        <Route path="/research/:option" component={Research} />
                        <AdminRoute
                            path="/admin/:currentURLParamOption"
                            component={RolePanel}
                        />

                        {/* Do not move this route to up. 404 route must be plugged at last */}
                        <Route path="" component={PageNotExist} />
                    </Switch>
                    <Footer />
                </AuthProvider>
            </Router>
        );
    }
}

export default App;
