import React from "react";
import { storage } from "../../../firebase";

export class Card extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            information: props.information,
            downloadUrl: "",
            cardStyle: {
                backgroundColor: "white",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
                borderRadius: "20px",
                fontSize: "16px",
            },
        };

        this.createCardEffect = this.createCardEffect.bind(this);
        this.removeCardEffect = this.removeCardEffect.bind(this);
    }

    componentDidMount = async () => {
        if (
            this.state.information.resumeVisibility &&
            this.state.information.resumeFile !== undefined &&
            this.state.information.resumeFile !== ""
        ) {
            const downloadUrl = await storage
                .ref()
                .child(`users/resumes/${this.state.information.resumeFile}`)
                .getDownloadURL();
            this.setState({ downloadUrl });
        }
    };

    createCardEffect() {
        this.setState({
            cardStyle: {
                backgroundColor: "rgb(232, 245, 230)",
                boxShadow: "0 32px 64px 0 rgba(0,0,0,0.2)",
                transition: "0.3s",
            },
        });
    }

    removeCardEffect() {
        this.setState({
            cardStyle: {
                backgroundColor: "white",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                transition: "0.1s",
                borderRadius: "20px",
                fontSize: "16px",
            },
        });
    }

    render() {
        return (
            <>
                <div className="container mt-5">
                    <div
                        className="card w-100"
                        style={this.state.cardStyle}
                        onMouseEnter={this.createCardEffect}
                        onMouseLeave={this.removeCardEffect}
                    >
                        <div className="card-body">
                            {this.state.information.resumeVisibility &&
                            this.state.information.resumeFile !== undefined &&
                            this.state.information.resumeFile !== "" ? (
                                <a
                                    href={this.state.downloadUrl}
                                    target="blank"
                                    style={{ textDecoration: "none" }}
                                >
                                    <h4>{this.state.information.name}</h4>
                                </a>
                            ) : (
                                <h4 className="card-title">
                                    {this.state.information.name}
                                </h4>
                            )}
                            <div>
                                <i>
                                    <span className="text-primary">
                                        {this.state.information.email}
                                    </span>
                                    <br />
                                    {this.state.information.role !==
                                        "advisory-member" && (
                                        <div>
                                            {this.state.information.designation}
                                            <br />
                                        </div>
                                    )}
                                    {this.state.information.affiliation}
                                    <br />
                                    {this.state.information.address},&nbsp;
                                    {this.state.information.city},&nbsp;
                                    {this.state.information.country}
                                    <br />
                                    Postal Code:{" "}
                                    {this.state.information.PINCode}
                                    <br />
                                </i>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Card;
