import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopNav from "./layout/navbar";
import Header from "../layout/header";

export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let navigationList = [
            { tag: "Reviewers Section", path: "/admin/reviewer" },
            { tag: "Volunteers Section", path: "/admin/volunteer" },
            { tag: "Editorial Section", path: "/admin/editorial" },
            {
                tag: "Associate Editorial Section",
                path: "/admin/associate-editorial",
            },
            { tag: "Advisory Section", path: "/admin/advisory-member" },
            { tag: "Archives Section", path: "/admin/archives" },
            { tag: "Manage Journal Release", path: "/admin/journalReleases" },
            { tag: "Event Management Section", path: "/admin/events" },
            { tag: "Sponsor Section", path: "/admin/sponsor" },
            { tag: "Contact List", path: "admin/contactList" },
        ];
        return (
            <div>
                <TopNav />
                <Header
                    heading="Admin Section"
                    tagline="Kanzuliman Research Publication"
                />
                <div className="container">
                    <h1 className="mt-5 ml-3">Admin Panel</h1>
                    <div style={{ display: "flex", "flex-wrap": "wrap" }}>
                        {navigationList.map((item, index) => {
                            return (
                                <div
                                    className="card m-2 p-5"
                                    key={index}
                                    style={{
                                        width: "300px",
                                        borderRadius: "20px",
                                        backgroundColor: "rgb(250,255,255)",
                                    }}
                                >
                                    <Link
                                        to={item.path}
                                        style={{ textDecoration: "none" }}
                                    >
                                        <h3>{item.tag}</h3>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default Home;
