const validations = {
    isNull: (data) => data.length === 0,
    isNumber: (data) =>
        data.length === 0 ? false : data.trim().match(/^\d{10}$/),
    isInRange: (lower, upper, data) =>
        data.trim().length >= lower && data.trim().length <= upper,

    removeSpaces: (data) => (data.length === 0 ? "" : data.trim()),

    name: (firstName, lastName) => `${firstName.trim()} ${lastName.trim()}`,

    email: (email) => {
        email = email.trim();
        const validEmailRegex =
            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return email.match(validEmailRegex) ? email : "";
    },

    mobile: (mobile) => {
        let tempCopy = mobile;
        const numbersRegex = /^\d{10}$/;
        if (tempCopy.split(" ")[1].match(numbersRegex)) {
            return mobile;
        }
        return "";
    },
};

export default validations;
