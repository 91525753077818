import React from "react";
import { db } from "../../firebase";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

export class Volumes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            relseaedVolumes: [],
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        try {
            const response = await db.collection("JournalRelease").get();
            let volumes = [];
            response.forEach((volume) => {
                let temp = {};
                temp = volume.data();
                if (temp.releaseVisibility) {
                    volumes.push(temp);
                }
            });
            this.setState({ relseaedVolumes: volumes });
        } catch (err) {
            console.log("Error while fetching Journal Releases: ", err);
        } finally {
            this.setState({ loading: false });
        }
    }

    buildCards() {
        const journalCards = this.state.relseaedVolumes.map((volume, i) => {
            let link = `/research/Volume/${volume.releaseName}`;
            return (
                <Card style={{ width: "100%", margin: "40px" }}>
                    <Card.Header>{volume.releaseName}</Card.Header>
                    <Card.Body>
                        <Card.Title>KAIJOR | {volume.releaseName}</Card.Title>
                        <Card.Text>
                            {volume.releaseDate}
                            <br />

                            <Link to={link} style={{ textDecoration: "none" }}>
                                View {volume.releaseName}
                            </Link>
                        </Card.Text>
                    </Card.Body>
                </Card>
            );
        });
        return journalCards;
    }

    render() {
        return (
            <>
                <TopNav />
                <Header
                    heading="Volumes"
                    tagline="Kanzuliman Research Publications | KAIJOR Research Volumes"
                />
                <div className="container mt-5">
                    {this.state.loading ? (
                        <div className="text-center">
                            <span
                                className="spinner-border text-success"
                                style={{ width: "4rem", height: "4rem" }}
                                role="status"
                            />
                        </div>
                    ) : (
                        <>{this.buildCards()}</>
                    )}
                </div>
            </>
        );
    }
}

export default Volumes;
