import React, { Component } from "react";

export class files extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: "",
            mainClass: `ml-3`,
            label: this.props.label ? this.props.label : "Upload",
        };
    }

    onFileChange = (event) => {
        this.props.onData(event.target.files[0]);
    };

    render() {
        return (
            <div className="input-group mb-1 ml-3">
                <label className="input-group-text">{this.state.label}</label>
                <input
                    type="file"
                    onChange={this.onFileChange}
                    className="ml-3 mt-1"
                />
            </div>
        );
    }
}

export default files;
