import React from "react";
import { db } from "../../firebase";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import Table from "./utilities/table";

export class SpecificVolume extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            releaseData: [],
            loading: false,
        };
    }

    async componentDidMount() {
        this.setState({ loading: true });
        try {
            const response = await db
                .collection("Archives")
                .where("releasedWith", "==", this.props.match.params.volumeName)
                .get();
            let array = [];
            let E = [],
                H = [],
                A = [],
                U = [],
                P = [];
            response.forEach((res) => {
                let temp = {};
                temp = res.data();
                temp.id = res.id;
                if (temp.status === "VERIFIED") {
                    switch (temp.paperID[temp.paperID.length - 1]) {
                        case "E":
                            E.push(temp);
                            break;
                        case "H":
                            H.push(temp);
                            break;
                        case "A":
                            A.push(temp);
                            break;
                        case "U":
                            U.push(temp);
                            break;
                        case "P":
                            P.push(temp);
                            break;
                        default:
                            break;
                    }
                }
            });
            E.forEach((rec) => array.push(rec));
            H.forEach((rec) => array.push(rec));
            U.forEach((rec) => array.push(rec));
            A.forEach((rec) => array.push(rec));
            P.forEach((rec) => array.push(rec));

            this.setState({ releaseData: array });
        } catch (err) {
            console.log("Error fetching volume data: ", err);
        } finally {
            this.setState({ loading: false });
        }
    }

    render() {
        return (
            <>
                <TopNav />
                <Header
                    heading={`KAIJOR | ${this.props.match.params.volumeName}`}
                    tagline="Kanzuliman Research Publications | KAIJOR Research Volumes"
                />
                <div className="mt-5">
                    <Table array={this.state.releaseData} />
                </div>
            </>
        );
    }
}

export default SpecificVolume;
