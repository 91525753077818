import React from "react";
import TopNav from "../layout/navbar";
import Header from "../../layout/header";
import Table from "./utilities/table";
import { db } from "../../../firebase";

export class Role extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tagline: "",
            data: [],
            loading: false,
            currentURLParamOption: "",
        };
        this.fetchDataFromFirebaseDB = this.fetchDataFromFirebaseDB.bind(this);
    }

    async fetchDataFromFirebaseDB(role) {
        this.setState({
            loading: true,
        });
        let data = [];
        const response = await db
            .collection("users")
            .where("role", "==", role)
            .get();
        response.forEach((doc) => {
            let temp = {};
            temp = doc.data();
            temp.id = doc.id;
            data.push(temp);
        });
        this.setState({
            data: data,
            loading: false,
        });
    }

    async componentDidUpdate() {
        if (
            this.props.match.params.currentURLParamOption !==
            this.state.currentURLParamOption
        ) {
            switch (this.props.match.params.currentURLParamOption) {
                case "editorial":
                    this.setState({
                        title: "Editorial Board",
                        tagline: "Members of editorial board of KAIJOR",
                        currentURLParamOption: "editorial",
                    });
                    this.fetchDataFromFirebaseDB(
                        this.props.match.params.currentURLParamOption
                    );
                    break;
                case "reviewer":
                    this.setState({
                        title: "Reviewer Board",
                        tagline: "Members of reviewer board of KAIJOR",
                        currentURLParamOption: "reviewer",
                    });
                    this.fetchDataFromFirebaseDB(
                        this.props.match.params.currentURLParamOption
                    );
                    break;
                case "advisory-member":
                    this.setState({
                        title: "Advisory Board",
                        tagline: "Members of advisory board of KAIJOR",
                        currentURLParamOption: "advisory-member",
                    });
                    this.fetchDataFromFirebaseDB(
                        this.props.match.params.currentURLParamOption
                    );
                    break;
                case "volunteer":
                    this.setState({
                        title: "Our Vounteers",
                        tagline: "Volunteers of KAIJOR | Kanzuliman Foundation",
                        currentURLParamOption: "volunteer",
                    });
                    this.fetchDataFromFirebaseDB(
                        this.props.match.params.currentURLParamOption
                    );
                    break;
                case "associate-editorial":
                    this.setState({
                        title: "Associate Editorial Board",
                        tagline:
                            "Members of associate editorial board of KAIJOR",
                        currentURLParamOption: "associate-editorial",
                    });
                    this.fetchDataFromFirebaseDB(
                        this.props.match.params.currentURLParamOption
                    );
                    break;
                default:
                    break;
            }
        }
    }

    async componentDidMount() {
        this.componentDidUpdate();
    }

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading={this.state.title}
                    tagline={this.state.tagline}
                />
                <div className="container main">
                    <div>
                        <Table
                            data={this.state.data}
                            updateComponentData={this.fetchDataFromFirebaseDB}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Role;
