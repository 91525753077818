import React from "react";
import countryCodes from "./utilities/countryCodes";
import validators from "./utilities/validations";

export class mobile extends React.Component {
    constructor(props) {
        super(props);
        this.codes = countryCodes();
        this.state = {
            inputClass: "ml-lg-3 ml-md-2 ml-sm-1 form-control",
            invalidMobile: false,
            mainClass: `col-lg-${this.props.cols} form-group row`,
            mobile: this.props.dataNumber ? this.props.dataNumber : "",
            dataCode: this.props.dataCode ? this.props.dataCode : "",
        };
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.mobile !== this.state.mobile) {
            let result = validators.isNumber(this.state.mobile);
            this.setState({ invalidMobile: result === null }, () =>
                this.props.emitError(
                    this.state.invalidMobile ||
                        this.state.mobile.trim().length === 0
                )
            );
        }

        if (prevProps.validate !== this.props.validate && this.props.validate) {
            let result = validators.isNumber(this.state.mobile);
            this.setState({ invalidMobile: result === null }, () => {
                if (this.state.invalidMobile)
                    this.setState({ inputClass: "form-control is-invalid" });
                else if (this.state.mobile.trim().length === 0)
                    this.setState({ inputClass: "form-control is-invalid" });
                else this.setState({ inputClass: "form-control is-valid" });

                this.props.emitError(
                    this.state.invalidMobile ||
                        this.state.mobile.trim().length === 0
                );
            });
        }

        if (prevProps.dataCode !== this.props.dataCode) {
            this.setState({
                dataCode: this.props.dataCode,
            });
        }
    }

    codeEventHandler = async (event) => {
        this.props.onCode(event.target.value);
        await this.setState({
            dataCode: event.target.value,
        });
    };

    inputEventHandler = (event) => {
        this.setState({
            inputClass: "ml-lg-3 ml-md-2 ml-sm-1 form-control",
            invalidMobile: false,
        });

        this.setState({ mobile: event.target.value }, () => {
            let result = validators.isNumber(this.state.mobile);
            this.setState({ invalidMobile: result === null }, () => {
                if (this.props.validate) {
                    if (this.state.invalidMobile)
                        this.setState({
                            inputClass: "form-control is-invalid",
                        });
                    else if (this.state.mobile.trim().length === 0)
                        this.setState({
                            inputClass: "form-control is-invalid",
                        });
                    else this.setState({ inputClass: "form-control is-valid" });
                }
            });
            this.props.onNumber(this.state.mobile);
            this.props.emitError(
                this.state.invalidMobile ||
                    this.state.mobile.trim().length === 0
            );
        });
    };

    render() {
        return (
            <div className={this.state.mainClass}>
                <label className="col-12">Mobile Number *</label>
                <div className="col-4">
                    <select
                        onChange={this.codeEventHandler}
                        value={this.state.dataCode}
                        className="form-control"
                    >
                        {Object.keys(this.codes).map((country) => (
                            <option value={this.codes[country]}>
                                {country} ({this.codes[country]})
                            </option>
                        ))}
                    </select>
                </div>
                <div className="col-8">
                    <input
                        onChange={this.inputEventHandler}
                        type="text"
                        className={this.state.inputClass}
                        value={this.state.mobile}
                        placeholder={this.props.placeholder}
                    />
                    {this.state.invalidMobile && (
                        <div className="invalid-feedback">
                            Please enter your valid mobile number.
                        </div>
                    )}
                    {!this.state.invalidMobile && (
                        <div className="invalid-feedback">
                            Please enter your mobile number.
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default mobile;
