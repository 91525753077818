import React from "react";
import TopNav from "../layout/navbar";
import Header from "../../layout/header";
import Table from "./utilities/table";
import { db } from "../../../firebase";
import { Button, Modal } from "react-bootstrap";

export class Role extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            releaseName: "",
            releaseDate: "",
            releaseVisibility: false,
            showRelease: false,
            loading: false,
            error: "",
            arrayVerified: [],
            arrayUnverified: [],
            arrayJournalRelease: [],
        };
    }

    update = async () => {
        this.setState({ loading: true });
        let verified = [];
        let unVerified = [];
        let journalRelease = [];
        try {
            let response = await db.collection("Archives").get();
            let responseJournalRelease = await db
                .collection("JournalRelease")
                .get();
            response.forEach((doc) => {
                let temp = {};
                temp = doc.data();
                temp.id = doc.id;
                if (temp.status === "VERIFIED") {
                    verified.push(temp);
                } else if (temp.status === "NOT_VERIFIED") {
                    unVerified.push(temp);
                }
            });
            responseJournalRelease.forEach((res) => {
                let temp = {};
                temp = res.data();
                journalRelease.push(temp.releaseName);
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.setState({ arrayVerified: verified });
            this.setState({ arrayUnverified: unVerified });
            this.setState({ arrayJournalRelease: journalRelease });
            this.setState({ loading: false });
        }
    };

    async handleReleaseSubmit() {
        if (
            this.state.releaseName.length === 0 ||
            this.state.releaseDate.length === 0
        ) {
            this.setState({
                error: "Error: cannot create blank journal release",
            });
            return;
        } else {
            this.setState({ error: "" });
            try {
                await db.collection("JournalRelease").add({
                    releaseName: this.state.releaseName,
                    releaseDate: this.state.releaseDate,
                    releaseVisibility: this.state.releaseVisibility,
                });
            } catch (err) {
                console.warn("Error: Cannot create new journal release: ", err);
            } finally {
                this.setState({
                    showRelease: false,
                    releaseName: "",
                    releaseDate: "",
                    error: "",
                });
            }
        }
    }

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Archive's Section"
                    tagline="Kanzuliman Research Publication"
                />
                {this.state.showRelease && (
                    <Modal
                        show={this.state.showRelease}
                        onHide={() =>
                            this.setState({ showRelease: false, error: "" })
                        }
                        centered
                        scrollable
                        size="lg"
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                Create New Journal Release
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.error ? (
                                <span
                                    className="alert alert-danger mt-3 mb-3"
                                    role="alert"
                                >
                                    {this.state.error}
                                </span>
                            ) : (
                                <></>
                            )}
                            <div className="row mt-3">
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                            this.setState({
                                                releaseName: e.target.value,
                                            })
                                        }
                                        placeholder="Release Name"
                                    />
                                </div>
                                <div className="col-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) =>
                                            this.setState({
                                                releaseDate: e.target.value,
                                            })
                                        }
                                        placeholder="Release Date"
                                    />
                                </div>
                            </div>
                            <Button
                                className="btn btn-dark btn-md mt-4 float-right"
                                onClick={() => this.handleReleaseSubmit()}
                            >
                                Create
                            </Button>
                        </Modal.Body>
                    </Modal>
                )}
                <div className="container main">
                    <div className="row m-4">
                        <div className="col">
                            <Button
                                className="btn btn-dark btn-md float-right"
                                onClick={() =>
                                    this.setState({ showRelease: true })
                                }
                            >
                                Create Release
                            </Button>
                        </div>
                    </div>
                    <nav>
                        <div
                            className="nav nav-tabs nav-fill"
                            id="nav-tab"
                            role="tablist"
                        >
                            <a
                                className="nav-link active"
                                id="nav-profile-tab"
                                data-bs-toggle="tab"
                                href="#verified"
                                role="tab"
                                aria-controls="nav-profile"
                                aria-selected="false"
                            >
                                Verified
                            </a>
                            <a
                                className="nav-link"
                                id="nav-contact-tab"
                                data-bs-toggle="tab"
                                href="#not_verified"
                                role="tab"
                                aria-controls="nav-contact"
                                aria-selected="false"
                            >
                                Pending
                            </a>
                        </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                        <div
                            className="tab-pane fade  show active"
                            id="verified"
                            role="tabpanel"
                            aria-labelledby="nav-profile-tab"
                        >
                            {!this.state.loading ? (
                                <Table
                                    type="VERIFIED"
                                    array={this.state.arrayVerified}
                                    arrayJournalRelease={
                                        this.state.arrayJournalRelease
                                    }
                                    update={this.update}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <div
                            className="tab-pane fade"
                            id="not_verified"
                            role="tabpanel"
                            aria-labelledby="nav-contact-tab"
                        >
                            {!this.state.loading ? (
                                <Table
                                    type="NOT_VERIFIED"
                                    array={this.state.arrayUnverified}
                                    arrayJournalRelease={
                                        this.state.arrayJournalRelease
                                    }
                                    update={this.update}
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    async componentDidMount() {
        await this.update();
    }
}

export default Role;
