import React from "react";
import "./styles.css";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import axios from "axios";
import { db } from "../../firebase";
import Number from "../formGroup/mobile";
import { appConfig } from "../../config";

import Field from "../formGroup/Field";
import countries from "../formGroup/utilities/countries";
import validators from "./utilities/validations";
import designations from "../formGroup/utilities/designations";

export class editorial extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            country: "",
            code: "+91",
            number: "",
            designation: "",
            email: "",
            proceed: false,
            disabled: true,
            invalidEmail: false,
            invalidMobile: false,
            isNull: false,
            validate: false,
            showError: false,
            showSpinner: false,
            errorfirstName: true,
            errorlastName: true,
            errorcountry: true,
            errorcity: true,
            errorState: true,
            erroraddress: true,
            erroremail: true,
            errorPINCode: true,
            errormobile: true,
            errordesignation: true,
        };
    }

    clickHandler = async (event) => {
        event.preventDefault();

        await this.setState({ validate: true, showError: false });
        if (!this.errorHandler()) {
            return;
        }
        await this.setState({ disable: true, showSpinner: true });

        try {
            if (
                validators.isNull(this.state.firstName) ||
                validators.isNull(this.state.lastName) ||
                validators.isNull(this.state.number)
            )
                this.setState({ isNull: true });

            const requestSent = {
                name: validators.name(
                    this.state.firstName,
                    this.state.lastName
                ),
                address: this.state.address,
                city: this.state.city,
                state: this.state.state,
                country: this.state.country,
                PINCode: this.state.PINCode,
                email: validators.email(this.state.email),
                mobile: `${this.state.code} ${this.state.number}`,
                designation: validators.removeSpaces(this.state.designation),
                role: "volunteer",
            };

            await db.collection("users").add(requestSent);

            try {
                const mailOptions = {
                    subject: `New Volunteer Application from ${requestSent.name}`,
                    text: `Hey Admin, greeting from Developers Kanzuliman, ${requestSent.name} has submiited a new Volunteer Application, kindly review the application and provide suitable response.
Name: ${requestSent.name}
Email: ${requestSent.email}
Contact: ${requestSent.mobile}



Regards,
Developers Kanzuliman
                    `,
                    html: "",
                };
                /// utilising email notification only in production mode
                if (
                    window.location.hostname !== "localhost" &&
                    window.location.hostname !== "127.0.0.1"
                ) {
                    await axios.post(
                        `${appConfig.emailEndpoint}sendEmail`,
                        mailOptions
                    );
                }
            } catch (err) {
                console.log("MailError: Unabel to send email", err);
            }

            this.props.history.push("/join/success");
        } catch (err) {
            await this.setState({
                disabled: false,
                showError: true,
                showSpinner: false,
            });
        }
    };

    errorHandler = () => {
        if (this.state.errorfirstName) return false;
        if (this.state.errorlastName) return false;
        if (this.state.erroraddress) return false;
        if (this.state.errorcity) return false;
        if (this.state.errorcountry) return false;
        if (this.state.errormobile) return false;
        if (this.state.errordesignation) return false;
        if (this.state.erroremail) return false;

        return true;
    };

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Volunteer Form"
                    tagline="Kanzuliman Research Publication"
                />
                <div className="container main">
                    <div className="container">
                        <p>
                            The KAIJOR welcomes competent students, scholars and
                            activist to join us as Volunteer. Being a Volunteer
                            is a matter of prestige and personal achievement.
                            KAIJOR follows a flexible set of guidelines while
                            accepting the applicant as a volunteer of KAIJOR.
                            The minimum set of qualifications for being a
                            volunteer of KAIJOR are listed below
                        </p>
                        <ul>
                            <li>
                                Candidate should be a holder or aspirant of
                                Bachelor or Equivalent degree from any
                                recognized university.
                            </li>
                            <li>
                                Candidate should be keen interested to write and
                                publish a research article in reputed journals
                                or magazine.
                            </li>
                        </ul>
                    </div>
                    <form className="mt-4">
                        <span className="">
                            Please fill the form below to apply as a volunteer
                            member of Kanzuliman Academic Research Journal.
                        </span>

                        {this.state.showError && (
                            <div className="alert alert-danger" role="alert">
                                Some error has occured, please check your
                                network connection and try again!
                            </div>
                        )}

                        <div className="row mt-5">
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(firstName) =>
                                    this.setState({ firstName })
                                }
                                emitError={(errorfirstName) =>
                                    this.setState({ errorfirstName })
                                }
                                label="First Name *"
                                validations={[
                                    [
                                        "required",
                                        "Please provide your first name",
                                    ],
                                    ["min", 2],
                                    ["max", 20],
                                ]}
                                type="text"
                                onChange={(errorfirstName) =>
                                    this.setState({ errorfirstName })
                                }
                            />
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(lastName) =>
                                    this.setState({ lastName })
                                }
                                onChange={(errorlastName) =>
                                    this.setState({ errorlastName })
                                }
                                label="Last Name *"
                                validations={[
                                    ["required"],
                                    ["min", 2],
                                    ["max", 20],
                                ]}
                                type="text"
                            />
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(email) => this.setState({ email })}
                                onChange={(erroremail) =>
                                    this.setState({ erroremail })
                                }
                                label="Email Address *"
                                validations={[["required"], ["email"]]}
                                type="email"
                            />
                            <Number
                                onNumber={(number) => this.setState({ number })}
                                onCode={(code) => this.setState({ code })}
                                cols="6"
                                validate={this.state.validate}
                                emitError={(errormobile) =>
                                    this.setState({ errormobile })
                                }
                            />
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(designation) =>
                                    this.setState({ designation })
                                }
                                onChange={(errordesignation) =>
                                    this.setState({ errordesignation })
                                }
                                label="Designation *"
                                validations={[["required"]]}
                                type="select"
                                options={designations}
                            />
                            <Field
                                cols="12"
                                validate={this.state.validate}
                                onData={(address) => this.setState({ address })}
                                onChange={(erroraddress) =>
                                    this.setState({ erroraddress })
                                }
                                label="Address *"
                                validations={[["required"]]}
                                type="text"
                            />
                            <Field
                                cols="4"
                                validate={this.state.validate}
                                onData={(city) => this.setState({ city })}
                                onChange={(errorcity) =>
                                    this.setState({ errorcity })
                                }
                                label="City *"
                                validations={[["required"]]}
                                type="text"
                            />
                            <Field
                                cols="4"
                                validate={this.state.validate}
                                onData={(state) => this.setState({ state })}
                                onChange={(errorState) =>
                                    this.setState({ errorState })
                                }
                                label="State *"
                                validations={[["required"]]}
                                type="text"
                            />
                            <Field
                                cols="4"
                                validate={this.state.validate}
                                onData={(PINCode) => this.setState({ PINCode })}
                                onChange={(errorPINCode) =>
                                    this.setState({ errorPINCode })
                                }
                                label="PIN Code *"
                                validations={[["required"]]}
                                type="text"
                            />
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(country) => this.setState({ country })}
                                onChange={(errorcountry) =>
                                    this.setState({ errorcountry })
                                }
                                label="Country *"
                                validations={[
                                    ["required", "Please select an option"],
                                ]}
                                type="select"
                                options={countries}
                            />
                            <div className="form-group container">
                                <div className="ml-2 container form-group">
                                    <input
                                        onClick={() =>
                                            this.setState({
                                                disabled: !this.state.disabled,
                                            })
                                        }
                                        className="form-check-input"
                                        type="checkbox"
                                        value=""
                                    />
                                    <label
                                        className="form-check-label text-muted"
                                        htmlFor="flexCheckDefault"
                                    >
                                        I agree to volunteer for Kanzuliman and
                                        adhere to all the rules and regulations
                                        implied on you and fulfill your duties
                                        to the best of your capacity?
                                    </label>
                                </div>
                            </div>
                            <div className="col-12 form-group">
                                <button
                                    onClick={this.clickHandler}
                                    className="btn btn-dark"
                                    disabled={this.state.disabled}
                                >
                                    {!this.state.showSpinner && (
                                        <span>Join Us</span>
                                    )}
                                    {this.state.showSpinner && (
                                        <i className="fa fa-spinner fa-spin fa-1x" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default editorial;
