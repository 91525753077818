const options = [
    "Assistant Professor",
    "Professor",
    "H.O.D",
    "Principal",
    "Associate Professor",
    "Lecturer",
    "Technical Assistant",
    "Laboratory Technician",
    "Jr. Scientist",
    "Sr. Scientist",
    "Scientist",
    "Director",
    "Professional (Researcher)",
    "Researcher",
    "Dean",
    "Senior Lecturer",
    "Postdoctoral Fellow",
    "PhD Student",
    "Master Student",
    "Bachelor Student",
];

export default options;
