import React, { Component } from "react";
import { Link } from "react-router-dom";

export class table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: "",
        };
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-4 form-group container m-3">
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                id="basic-addon1"
                            >
                                <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                />
                            </span>
                            <input
                                className="form-control"
                                placeholder="Type to search..."
                                onChange={(event) =>
                                    this.setState({
                                        search: event.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <table className="table table-hover table-info table-responsive-sm">
                    <thead className="table-dark">
                        <tr>
                            <th scope="col">Paper ID</th>
                            <th scope="col">Title</th>
                            <th scope="col">Author</th>
                            <th scope="col">Type</th>
                            <th scope="col">Pages</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.array.map((data) => {
                            let show = false;
                            const filter = this.state.search.toUpperCase();

                            Object.keys(data).forEach((key) => {
                                if (
                                    key !== "status" ||
                                    key !== "_id" ||
                                    key !== "__v" ||
                                    key !== "id"
                                ) {
                                    let value = data[key];
                                    if (typeof value === "number")
                                        value = value.toString();

                                    if (value !== null)
                                        if (
                                            value
                                                .toUpperCase()
                                                .indexOf(filter) > -1
                                        )
                                            show = true;
                                }
                            });
                            if (show === true)
                                return (
                                    <tr>
                                        <td>{data.paperID}</td>
                                        <Link
                                            to={`/research/${data.id}`}
                                            style={{ textDecoration: "none" }}
                                        >
                                            <td
                                                className={data.language}
                                                dir="rtl"
                                            >
                                                {data.title}
                                            </td>
                                        </Link>
                                        <td className={data.language}>
                                            {data.name}
                                        </td>
                                        <td>{data.type}</td>
                                        <td>{data.pages}</td>
                                    </tr>
                                );
                            else return null;
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default table;
