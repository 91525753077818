import React from "react";
import "./mission.css";
import TopNav from "../layout/topnav";
import Header from "../layout/header";

class Mission extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Mission and Scope"
                    tagline="Kanzuliman Research Publication"
                />
                <div className="container mt-5 w3-animate-zoom">
                    <h1 className="w3-animate-right">Our Mission</h1>
                    <p className="w3-animate-opacity">
                        <i>
                            <strong>
                                Kanzuliman Academic International Journal On
                                Razawiyât | KAIJOR
                            </strong>
                        </i>
                        <br />
                        Our Mission is to inculcate the spirit of Research among
                        the youth. Redressing the present desperate conditions
                        and rejuvenating the past glory of mankind in the field
                        of research. Through the journal we aim to establish a
                        congregation of intellectuals and thinkers who can
                        tackle the modern day problems, especially the declining
                        moral, ethical and humanitarian values in the world.
                        Research and Development is based on empirical,
                        pragmatic, statistical and matter-of-fact of fact
                        analysis of data and information to deduce concrete
                        results which are universally accepted and appreciated.
                        We target to achieve this by instilling an analytical
                        approach in the literate masses to help them achieve
                        actual objectives. Imam Ahmad Raza (may Allah sanctify
                        his secret) he was an Islamic scholar as well as an
                        acknowledged scientist who believed in the empirical
                        analysis of data rather than making emotionally charged
                        statements, his verdicts contain great deal of details
                        and a plethora of evidences aiding his cause. Our
                        mission us to spread this approach in every nook and
                        corner of the world so that modern scholarly scientists
                        are born who can reconcile between the leading-edge
                        technology and the antique traditions.
                    </p>
                    <h1 className="w3-animate-right">Scope</h1>
                    <p className="w3-animate-opacity">
                        <strong>
                            The Journal invites research papers on following
                            relevant topics
                        </strong>
                    </p>
                    <ul className="w3-animate-opacity">
                        <li>
                            A Sufi path of the fourteenth century – Barelwis:
                            the Sufi ideology of Imam Ahmad Raza Khan.
                        </li>
                        <li>
                            Imam Ahmad Raza Khan, A Revivalist : His acclaim
                            from Delhi to Mecca and Medina.
                        </li>
                        <li>
                            Imam Ahmad Raza Khan and his expertise Arabic and
                            Persian Poetry
                        </li>
                        <li>
                            Comparative Study on the Traditional and Modern
                            versions of Islam.
                        </li>
                        <li>
                            Razawiyāt – a platform to unite the Believers of the
                            True Faith.
                        </li>
                        <li>
                            Relation and Friendship of Shahji Miyan with Imam
                            Ahmad Raza Khan.
                        </li>
                        <li>
                            Sects Within the Ahlus Sunnah Hanafis : The Case
                            Study of Deobandi–Barelvi Conflict in India.
                        </li>
                        <li>
                            Comparative Study on the working methodologies of
                            Sheikh Ahmad Sarhindi and Imam Ahmad Raza Khan.
                        </li>
                        <li>
                            The Seal of Modern Science on the Islamic decree of
                            Imam Ahmad Raza and Imam Mustafa Raza Khan on
                            Television and Mic.
                        </li>
                        <li>
                            Android and Windows Software development for the
                            Qur’anic Pronunciation error detection.
                        </li>
                        <li>
                            A technical report on finding the location of Sunni
                            Mosques based on IT and Google Maps for
                            Congregational Prayers.
                        </li>
                        <li>
                            Poetry of Raza’s family: Odes for his eminence The
                            Prophet ﷺ
                        </li>
                        <li>Indian Sufism in the Twentieth Century.</li>
                        <li>
                            Literature and Empire of the Muslims in Rohilkhand
                            and impact of Ala Hazrat’s writings.
                        </li>
                        <li>
                            Influence of Imam Ahmad Raza in Worldwide reseaches,
                            especially in the works of contemporary scholars.
                        </li>
                        <li>
                            Research on Islamıc Busıness Ethics and Currency on
                            the foot Prints of Imam Ahmad Raza Khan.
                        </li>
                        <li>
                            Islamıc Banking according to Imam Ahmad Raza Khan.
                        </li>
                        <li>
                            Analysis of Ala Hazrat: A Chemical approach on Water
                            Testing to determine its odor and color.
                        </li>
                        <li>
                            A Chemical approach of Soil Testing exploring 80
                            Natures of Soil Imam Ahmad Raza’s endeavor.
                        </li>
                        <li>
                            Scientific findings on the color of water and the
                            analysis in Fatwa Razawiyya.
                        </li>
                        <li>
                            Michelson Morley Experiment and similar findings
                            serving as evidences for Fauz-e-Mubeen Dar
                            Radd-e-Harkat-e-Zameen.
                        </li>
                        <li>
                            Philosophical, Oriental and Evolutional aspects of
                            the Ideologies of Sufi Saints.
                        </li>
                        <li>Other related topics.</li>
                    </ul>
                    <h1 className="w3-animate-right">Areas</h1>
                    <ul className="w3-animate-opacity">
                        <li>
                            Qur’an, Hadith, Fiqh, Usool ul Fiqh, Usool ul
                            Hadith, Rhetoric, Qur’anic eloquence.
                        </li>
                        <li>Islam and Human Values.</li>
                        <li>Literature and True Sufism.</li>
                        <li>Footprints and Biographies of Saints.</li>
                        <li>Islamic Education.</li>
                        <li>Islamic Management Science.</li>
                    </ul>
                    <strong>
                        {" "}
                        Any other related area or topic pertaining to related
                        issues may be considered as per the theme of the
                        Journal.
                    </strong>
                </div>
            </div>
        );
    }
}

export default Mission;
