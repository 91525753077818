import React, { Component } from "react";
import { db, storage } from "../../../firebase";
import CreativeCommonsImage from "../../../assets/cc.png";
import DigiproveLogo from "../../../assets/digi.png";

export class information extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            preStyle: {
                whiteSpace: "pre-wrap",
                wordBreak: "break-word",
            },
            downloadUrl: "",
        };
    }

    capitalize = (word) =>
        typeof word !== "string"
            ? ""
            : word.charAt(0).toUpperCase() + word.slice(1);

    async componentDidUpdate(prevProps) {
        if (prevProps.id !== this.props.id) {
            this.componentDidMount();
        }
    }

    parseDOI(DOI) {
        if (DOI) {
            let splittedArray = DOI.split("]");
            let title = splittedArray[0].substr(1);
            let url = splittedArray[1].substr(1, splittedArray[1].length - 2);
            return (
                <>
                    <a
                        href={url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none" }}
                    >
                        {title}
                    </a>
                </>
            );
        }
    }

    render() {
        let referenceClassName = `${this.state.data.language} collapse`;
        return (
            <div className="mt-5">
                <div className="container">
                    <table className="table table-striped">
                        <tbody>
                            <tr>
                                <td>Title</td>
                                <td className={this.state.data.language}>
                                    <strong>{this.state.data.title}</strong>
                                </td>
                            </tr>
                            <tr>
                                <td>Author</td>
                                <td className={this.state.data.language}>
                                    {this.state.data.name}
                                </td>
                            </tr>
                            <tr>
                                <td>Institute</td>
                                <td>{this.state.data.institute}</td>
                            </tr>
                            <tr>
                                <td>Language</td>
                                <td>{this.state.data.language}</td>
                            </tr>
                            <tr>
                                <td>Area of Study</td>
                                <td>{this.state.data.areaOfStudy}</td>
                            </tr>
                            {/* default DOI value is []() so empty DOI will be of length 4 */}
                            {this.state.data.DOI !== undefined &&
                            this.state.data.DOI.length > 4 ? (
                                <tr>
                                    <td>DOI</td>
                                    <td>
                                        {this.parseDOI(this.state.data.DOI)}
                                    </td>
                                </tr>
                            ) : (
                                <></>
                            )}
                            {this.state.data.paperID !== undefined &&
                            this.state.data.paperID !== "" ? (
                                <tr>
                                    <td>Paper ID</td>
                                    <td>{this.state.data.paperID}</td>
                                </tr>
                            ) : (
                                <></>
                            )}
                            <tr>
                                <td>Keywords</td>
                                <td
                                    style={this.state.preStyle}
                                    className={this.state.data.language}
                                >
                                    {this.state.data.keywords}
                                </td>
                            </tr>
                            {this.state.data.pdfFile &&
                            this.state.data.downloadLink === "enable" ? (
                                <tr>
                                    <td>Download</td>
                                    <td>
                                        <a
                                            href={this.state.downloadUrl}
                                            target="blank"
                                        >
                                            Download Full Text
                                        </a>
                                    </td>
                                </tr>
                            ) : (
                                <></>
                            )}
                        </tbody>
                    </table>
                    <div className="container mt-4">
                        <h3 className="mt-5">Abstract</h3>
                        <p className={this.state.data.language} dir="rtl">
                            {this.state.data.abstract}
                        </p>
                        <div style={{ paddingTop: "50px" }} />
                        <a
                            href="#references"
                            data-toggle="collapse"
                            style={{ textDecoration: "none" }}
                        >
                            <h4>References</h4>
                        </a>

                        <div
                            id="references"
                            className={referenceClassName}
                            style={this.state.preStyle}
                        >
                            {this.state.data.refrences}
                        </div>
                    </div>
                    <div className="mt-5 ml-3">
                        <a
                            href="http://creativecommons.org/licenses/by/4.0/"
                            target="blank"
                        >
                            <img
                                src={CreativeCommonsImage}
                                alt="creative commons license logo."
                            />
                        </a>
                        <br />
                        <span>This work is licensed under a </span>
                        <a
                            href="http://creativecommons.org/licenses/by/4.0/"
                            style={{ textDecoration: "none" }}
                            target="blank"
                        >
                            Creative Commons Attribution 4.0 International
                            License
                        </a>
                        <br />
                        {this.state.data.DOI !== undefined &&
                        this.state.data.DOI.length > 4 ? (
                            <span className="mt-2">
                                DOI {this.parseDOI(this.state.data.DOI)} &#xa9;{" "}
                                {this.state.data.releasedWith}. Hosted by KAIJOR{" "}
                                <br />
                            </span>
                        ) : (
                            <></>
                        )}
                        <br />
                        <a
                            href={this.state.data.digiproveURL}
                            style={{ textDecoration: "none" }}
                            target="blank"
                        >
                            <span style={{ border: "1px solid black" }}>
                                <img
                                    src={DigiproveLogo}
                                    alt="Digiprove logo"
                                    height="20"
                                    width="20"
                                />
                                <span className="ml-2">
                                    Copyright secured by Digiprove &#xa9; 2020
                                    KANZULIMAN Foundation
                                </span>
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount = async () => {
        const response = await db
            .collection("Archives")
            .doc(this.props.id)
            .get();
        const data = { ...response.data(), id: this.props.id };
        this.setState({ data: data });
        try {
            if (this.state.data.pdfFile) {
                const downloadUrl = await storage
                    .ref()
                    .child(`archives/PdfFile/${this.state.data.pdfFile}`)
                    .getDownloadURL();
                this.setState({ downloadUrl });
            }
        } catch (err) {
            console.log("Error: Cannot fetch download url ", err);
        }
    };
}

export default information;
