import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import TopNav from "../layout/topnav";
import Header from "../layout/header";

export default function ForgotPassword() {
    const emailRef = useRef();
    const { resetPassword } = useAuth();
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    async function handleSubmit(e) {
        e.preventDefault();

        try {
            setMessage("");
            setError("");
            setLoading(true);
            await resetPassword(emailRef.current.value);
            setMessage("An email has been sent to your registered account.");
        } catch {
            setError("Unable to reset password");
        }

        setLoading(false);
    }

    return (
        <>
            <TopNav />
            <Header
                heading="Kanzuliman Research"
                tagline="Kanzuliman Academic International Journal on Razawiyāt (KAIJOR) world's first academic research journal on the subject of Razawiyāt"
            />
            <Container
                className="mt-5 d-flex align-items-center justify-content-center"
                style={{ minHeight: "50vh" }}
            >
                <div className="w-100" style={{ maxWidth: "700px" }}>
                    <Card>
                        <Card.Body>
                            <h2 className="text-center mb-4">Password Reset</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            {message && (
                                <Alert variant="success">{message}</Alert>
                            )}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group id="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="email"
                                        ref={emailRef}
                                        required
                                    />
                                </Form.Group>
                                <Button
                                    disabled={loading}
                                    className="w-100 btn-dark"
                                    type="submit"
                                >
                                    Reset Password
                                </Button>
                            </Form>
                            <div className="w-100 text-center mt-3">
                                <Link
                                    to="/login"
                                    style={{ textDecoration: "none" }}
                                >
                                    Login
                                </Link>
                            </div>
                        </Card.Body>
                        <div className="w-100 text-center mt-2">
                            Need an account?{" "}
                            <Link
                                to="/signup"
                                style={{ textDecoration: "none" }}
                            >
                                Sign Up
                            </Link>
                        </div>
                    </Card>
                </div>
            </Container>
        </>
    );
}
