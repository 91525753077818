import React from "react";

export class languages extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mainClass: `col-lg-${this.props.cols} form-group`,
            mainLabel: `Your language(s) of ${this.props.type}`,
            languageArabic: this.props.data
                ? this.props.data.split(", ")[0].split(",").includes("Arabic")
                : false,
            languageEnglish: this.props.data
                ? this.props.data.split(", ")[0].split(",").includes("English")
                : false,
            languagePersian: this.props.data
                ? this.props.data.split(", ")[0].split(",").includes("Persian")
                : false,
            languageUrdu: this.props.data
                ? this.props.data.split(", ")[0].split(",").includes("Urdu")
                : false,
            languageHindi: this.props.data
                ? this.props.data.split(", ")[0].split(",").includes("Hindi")
                : false,
            errorlanguage: this.props.data
                ? ""
                : "Please select at least one language",
        };
    }

    getLanguages = () => {
        let languages = "";
        if (this.state.languageArabic) languages += "Arabic,";
        if (this.state.languageEnglish) languages += "English,";
        if (this.state.languageUrdu) languages += "Urdu,";
        if (this.state.languagePersian) languages += "Persian,";
        if (this.state.languageHindi) languages += "Hindi";

        return languages;
    };

    inputEventHandler = async (language) => {
        if (language === "arabic")
            await this.setState({ languageArabic: !this.state.languageArabic });
        else if (language === "english")
            await this.setState({
                languageEnglish: !this.state.languageEnglish,
            });
        else if (language === "urdu")
            await this.setState({ languageUrdu: !this.state.languageUrdu });
        else if (language === "persian")
            await this.setState({
                languagePersian: !this.state.languagePersian,
            });
        else if (language === "hindi")
            await this.setState({ languageHindi: !this.state.languageHindi });

        let l = this.getLanguages();
        if (!l) {
            this.setState({
                errorlanguage: "Please select at least one language",
            });
        } else {
            this.setState({ errorlanguage: "" });
        }
        this.props.onData(this.getLanguages());
    };

    render() {
        return (
            <div className={this.state.mainClass}>
                <label>{this.state.mainLabel}</label>
                <div className="form-check">
                    <input
                        className="form-check-input"
                        onChange={() => this.inputEventHandler("arabic")}
                        type="checkbox"
                        value={this.state.languageArabic}
                        id="flexCheckDefault1"
                        checked={this.state.languageArabic}
                    />
                    <label
                        className="form-check-label"
                        htmlFor="flexCheckDefault1"
                    >
                        Arabic
                    </label>
                    <span className="ml-lg-4 ml-5">
                        <input
                            className="form-check-input"
                            onChange={() => this.inputEventHandler("english")}
                            type="checkbox"
                            value={this.state.languageEnglish}
                            id="flexCheckDefault2"
                            checked={this.state.languageEnglish}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault2"
                        >
                            English
                        </label>
                    </span>
                    <span className="ml-lg-4 ml-5">
                        <input
                            className="form-check-input"
                            onChange={() => this.inputEventHandler("urdu")}
                            type="checkbox"
                            value={this.state.languageUrdu}
                            id="flexCheckDefault3"
                            checked={this.state.languageUrdu}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault3"
                        >
                            Urdu
                        </label>
                    </span>
                    <span className="ml-lg-4 ml-5">
                        <input
                            className="form-check-input"
                            onChange={() => this.inputEventHandler("hindi")}
                            type="checkbox"
                            value={this.state.languageHindi}
                            id="flexCheckDefault4"
                            checked={this.state.languageHindi}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault4"
                        >
                            Hindi
                        </label>
                    </span>
                    <span className="ml-lg-4 ml-5">
                        <input
                            className="form-check-input"
                            onChange={() => this.inputEventHandler("persian")}
                            type="checkbox"
                            value={this.state.languagePersian}
                            id="flexCheckDefault5"
                            checked={this.state.languagePersian}
                        />
                        <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault5"
                        >
                            Persian
                        </label>
                    </span>
                </div>
                <span className="text-danger">{this.state.errorlanguage}</span>
            </div>
        );
    }
}

export default languages;
