import React, { Component } from "react";
import TopNav from "./layout/navbar";
import Header from "../layout/header";
import { db } from "../../firebase";

class ContactList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            userList: [],
        };

        this.sortByName = this.sortByName.bind(this);
        this.sortByRole = this.sortByRole.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: true });
        try {
            let userList = [];
            let response = await db.collection("users").get();
            response.forEach((record) => {
                let temp = {};
                temp = record.data();
                temp.id = record.id;
                userList.push(temp);
            });
            response = await db.collection("Archives").get();
            response.forEach((record) => {
                let temp = {};
                temp = record.data();
                temp.id = record.id;
                temp.role = "author";
                if (temp.status === "VERIFIED") {
                    userList.push(temp);
                }
            });
            this.setState({ userList });
            this.sortByName();
        } catch (err) {
            console.log("Error fetching userlist data", err);
        }
        this.setState({ loading: false });
    }

    sortByName() {
        let userList = this.state.userList;
        userList.sort((first, second) => {
            if (first.name < second.name) return -1;
            else return 1;
        });
        this.setState({ userList });
    }

    sortByRole() {
        console.log("Sorting by role");
        let userList = this.state.userList;
        userList.sort((first, second) => {
            if (first.role < second.role) return -1;
            else return 1;
        });
        this.setState({ userList });
    }

    render() {
        return (
            <>
                <TopNav />
                <Header
                    heading="Contact List Section"
                    tagline="Kanzuliman Research Publication"
                />
                <div
                    className="container"
                    style={{ marginTop: "100px", marginBottom: "400px" }}
                >
                    <div className="row ml-1 mb-4">
                        <button
                            className="btn btn-sm btn-dark"
                            onClick={this.sortByName}
                        >
                            Sort By Name
                        </button>
                        <div className="ml-2 mr-2" />
                        <button
                            className="btn btn-sm btn-dark"
                            onClick={this.sortByRole}
                        >
                            Sort By Role
                        </button>
                    </div>
                    <table className="table table-responsive-sm table-info">
                        <thead className="table-dark">
                            <tr>
                                <th>Name</th>
                                <th>Contact Details</th>
                                <th>Role</th>
                            </tr>
                        </thead>
                        {this.state.loading ? (
                            <div className="text-center">
                                <div
                                    class="spinner-border text-success"
                                    style={{
                                        width: "3rem",
                                        height: "3rem",
                                        marginTop: "10rem",
                                        marginLeft: "22rem",
                                    }}
                                    role="status"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        ) : (
                            <tbody>
                                {this.state.userList.map((user) => {
                                    return (
                                        <tr>
                                            <td>{user.name}</td>
                                            <td>
                                                <i>
                                                    <span>
                                                        {user.mobile}
                                                        &nbsp;&nbsp;
                                                    </span>
                                                    <span>
                                                        {user.mobile1}
                                                        &nbsp;&nbsp;
                                                    </span>
                                                    <span>
                                                        {user.mobile2}
                                                        &nbsp;&nbsp;
                                                    </span>
                                                    <span>
                                                        {user.email}&nbsp;&nbsp;
                                                    </span>
                                                    <span>
                                                        {user.alternateEmail}
                                                        &nbsp;&nbsp;
                                                    </span>
                                                    <span>
                                                        {user.email1}
                                                        &nbsp;&nbsp;
                                                    </span>
                                                    <span>
                                                        {user.email2}
                                                        &nbsp;&nbsp;
                                                    </span>
                                                </i>
                                            </td>
                                            <td>{user.role}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        )}
                    </table>
                </div>
            </>
        );
    }
}

export default ContactList;
