import React, { Component } from "react";
import TableRow from "./tableRow";

export class table extends Component {
    constructor(props) {
        super(props);
        this.state = {
            search: "",
        };
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-4 form-group container m-3">
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                id="basic-addon1"
                            >
                                <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                />
                            </span>
                            <input
                                className="form-control"
                                placeholder="Type to search..."
                                onChange={(event) =>
                                    this.setState({
                                        search: event.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Designation</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.data.map((record) => {
                            return (
                                <TableRow
                                    data={record}
                                    updateComponentData={
                                        this.props.updateComponentData
                                    }
                                />
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default table;
