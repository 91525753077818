import React from "react";
import Header from "../layout/header";
import TopNav from "../layout/topnav";
import DownloadLogo from "../../assets/download_logo.png";
import DownloadOldHeader from "../../assets/download_old_header.png";
import DownloadNewHeader from "../../assets/download_new_header.png";
import ReviewReport from "../../assets/reviewReport.docx";

class OfficialDownloads extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Official Downloads"
                    tagline="Kanzuliman Research Publications"
                />
                <div className="container mt-5">
                    <h4>Downloads</h4>
                    <li>
                        <a
                            href={ReviewReport}
                            style={{ textDecoration: "none" }}
                            target="blank"
                        >
                            Reviewers Report
                        </a>
                        <br />
                    </li>
                    <li>
                        <a
                            href={DownloadLogo}
                            style={{ textDecoration: "none" }}
                            target="blank"
                        >
                            Logo Download
                        </a>
                        <br />
                    </li>
                    <li>
                        <a
                            href={DownloadOldHeader}
                            style={{ textDecoration: "none" }}
                            target="blank"
                        >
                            Old Header
                        </a>
                        <br />
                    </li>
                    <li>
                        <a
                            href={DownloadNewHeader}
                            style={{ textDecoration: "none" }}
                            target="blank"
                        >
                            New Header
                        </a>
                        <br />
                    </li>
                    <br />
                    <br />
                    <span className="text-muted">
                        Copyright © secured by Kanzuliman Foundation
                    </span>
                </div>
            </div>
        );
    }
}

export default OfficialDownloads;
