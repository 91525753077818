import { Component } from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import { db } from "../../firebase";
import EventCard from "./EventCard";

class ViewEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            events: [],
            loading: false,
        };
    }

    async fetchEventsData() {
        try {
            const response = await db.collection("events").get();
            let events = [];
            response.forEach((record) => {
                let temp = {};
                temp = record.data();
                temp.id = record.id;
                events.push(temp);
            });
            events.sort((first, second) => {
                if (first.eventID > second.eventID) return -1;
                else return 1;
            });
            this.setState({ events });
        } catch (err) {
            console.log("Error fetching events data: ", err);
        }
    }
    async componentDidMount() {
        this.setState({ loading: true });
        await this.fetchEventsData();
        this.setState({ loading: false });
    }

    render() {
        return (
            <>
                <TopNav />
                <Header
                    heading="Events and Activities"
                    tagline="Kanzuliman Research Publication | KAIJOR International Academic Journal on Razawiyat"
                />
                <div
                    className="container"
                    style={{ marginTop: "100px", marginBottom: "400px" }}
                >
                    {this.state.loading && (
                        <div className="text-center">
                            <div
                                class="spinner-border text-success"
                                style={{
                                    width: "5rem",
                                    height: "5rem",
                                    marginTop: "10rem",
                                }}
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    )}
                    {this.state.events.map((event, index) => {
                        if (event.eventVisibility) {
                            return <EventCard event={event} key={index} />;
                        }
                        return <></>;
                    })}
                </div>
            </>
        );
    }
}
export default ViewEvent;
