import React from "react";
import Header from "../layout/header";
import TopNav from "../layout/topnav";
import axios from "axios";
import Number from "../formGroup/mobile";
import Files from "../formGroup/files";
import Field from "../formGroup/Field";
import countries from "../formGroup/utilities/countries";
import { ProgressBar } from "react-bootstrap";

import validators from "../join/utilities/validations";
import { Link } from "react-router-dom";

import { db, storage } from "../../firebase";
import { v4 } from "uuid";

import Utils from "./utilities";
import { appConfig } from "../../config";

export class onlinePaperSubmission extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            middleName: "",
            lastName: "",
            country: "",
            city: "",
            State: "",
            address_1: "",
            address_2: "",
            email: "",
            number: "",
            code: "+91",
            affiliation: "",
            profession: "",
            areaOfStudy: "",
            title: "",
            language: "",
            type: "",
            abstract: "",
            keywords: "",
            refrences: "",
            conclusion: "",
            pdfFile: "",
            wordFile: "",
            reviewerForm: "",
            copyrightForm: "",
            agreement: true,
            errorfirstName: true,
            errorlastName: true,
            errorcountry: true,
            errorcity: true,
            errorstate: true,
            erroraddress1: true,
            erroremail: true,
            errormobile: true,
            erroraffiliation: true,
            errorprofession: true,
            errorareaOfStudy: true,
            errortitle: true,
            errorlanguage: true,
            errortype: true,
            errorabstract: true,
            errorkeywords: true,
            errorrefrences: true,
            errorconclusion: true,
            validate: false,
            isNull: false,
            invalidEmail: false,
            invalidMobile: false,
            disable: false,
            showError: false,
            showSpinner: false,
            progressWordFile: 0,
            progressPdfFile: 0,
            progressCopyrightForm: 0,
            progressReviewerForm: 0,
            DOI: "[]()",
            paperID: "",
            releasedWith: "",
        };
    }

    successPage = () => {
        if (
            this.state.progressWordFile === 1 &&
            (!this.state.pdfFile || this.state.progressPdfFile === 1) &&
            (!this.state.copyrightForm ||
                this.state.progressCopyrightForm === 1) &&
            (!this.state.reviewerForm || this.state.progressReviewerForm === 1)
        )
            setTimeout(() => {
                this.props.history.push("/join/success");
            }, 100);
    };

    clickHandler = async (event) => {
        event.preventDefault();
        this.setState({
            validate: true,
            showError: false,
            showPdfFileError: false,
            showWordFileError: false,
            showReviewerFormError: false,
            showCopyrightFormError: false,
        });

        if (this.state.pdfFile !== "" && this.state.pdfFile !== undefined) {
            let invalidPdfFile = Utils.invalidFile(this.state.pdfFile, [
                ".pdf",
            ]);
            if (invalidPdfFile) {
                this.setState({ showPdfFileError: true });
            }
            if (invalidPdfFile || !this.errorHandler()) {
                return;
            }
        }
        if (
            this.state.copyrightForm !== "" &&
            this.state.copyrightForm !== undefined
        ) {
            let invalidCopyrightForm = Utils.invalidFile(
                this.state.copyrightForm,
                [".docx", ".pdf", ".doc"]
            );
            if (invalidCopyrightForm) {
                this.setState({ showCopyrightFormError: true });
            }
            if (invalidCopyrightForm || !this.errorHandler()) {
                return;
            }
        }
        if (
            this.state.reviewerForm !== "" &&
            this.state.reviewerForm !== undefined
        ) {
            let invalidReviewerForm = Utils.invalidFile(
                this.state.reviewerForm,
                [".docx", ".pdf", ".doc"]
            );
            if (invalidReviewerForm) {
                this.setState({ showReviewerFormError: true });
            }
            if (invalidReviewerForm || !this.errorHandler()) {
                return;
            }
        }

        let invalidWordFile = Utils.invalidFile(this.state.wordFile, [
            ".docx",
            ".doc",
        ]);
        if (invalidWordFile) {
            this.setState({ showWordFileError: true });
        }
        if (invalidWordFile || !this.errorHandler()) {
            return;
        }
        await this.setState({ disable: true, showSpinner: true });

        try {
            const timestamp = Date.now();
            const requestSent = {
                name:
                    this.state.firstName.trim() +
                    " " +
                    this.state.middleName.trim() +
                    " " +
                    this.state.lastName.trim(),
                address:
                    validators.removeSpaces(this.state.address_1) +
                    " " +
                    validators.removeSpaces(this.state.address_2),
                city: validators.removeSpaces(this.state.city),
                state: validators.removeSpaces(this.state.State),
                country: validators.removeSpaces(this.state.country),
                email: validators.email(this.state.email),
                email1: "",
                email2: "",
                mobile: `${this.state.code} ${this.state.number}`,
                mobile1: "",
                mobile2: "",
                profession: validators.removeSpaces(this.state.profession),
                institute: validators.removeSpaces(this.state.affiliation),
                areaOfStudy: validators.removeSpaces(this.state.areaOfStudy),
                title: validators.removeSpaces(this.state.title),
                language: validators.removeSpaces(this.state.language),
                type: validators.removeSpaces(this.state.type),
                abstract: validators.removeSpaces(this.state.abstract),
                keywords: validators.removeSpaces(this.state.keywords),
                refrences: validators.removeSpaces(this.state.refrences),
                conclusion: validators.removeSpaces(this.state.conclusion),
                pdfFile:
                    this.state.pdfFile !== ""
                        ? v4() + Utils.getFileExtension(this.state.pdfFile.name)
                        : "",
                wordFile:
                    v4() + Utils.getFileExtension(this.state.wordFile.name),
                reviewerForm:
                    this.state.reviewerForm !== ""
                        ? v4() +
                          Utils.getFileExtension(this.state.reviewerForm.name)
                        : "",
                copyrightForm:
                    this.state.copyrightForm !== ""
                        ? v4() +
                          Utils.getFileExtension(this.state.copyrightForm.name)
                        : "",
                timestamp: timestamp,
                DOI: this.state.DOI,
                paperID: this.state.paperID,
                releasedWith: this.state.releasedWith,
                status: "NOT_VERIFIED",
                downloadLink: "disable",
                digiproveURL: "",
                pages: "",
            };

            await db.collection("Archives").add(requestSent);
            let wordFile = new File(
                [this.state.wordFile],
                requestSent.wordFile
            );

            await storage
                .ref()
                .child(`archives/wordFile/${wordFile.name}`)
                .put(wordFile, {
                    contentType: Utils.getFileContentType(
                        Utils.getFileExtension(wordFile.name)
                    ),
                })
                .on(
                    "state_changed",
                    (snapshot) =>
                        this.setState({
                            progressWordFile:
                                snapshot.bytesTransferred / snapshot.totalBytes,
                        }),
                    (error) => this.setState({ showWordFileError: true }),
                    () => this.successPage()
                );

            if (this.state.pdfFile !== "" && this.state.pdfFile !== undefined) {
                let pdfFile = new File(
                    [this.state.pdfFile],
                    requestSent.pdfFile
                );
                await storage
                    .ref()
                    .child(`archives/PdfFile/${pdfFile.name}`)
                    .put(pdfFile, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(pdfFile.name)
                        ),
                    })
                    .on(
                        "state_changed",
                        (snapshot) =>
                            this.setState({
                                progressPdfFile:
                                    snapshot.bytesTransferred /
                                    snapshot.totalBytes,
                            }),
                        (error) => this.setState({ showPdfFileError: true }),
                        () => this.successPage()
                    );
            }
            if (
                this.state.copyrightForm !== "" &&
                this.state.copyrightForm !== undefined
            ) {
                let copyrightForm = new File(
                    [this.state.copyrightForm],
                    requestSent.copyrightForm
                );
                await storage
                    .ref()
                    .child(`archives/copyrightForm/${copyrightForm.name}`)
                    .put(copyrightForm, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(copyrightForm.name)
                        ),
                    })
                    .on(
                        "state_changed",
                        (snapshot) =>
                            this.setState({
                                progressCopyrightForm:
                                    snapshot.bytesTransferred /
                                    snapshot.totalBytes,
                            }),
                        (error) =>
                            this.setState({ showCopyrightFormError: true }),
                        () => this.successPage()
                    );
            }
            if (
                this.state.reviewerForm !== "" &&
                this.state.reviewerForm !== undefined
            ) {
                let reviewerForm = new File(
                    [this.state.reviewerForm],
                    requestSent.reviewerForm
                );
                await storage
                    .ref()
                    .child(`archives/reviewerForm/${reviewerForm.name}`)
                    .put(reviewerForm, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(reviewerForm.name)
                        ),
                    })
                    .on(
                        "state_changed",
                        (snapshot) =>
                            this.setState({
                                progressReviewerForm:
                                    snapshot.bytesTransferred /
                                    snapshot.totalBytes,
                            }),
                        (error) =>
                            this.setState({ showReviewerFormError: true }),
                        () => this.successPage()
                    );
            }
            try {
                const mailOptions = {
                    subject: `${requestSent.name}: submitted new ${requestSent.type}`,
                    text: `Hey Admin, greeting from Developers Kanzuliman, ${requestSent.name} has submiited a new ${requestSent.type} entitled ${requestSent.title}, kindly review the issue and provide suitable response.
Language: ${requestSent.language}
Author email: ${requestSent.email}
Author contact: ${requestSent.mobile}



Regards,
Developers Kanzuliman
                    `,
                    html: "",
                };
                /// utilising email notification only in production mode
                if (
                    window.location.hostname !== "localhost" &&
                    window.location.hostname !== "127.0.0.1"
                ) {
                    await axios.post(
                        `${appConfig.emailEndpoint}sendEmail`,
                        mailOptions
                    );
                }
            } catch (err) {
                console.log("MailError: Unabel to send email", err);
            }
            this.successPage();
        } catch (err) {
            await this.setState({
                disable: false,
                showError: true,
                showSpinner: false,
            });
        }
    };

    errorHandler = () => {
        if (
            this.state.errorlastName ||
            this.state.errorfirstName ||
            this.state.errorcountry ||
            this.state.errorcity ||
            this.state.errorstate ||
            this.state.erroraddress1 ||
            this.state.erroremail ||
            this.state.errormobile ||
            this.state.erroraffiliation ||
            this.state.errorprofession ||
            this.state.errorareaOfStudy ||
            this.state.errortitle ||
            this.state.errorlanguage ||
            this.state.errortype ||
            this.state.errorabstract ||
            this.state.errorkeywords ||
            this.state.errorrefrences ||
            this.state.errorconclusion
        ) {
            return false;
        }
        return true;
    };

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Online Paper Submission"
                    tagline="Kanzuliman Research Publication"
                />
                <div className="container main">
                    <div className="border-bottom text-muted">
                        Kindly Check author’s
                        <Link class="ml-1 mr-1" to="/authors/guidelines">
                            guidelines
                        </Link>
                        and prepare the details as per this format before Online
                        Paper Submission
                    </div>
                    {this.state.showError && (
                        <div className="alert alert-danger" role="alert">
                            Some error has occured, check your network
                            connection!
                        </div>
                    )}

                    {this.state.showPdfFileError && (
                        <div className="alert alert-danger" role="alert">
                            Please upload a valid PDF file of Manuscript!
                        </div>
                    )}

                    {this.state.showWordFileError && (
                        <div className="alert alert-danger" role="alert">
                            Please upload a valid Word file of Manuscript!
                        </div>
                    )}

                    {this.state.showReviewerFormError && (
                        <div className="alert alert-danger" role="alert">
                            Please upload a valid Reviewer Form!
                        </div>
                    )}

                    {this.state.showCopyrightFormError && (
                        <div className="alert alert-danger" role="alert">
                            Please upload a valid Copyright Form!
                        </div>
                    )}

                    <div className="row mt-4">
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(firstName) => this.setState({ firstName })}
                            onChange={(errorfirstName) =>
                                this.setState({ errorfirstName })
                            }
                            label="First Name *"
                            validations={[
                                ["required", "Please provide your first name"],
                                ["min", 2],
                                ["max", 20],
                            ]}
                            type="text"
                            placeholder="Type in your first name"
                        />
                        <Field
                            cols="4"
                            validate={false}
                            onData={(middleName) =>
                                this.setState({ middleName })
                            }
                            onChange={(x) => x}
                            label="Middle Name"
                            validations={[]}
                            type="text"
                            placeholder="Type in your middle name"
                        />
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(lastName) => this.setState({ lastName })}
                            onChange={(errorlastName) =>
                                this.setState({ errorlastName })
                            }
                            label="Last Name *"
                            validations={[
                                ["required"],
                                ["min", 2],
                                ["max", 20],
                            ]}
                            type="text"
                            placeholder="Type in your last name"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(email) => this.setState({ email })}
                            onChange={(erroremail) =>
                                this.setState({ erroremail })
                            }
                            label="Email Address *"
                            validations={[["required"], ["email"]]}
                            type="email"
                            placeholder="Preferred institutional email"
                        />
                        <Number
                            onNumber={(number) => this.setState({ number })}
                            onCode={(code) => this.setState({ code })}
                            dataNumber={this.state.number}
                            dataCode={this.state.code}
                            cols="6"
                            validate={this.state.validate}
                            emitError={(errormobile) =>
                                this.setState({ errormobile })
                            }
                            placeholder="Type in your mobile number"
                        />
                        <Field
                            cols="12"
                            validate={this.state.validate}
                            onData={(address_1) => this.setState({ address_1 })}
                            onChange={(erroraddress1) =>
                                this.setState({ erroraddress1 })
                            }
                            label="Address *"
                            validations={[["required"]]}
                            placeholder="Address Line 1"
                            type="text"
                        />
                        <Field
                            cols="12"
                            validate={false}
                            onData={(address_2) => this.setState({ address_2 })}
                            onChange={(erroraddress) =>
                                this.setState({ erroraddress })
                            }
                            label=""
                            validations={[]}
                            placeholder="Address Line 2"
                            type="text"
                        />
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(city) => this.setState({ city })}
                            onChange={(errorcity) =>
                                this.setState({ errorcity })
                            }
                            label="City*"
                            validations={[["required"]]}
                            type="text"
                            placeholder="Type in your city name"
                        />
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(State) => this.setState({ State })}
                            onChange={(errorstate) =>
                                this.setState({ errorstate })
                            }
                            label="State *"
                            validations={[["required"]]}
                            type="text"
                            placeholder="Type in your State name"
                        />
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(country) => this.setState({ country })}
                            onChange={(errorcountry) =>
                                this.setState({ errorcountry })
                            }
                            label="Country *"
                            validations={[
                                ["required", "Please select an option"],
                            ]}
                            type="select"
                            options={countries}
                            value={this.state.country}
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(affiliation) =>
                                this.setState({ affiliation })
                            }
                            onChange={(erroraffiliation) =>
                                this.setState({ erroraffiliation })
                            }
                            label="Affiliation (University/College/Company) *"
                            validations={[
                                ["required", "Please enter your affiliation"],
                            ]}
                            type="text"
                            placeholder="Type in the name of affiliating institue"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(profession) =>
                                this.setState({ profession })
                            }
                            onChange={(errorprofession) =>
                                this.setState({ errorprofession })
                            }
                            label="Profession *"
                            validations={[["required"]]}
                            type="text"
                            placeholder="Type in your professional status"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(areaOfStudy) =>
                                this.setState({ areaOfStudy })
                            }
                            onChange={(errorareaOfStudy) =>
                                this.setState({ errorareaOfStudy })
                            }
                            label="Area of Study *"
                            validations={[["required"]]}
                            type="text"
                            placeholder="Your area of research"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(type) => this.setState({ type })}
                            onChange={(errortype) =>
                                this.setState({ errortype })
                            }
                            label="Manuscript Type *"
                            validations={[
                                ["required", "Please provide Manuscript Type"],
                            ]}
                            type="select"
                            options={[
                                "Research Book",
                                "Case Study",
                                "Review",
                                "Research Thesis",
                                "Research Paper",
                                "Special Issue",
                                "Other",
                            ]}
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(title) => this.setState({ title })}
                            onChange={(errortitle) =>
                                this.setState({ errortitle })
                            }
                            label="Manuscript Title *"
                            validations={[
                                ["required", "Please enter Manuscript Title"],
                            ]}
                            type="text"
                            placeholder="Type the title of your Manuscript"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(language) => this.setState({ language })}
                            onChange={(errorlanguage) =>
                                this.setState({ errorlanguage })
                            }
                            label="Manuscript Language *"
                            validations={[
                                [
                                    "required",
                                    "Please select Manuscript Language",
                                ],
                            ]}
                            type="select"
                            options={[
                                "English",
                                "Persian",
                                "Hindi",
                                "Urdu",
                                "Arabic",
                            ]}
                        />
                        <Field
                            cols="12"
                            validate={this.state.validate}
                            onData={(abstract) => this.setState({ abstract })}
                            onChange={(errorabstract) =>
                                this.setState({ errorabstract })
                            }
                            label="Manuscript Abstract *"
                            validations={[
                                [
                                    "required",
                                    "Please enter Manuscript Abstract",
                                ],
                            ]}
                            type="textarea"
                            placeholder="Type your Manuscript Abstract here"
                        />
                        <Field
                            cols="12"
                            validate={this.state.validate}
                            onData={(keywords) => this.setState({ keywords })}
                            onChange={(errorkeywords) =>
                                this.setState({ errorkeywords })
                            }
                            label="Keywords *"
                            validations={[
                                ["required", "Please enter some keywords"],
                            ]}
                            type="textarea"
                            placeholder="Keywords related to your manuscript"
                        />
                        <Field
                            cols="12"
                            validate={this.state.validate}
                            onData={(conclusion) =>
                                this.setState({ conclusion })
                            }
                            onChange={(errorconclusion) =>
                                this.setState({ errorconclusion })
                            }
                            label="Conclusion *"
                            validations={[
                                ["required", "Please enter the conclusion"],
                            ]}
                            type="textarea"
                            placeholder="Type conclusion of your manuscript here"
                        />
                        <Field
                            cols="12"
                            validate={this.state.validate}
                            onData={(refrences) => this.setState({ refrences })}
                            onChange={(errorrefrences) =>
                                this.setState({ errorrefrences })
                            }
                            label="References *"
                            validations={[
                                ["required", "Please provide some refrences"],
                            ]}
                            type="textarea"
                            placeholder="Type reference of your manuscript"
                        />
                        <div className="col-12 row">
                            <div className="col-8">
                                <Files
                                    label="PDF file of Manuscript *"
                                    onData={(pdfFile) =>
                                        this.setState({ pdfFile })
                                    }
                                />
                            </div>
                            {this.state.progressPdfFile ? (
                                <div className="col-4 p-3">
                                    <ProgressBar
                                        animated
                                        now={this.state.progressPdfFile * 100}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col-12 row">
                            <div className="col-8">
                                <Files
                                    label="Word file of Manuscript *"
                                    onData={(wordFile) =>
                                        this.setState({ wordFile })
                                    }
                                />
                            </div>
                            {this.state.progressWordFile ? (
                                <div className="col-4 p-3">
                                    <ProgressBar
                                        animated
                                        now={this.state.progressWordFile * 100}
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col-12 row">
                            <div className="col-8">
                                <Files
                                    label="Reviewer Form *"
                                    onData={(reviewerForm) =>
                                        this.setState({ reviewerForm })
                                    }
                                />
                            </div>
                            {this.state.progressReviewerForm ? (
                                <div className="col-4 p-3">
                                    <ProgressBar
                                        animated
                                        now={
                                            this.state.progressReviewerForm *
                                            100
                                        }
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col-12 row">
                            <div className="col-8">
                                <Files
                                    label="Copyright Form *"
                                    onData={(copyrightForm) =>
                                        this.setState({ copyrightForm })
                                    }
                                />
                            </div>
                            {this.state.progressCopyrightForm ? (
                                <div className="col-4 p-3">
                                    <ProgressBar
                                        animated
                                        now={
                                            this.state.progressCopyrightForm *
                                            100
                                        }
                                    />
                                </div>
                            ) : (
                                <></>
                            )}
                        </div>
                        <div className="col-12 mt-4 ml-1 form-group">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value={this.state.agreement}
                                    onChange={() => {
                                        this.setState({
                                            agreement: !this.state.agreement,
                                        });
                                    }}
                                />
                                <label className="form-check-label">
                                    I hereby declare that this research paper is
                                    owned by me and i am giving it's all
                                    copyrights to Kanzuliman Foundation for
                                    publication in KAIJOR.
                                </label>
                            </div>
                        </div>
                        <div className="col-12 form-group">
                            <button
                                onClick={this.clickHandler}
                                type="submit"
                                className="btn btn-dark"
                                disabled={
                                    this.state.disable || this.state.agreement
                                }
                            >
                                {!this.state.showSpinner && <span>Submit</span>}
                                {this.state.showSpinner && (
                                    <i className="fa fa-spinner fa-spin fa-1x" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default onlinePaperSubmission;
