import React from "react";
import "./president.css";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import start from "../../assets/start.png";
import logo from "../../assets/circlelogo.png";

class President extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="President's Message"
                    tagline="Kanzuliman Research Publication"
                />
                <div className="container mt-5 w3-animate-zoom">
                    <img
                        src={start}
                        width="100%"
                        className="mb-5"
                        alt="bismillah"
                    />
                    <p className="w3-animate-opacity">
                        Dear Members, Volunteers, Friends and Colleagues,
                        <br />
                        <br />
                        <br />
                        Together with my team at Kanzuliman Foundation, I wish
                        you health and prosperity! <br />
                        <br />
                        I am humbled, honoured and priviliged to proffer you
                        Kanzuliman Foundation and its associated divisions i am
                        deeply grateful to my spritual master and guide, the
                        leader of the learned Huzoor Sadrululama Allama Theseen
                        Raza Khan Muhaddith Barelvi Alahir'rahama for endowing
                        his guidance and partonage on Kanzuliman Islamic Library
                        where this initiative was born. I am inspired by his
                        commitment towards knowledge and his 50 years of
                        unmatched selfless services as a teacher that produced
                        thousands of scholars and one of the finest minds. I am
                        equally thankful to my parents for their unimaginable
                        support and well wishers for their hands that kept my
                        back strong.
                        <br />
                        <br />
                        <br />
                        In 2006, when i laid the foundation of Kanzuliman
                        Foundation in a 15 X 30 Sq. ft. library i had a vision
                        of turning this library in a movement on the 10 point
                        plan of Aala Hazrat Alahir'rahama, Alhamdulil'lah the
                        growth story continues and we are now witnessing that
                        dream turning into a shining reality. I am grateful to
                        everyone who supported this initiative in the beginning
                        and remained with me shoulder to shoulder during the
                        inception. I remember my dearest friend, brother and
                        founding member Late Yazdani for his fraternal hand and
                        everything he did to setup the foundations of this
                        initiative, may Allah bless his noble soul and may he
                        rest in peace forever indeed, everyone who supported and
                        is currently supporting had a share in the growth and
                        success of this initiative and surely they will ge their
                        reward from the One who is best rewarder. With this i
                        look forward to continuing this important work of
                        leading this initiative with all my dedication and true
                        sprit
                        <br />
                        In this endeavour, I am joined by my borad on the
                        Executive Commitee as well as the 2019-2020 division
                        head and volunteers for Kanzuliman Library, Kanzuliman
                        Research and Kanzuliman Non Profit (Project CARE)
                        including Library branch heads who represent Kanzuliman
                        Foundation in various cities.
                        <br />
                        <br />
                        <br />
                        This year as we celebrate our 14th foundation year since
                        we started our very first library (2006-2020)
                        Alhamdulil'lah we have a long list of achievements As a
                        non-profit, Kanzuliman Foundation has expanded into 3
                        major division in the recent years
                    </p>
                    <ul className="w3-animate-opacity">
                        <li>Kanzuliman Library</li>
                        <li>Kanzuliman Research</li>
                        <li>Kanzuliman Foundation NGO (Project CARE)</li>
                    </ul>
                    <p className="w3-animate-opacity">
                        <strong>Kanzuliman Library</strong> A chain of libraries
                        operarted under the name Kanzuliman is an initiative to
                        impart education through setting up of small libraries
                        accross the country and help destitute have access to
                        free literature, books and foundational coaching. At
                        present we have 5+ non profit small libraries operating
                        in different cities which are maintained and operated by
                        our volunteers and members at respective locations.
                        <br />
                        <br />
                        <strong>Kanzuliman Research</strong> To preserve and
                        garner academic research activities and ensure the
                        creation of an arena that supplements the knowledge
                        world with a high grade quality of publication of peer
                        reviwed and refereed international journal from diverse
                        field, Kanzuliman Foundation has established a platform
                        for keen researchers, learned academicians,
                        professionals, practitioners and students to generate
                        and create knowledge in the form of well articulated
                        quality wise empirical and theoretical research papers,
                        case studies, literature reviews and book reviews/
                        <br />
                        <br />
                        <strong>
                            Kanzuliman Foundation NGO (Project CARE)
                        </strong>{" "}
                        Project CARE is a non-profit initiative to help
                        destitute have access to quality education, healthcare
                        services and nutritous food in India. With a population
                        of nearly 1.3 billion there is an immediate and ongoing
                        need of initiative like Project CARE. We started this
                        initiative in 2020 and our decicated team of volunteers
                        is helping spread our word through three of our major
                        non-profit campaings - Education, Healthcare and Food
                        Program.
                        <br />
                        <br />
                        During the next 5 years Kanzuliman Foundation plans to
                        start ab educational facility in a form of school where
                        children will have access to much needed quality
                        education In Sha Allah, till then we will continue to
                        expand in all areas where ever our efforts are needed in
                        the national intrest towards the Nation building
                        activities for a learned, healthy and morally strong
                        society. Therefore, i conclude by extending this
                        initiative on behalf of Kanzuliman Foundation and invite
                        all the readers who would be reading this note to come
                        and shake hands with us, join us and help us build a
                        dream that will transform the lives of our future
                        generations In Sha Allah!
                        <br />
                        <br />
                        Your sincerly,
                        <br />
                        Amir Hussain Thesini
                        <br />
                        <br />
                        Founder and President
                        <br />
                        Kanzuliman Foundation
                    </p>
                    <div className="text-center mt-5 w3-animate-opacity">
                        <img src={logo} alt="kanzuliman foundation logo" />
                    </div>
                    <div className="text-center mt-3">
                        <h4>
                            Kanzuliman Foundation
                            <br />
                            Library | Research | Non-Profit
                        </h4>
                    </div>
                </div>
            </div>
        );
    }
}

export default President;
