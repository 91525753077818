import React from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import axios from "axios";
import { appConfig } from "../../config";

import Number from "../formGroup/mobile";
import Field from "../formGroup/Field";
import countries from "../formGroup/utilities/countries";
import { db } from "../../firebase";

import validators from "./utilities/validations";

export class sponsorship extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            number: "",
            code: "+91",
            organization: "",
            magazine: "",
            budget: "",
            displayName: "",
            address: "",
            city: "",
            country: "",
            email: "",
            PINCode: "",
            state: "",
            errorstate: true,
            errorfirstName: true,
            errorlastName: true,
            errornumber: true,
            errorcode: true,
            errororganization: true,
            errormagazine: true,
            errorbudget: true,
            errordisplayName: true,
            erroraddress: true,
            errorcity: true,
            errorcountry: true,
            erroremail: true,
            errorPINCode: true,
            invalidEmail: false,
            invalidMobile: false,
            isNull: false,
            validate: false,
            disable: false,
            showError: false,
            showSpinner: false,
        };
    }

    clickHandler = async (event) => {
        event.preventDefault();

        await this.setState({ validate: true, showError: false });
        if (!this.errorHandler()) return;
        await this.setState({ disable: true, showSpinner: true });

        try {
            const requestSent = {
                name: validators.name(
                    this.state.firstName,
                    this.state.lastName
                ),
                address: validators.removeSpaces(this.state.address),
                city: validators.removeSpaces(this.state.city),
                country: validators.removeSpaces(this.state.country),
                email: validators.email(this.state.email),
                mobile: `${this.state.code} ${this.state.number}`,
                organisation: validators.removeSpaces(this.state.organization),
                PINCode: validators.removeSpaces(this.state.PINCode),
                budget: validators.removeSpaces(this.state.budget),
                sponsorshipChoice: validators.removeSpaces(this.state.magazine),
                displayName: validators.removeSpaces(this.state.displayName),
            };

            await db.collection("sponsors").add(requestSent);

            /// utilising email notification only in production mode
            if (
                window.location.hostname !== "localhost" &&
                window.location.hostname !== "127.0.0.1"
            ) {
                try {
                    const mailOptions = {
                        subject: `New sponsorship Application from ${requestSent.name}`,
                        text: `Hey Admin, greeting from Developers Kanzuliman, ${requestSent.name} has submiited a new Sponsorship Application, kindly review the application and provide suitable response.
Name: ${requestSent.name}
Email: ${requestSent.email}
Address: ${requestSent.address}, ${requestSent.city}, ${requestSent.state}
Contact: ${requestSent.mobile}



Regards,
Developers Kanzuliman
                `,
                        html: "",
                    };
                    await axios.post(
                        `${appConfig.emailEndpoint}sendEmail`,
                        mailOptions
                    );
                } catch (err) {
                    console.log("MailError: Unabel to send email", err);
                }
            }

            this.props.history.push("/join/success");
        } catch (err) {
            console.log("Error: Unable to send avisory application: ", err);

            await this.setState({
                disable: false,
                showError: true,
                showSpinner: false,
            });
        }
    };

    errorHandler = () => {
        if (this.state.errorfirstName) return false;
        if (this.state.errorlastName) return false;
        if (this.state.errormobile) return false;
        if (this.state.errormagazine) return false;
        if (this.state.errorbudget) return false;
        if (this.state.errordisplayName) return false;
        if (this.state.erroraddress) return false;
        if (this.state.errorcity) return false;
        if (this.state.errorcountry) return false;
        if (this.state.erroremail) return false;
        if (this.state.errorpostal) return false;

        return true;
    };

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Sponsorship Form"
                    tagline="Kanzuliman Reasearch Publicaton"
                />
                <form className="mt-5 container">
                    <span className="mt-5">
                        We really appreciate your step towards sponsorship of
                        Kanzuliman Foudation. Thanks for your kind sponsorship.
                    </span>
                    {this.state.showError && (
                        <div className="alert alert-danger" role="alert">
                            Some error has occured, please check your network
                            connection and try again!
                        </div>
                    )}

                    <div className="row mt-4">
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(firstName) => this.setState({ firstName })}
                            onChange={(errorfirstName) =>
                                this.setState({ errorfirstName })
                            }
                            label="First Name *"
                            validations={[
                                ["required", "Please provide your first name"],
                                ["min", 2],
                                ["max", 20],
                            ]}
                            type="text"
                            placeholder="Type your first name"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(lastName) => this.setState({ lastName })}
                            onChange={(errorlastName) =>
                                this.setState({ errorlastName })
                            }
                            label="Last Name *"
                            validations={[
                                ["required"],
                                ["min", 2],
                                ["max", 20],
                            ]}
                            type="text"
                            placeholder="Type your last name"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(email) => this.setState({ email })}
                            onChange={(erroremail) =>
                                this.setState({ erroremail })
                            }
                            label="Email Address *"
                            validations={[["required"], ["email"]]}
                            type="email"
                            placeholder="Type your email address"
                        />
                        <Number
                            onNumber={(number) => this.setState({ number })}
                            onCode={(code) => this.setState({ code })}
                            cols="6"
                            validate={this.state.validate}
                            emitError={(errormobile) =>
                                this.setState({ errormobile })
                            }
                            placeholder="Type your mobile number"
                        />
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(magazine) => this.setState({ magazine })}
                            onChange={(errormagazine) =>
                                this.setState({ errormagazine })
                            }
                            label="Magazine to be Sponsored *"
                            validations={[
                                ["required", "Please select a magazine"],
                            ]}
                            type="select"
                            options={[
                                "Kanzuliman Research Publications",
                                "KAIJOR",
                                "Kanzuliman Publications",
                            ]}
                        />
                        <Field
                            cols="8"
                            validate={this.state.validate}
                            onData={(address) => this.setState({ address })}
                            onChange={(erroraddress) =>
                                this.setState({ erroraddress })
                            }
                            label="Address *"
                            validations={[["required"]]}
                            type="text"
                            placeholder="Type in your street address"
                        />
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(PINCode) => this.setState({ PINCode })}
                            onChange={(errorPINCode) =>
                                this.setState({ errorPINCode })
                            }
                            label="PIN Code *"
                            validations={[["required"]]}
                            type="text"
                            placeholder="Type your PIN code"
                        />
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(city) => this.setState({ city })}
                            onChange={(errorcity) =>
                                this.setState({ errorcity })
                            }
                            label="City"
                            validations={[["required"]]}
                            type="text"
                            placeholder="Type in your city"
                        />
                        <Field
                            cols="4"
                            validate={this.state.validate}
                            onData={(state) => this.setState({ state })}
                            onChange={(errorstate) =>
                                this.setState({ errorstate })
                            }
                            label="State"
                            validations={[["required"]]}
                            type="text"
                            placeholder="Type in your State"
                        />
                        <Field
                            cols="5"
                            validate={this.state.validate}
                            onData={(country) => this.setState({ country })}
                            onChange={(errorcountry) =>
                                this.setState({ errorcountry })
                            }
                            label="Country *"
                            validations={[
                                ["required", "Please select an option"],
                            ]}
                            type="select"
                            options={countries}
                            value={this.state.country}
                        />
                        <Field
                            cols="5"
                            validate={this.state.validate}
                            onChange={(errororganization) =>
                                this.setState({ errororganization })
                            }
                            onData={(organization) =>
                                this.setState({ organization })
                            }
                            label="Organization/Company (if applicable)"
                            validations={[]}
                            type="text"
                            placeholder="Name of any recognized organisation you belongs to"
                        />
                        <Field
                            cols="5"
                            validate={this.state.validate}
                            onData={(displayName) =>
                                this.setState({ displayName })
                            }
                            onChange={(errordisplayName) =>
                                this.setState({ errordisplayName })
                            }
                            label="Name to be displayed *"
                            validations={[
                                ["required", "Please provide this field"],
                                ["min", 2],
                                ["max", 20],
                            ]}
                            type="text"
                            placeholder="Name to be displayed on Magazine Cover"
                        />
                        <Field
                            cols="2"
                            validate={this.state.validate}
                            onData={(budget) => this.setState({ budget })}
                            onChange={(errorbudget) =>
                                this.setState({ errorbudget })
                            }
                            label="Budget (in rupees) *"
                            validations={[
                                [
                                    "required",
                                    "Please enter your expected budget",
                                ],
                            ]}
                            type="number"
                            placeholder="Your SponsorShip budget in INR"
                        />
                        <div className="col-12 form-group">
                            <button
                                onClick={this.clickHandler}
                                className="btn btn-dark"
                                disabled={this.state.disable}
                            >
                                {!this.state.showSpinner && (
                                    <span>Sponsor</span>
                                )}
                                {this.state.showSpinner && (
                                    <i className="fa fa-spinner fa-spin fa-1x" />
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default sponsorship;
