import React from "react";
import TopNav from "../layout/navbar";
import Header from "../../layout/header";
import { db } from "../../../firebase";
import { Form } from "react-bootstrap";
import { Button, Modal } from "react-bootstrap";

export class ManageJournalRelease extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            array: [],
        };

        this.fetchJournalData = this.fetchJournalData.bind(this);
    }

    async fetchJournalData() {
        try {
            const response = await db.collection("JournalRelease").get();
            let array = [];
            response.forEach((doc) => {
                let temp = {};
                temp = doc.data();
                temp.id = doc.id;
                array.push(temp);
            });
            this.setState({ array });
        } catch (err) {
            console.log("Error fetching journal data: ", err);
        }
    }

    async componentDidMount() {
        await this.fetchJournalData();
    }

    async componentDidUpdate() {
        this.fetchJournalData();
    }

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Manage Archives Releases"
                    tagline="Kanzuliman Research Publication | KAIJOR International Academic Journal on Razawiyat"
                />
                <div className="container mt-5">
                    <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Release Name</th>
                                <th scope="col">Date</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.array.map((ele, index) => {
                                return (
                                    <BuildDataRow
                                        data={ele}
                                        key={index}
                                        updateComponentData={
                                            this.fetchJournalData
                                        }
                                    />
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

class BuildDataRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showEditModal: false,
        };
    }

    render() {
        return (
            <>
                <tr>
                    <td>{this.props.data.releaseName}</td>
                    <td>{this.props.data.releaseDate}</td>
                    <td>
                        <Form className="row">
                            <span
                                style={{ color: "Black" }}
                                className="col-1"
                                title="Edit"
                                onClick={() => {
                                    this.setState({
                                        showEditModal: true,
                                    });
                                }}
                            >
                                <div
                                    title="Click to edit record"
                                    className="tooltip-test fa fa-pencil"
                                    aria-hidden="true"
                                />
                            </span>
                            <span
                                style={{ color: "Red" }}
                                className="col-1"
                                onClick={async () => {
                                    try {
                                        await db
                                            .collection("JournalRelease")
                                            .doc(this.props.data.id)
                                            .delete();
                                    } catch (err) {
                                        console.log(
                                            "Unable to delte release:",
                                            err
                                        );
                                    }
                                }}
                            >
                                <i
                                    className="tooltip-test fa fa-trash"
                                    title="Click to Delete"
                                    aria-hidden="true"
                                />
                            </span>
                        </Form>
                    </td>
                </tr>
                {this.state.showEditModal && (
                    <EditModal
                        show={this.state.showEditModal}
                        hide={() => this.setState({ showEditModal: false })}
                        data={this.props.data}
                        updateComponentData={this.props.updateComponentData}
                    />
                )}
            </>
        );
    }
}

class EditModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            releaseName: this.props.data.releaseName,
            releaseDate: this.props.data.releaseDate,
            releaseVisibility: this.props.data.releaseVisibility,
            loading: false,
            error: "",
        };
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
    }

    async handleEditSubmit() {
        this.setState({ loading: true, error: "" });
        if (
            this.state.releaseName.length === 0 ||
            this.state.releaseDate.length === 0
        ) {
            this.setState({
                error: "Error: cannot create blank journal release",
            });
            return;
        } else {
            this.setState({ error: "" });
            try {
                await db
                    .collection("JournalRelease")
                    .doc(this.props.data.id)
                    .set({
                        releaseName: this.state.releaseName,
                        releaseDate: this.state.releaseDate,
                        releaseVisibility: this.state.releaseVisibility,
                    });
            } catch (err) {
                console.warn("Error: Cannot create new journal release: ", err);
            }
            this.setState({
                loading: false,
                error: "",
            });
            this.props.hide();
            this.props.updateComponentData();
        }
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.hide}
                    centered
                    scrollable
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            Edit {this.props.data.releaseName} details
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row mt-3">
                            <div className="col-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                        this.setState({
                                            releaseName: e.target.value,
                                        })
                                    }
                                    placeholder="Release Name"
                                    value={this.state.releaseName}
                                />
                            </div>
                            <div className="col-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    onChange={(e) =>
                                        this.setState({
                                            releaseDate: e.target.value,
                                        })
                                    }
                                    placeholder="Release Date"
                                    value={this.state.releaseDate}
                                />
                            </div>
                            <div className="row ml-5 mt-4">
                                <input
                                    className="mt-2"
                                    type="checkbox"
                                    onChange={(e) =>
                                        this.setState({
                                            releaseVisibility: e.target.checked,
                                        })
                                    }
                                    checked={this.state.releaseVisibility}
                                />
                                <label className="ml-2">
                                    Release Visibility
                                </label>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={this.props.hide}>
                            Close
                        </Button>
                        {this.state.loading ? (
                            <button
                                class="btn btn-primary"
                                type="button"
                                disabled
                            >
                                <span
                                    class="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            </button>
                        ) : (
                            <Button
                                variant="primary"
                                onClick={this.handleEditSubmit}
                            >
                                Update
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default ManageJournalRelease;
