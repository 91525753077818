const utils = {
    invalidFile: (file, types) => {
        if (file === undefined || file === null) return true;
        if (file.name === undefined || file === "") return true;
        else {
            let returnValue = false;
            types.forEach((type) => {
                if (utils.getFileExtension(file.name) === type)
                    returnValue = true;
            });
            return !returnValue;
        }
    },
    getFileExtension: (filename) => {
        var ext = /^.+\.([^.]+)$/.exec(filename);
        return ext == null ? "" : `.${ext[1]}`;
    },
    getFileContentType: (type) => {
        switch (type) {
            case ".docx":
                return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
            case ".doc":
                return "application/msword";
            case ".pdf":
                return "application/pdf";
            default:
                return "";
        }
    },
};

export default utils;
