import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function PrivateRoute({ component: Component, ...rest }) {
    const { currentUser } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                currentUser ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
}

export function UnLoggedRoute({ component: Component, ...rest }) {
    const { currentUser } = useAuth();

    return (
        <Route
            {...rest}
            render={(props) =>
                !currentUser ? <Component {...props} /> : <Redirect to="/" />
            }
        />
    );
}

export function AdminRoute({ component: Component, ...rest }) {
    const { currentUser, admin } = useAuth();
    return (
        <Route
            {...rest}
            render={(props) =>
                admin && currentUser ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/" />
                )
            }
        />
    );
}
