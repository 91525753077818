import { Component } from "react";
import { storage } from "../../firebase";

class EventCard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventPosterFile: "",
            eventMOMFile: "",
            eventBrochureFile: "",
            streamLinks: [],
        };
    }

    async fetcheventPosterFile() {
        if (this.props.event.eventPosterFile) {
            try {
                const eventPosterFile = await storage
                    .ref()
                    .child(`events/uploads/${this.props.event.eventPosterFile}`)
                    .getDownloadURL();
                this.setState({ eventPosterFile });
            } catch (err) {
                console.log("Unable to fetch", err);
            }
        }
    }

    async fetcheventMOMFile() {
        if (this.props.event.eventMOMFile) {
            try {
                const eventMOMFile = await storage
                    .ref()
                    .child(`events/uploads/${this.props.event.eventMOMFile}`)
                    .getDownloadURL();
                this.setState({ eventMOMFile });
            } catch (err) {
                console.log("Unable to fetch", err);
            }
        }
    }

    async fetcheventBrochureFile() {
        if (this.props.event.eventBrochureFile) {
            try {
                const eventBrochureFile = await storage
                    .ref()
                    .child(
                        `events/uploads/${this.props.event.eventBrochureFile}`
                    )
                    .getDownloadURL();
                this.setState({ eventBrochureFile });
            } catch (err) {
                console.log("Unable to fetch", err);
            }
        }
    }

    parseSpeakers(speakers) {
        let allSpeakers = [];
        let listSpeaker = speakers.split("#");
        listSpeaker.forEach((speaker) => {
            allSpeakers.push({
                name: speaker.split("*")[0],
                topic: speaker.split("*")[1],
            });
        });
        return allSpeakers;
    }

    parseDateAndTime() {
        return (
            new Date(this.props.event.eventDateAndTime.seconds).toDateString() +
            " | " +
            new Date(
                this.props.event.eventDateAndTime.seconds
            ).toLocaleTimeString()
        );
    }

    parseStreamLinks(streamLinks) {
        let allLinks = [];
        let linkList = streamLinks.split("#");
        linkList.forEach((link) => {
            allLinks.push({
                tag: link.split("*")[0],
                link: link.split("*")[1],
            });
        });
        return allLinks;
    }

    async componentDidMount() {
        await this.fetcheventPosterFile();
        await this.fetcheventMOMFile();
        await this.fetcheventBrochureFile();
    }

    render() {
        return (
            <>
                <div className="mt-5" />
                <div className="card" style={{ borderRadius: "10px" }}>
                    <div className="card-body">
                        <div className="row" style={{ display: "flex" }}>
                            <div className="col">
                                <h2>
                                    {this.props.event.eventID} |{" "}
                                    {this.props.event.eventType} on{" "}
                                    {this.props.event.eventTitle}
                                </h2>
                                <div className="mt-4"></div>
                                <i>{this.props.event.eventDescription}</i>
                                <div className="mt-4"></div>
                                <h4>Date and Time</h4>
                                <h5>
                                    {this.parseDateAndTime(
                                        this.props.event.eventDateAndTime
                                    )}
                                </h5>
                                <div className="mt-4"></div>
                                <h4>
                                    Event Place / Platform:{" "}
                                    {this.props.event.eventPlace}
                                </h4>
                                <div className="mt-4"></div>
                                <h4>Eminent Speakers</h4>
                                {this.parseSpeakers(
                                    this.props.event.speakers
                                ).map((speaker) => {
                                    return (
                                        <b>
                                            <i>
                                                {speaker.name} | {speaker.topic}
                                            </i>
                                            <br />
                                        </b>
                                    );
                                })}
                                <div className="mt-4"></div>
                                <h4>Live Stream Links</h4>
                                {this.parseStreamLinks(
                                    this.props.event.streamLinks
                                ).map((link) => {
                                    return (
                                        <a
                                            href={link.link}
                                            target="blank"
                                            style={{
                                                textDecoration: "none",
                                                color: "blue",
                                            }}
                                        >
                                            {link.tag}
                                            <br />
                                        </a>
                                    );
                                })}
                                <div className="mt-4"></div>
                                <h4>Event Coordinator</h4>
                                <i>{this.props.event.coordinatorName}</i>
                                <div className="mt-1"></div>
                                <i>{this.props.event.coordinatorMobile}</i>
                                <div className="mt-4"></div>
                                {this.state.eventBrochureFile && (
                                    <a
                                        href={this.state.eventBrochureFile}
                                        target="blank"
                                        style={{
                                            textDecoration: "none",
                                            color: "blue",
                                        }}
                                    >
                                        Download Brochure
                                    </a>
                                )}
                                <div className="mt-1" />
                                {this.state.eventMOMFile && (
                                    <a
                                        href={this.state.eventMOMFile}
                                        target="blank"
                                        style={{
                                            textDecoration: "none",
                                            color: "blue",
                                        }}
                                    >
                                        Download MOM
                                    </a>
                                )}
                            </div>
                            <div className="col-6">
                                <img
                                    src={this.state.eventPosterFile}
                                    style={{ borderRadius: "10px" }}
                                    width="100%"
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-5" />
            </>
        );
    }
}

export default EventCard;
