import React, { Component } from "react";

export class datetime extends Component {
    constructor(props) {
        super(props);
        const current = new Date();
        this.state = {
            datetime: this.props.datetime ? this.props.datetime : current,
            time: `${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`,
            date: `${current.getFullYear()}-${
                current.getMonth() + 1
            }-${current.getDate()}`,
        };
    }

    async componentDidUpdate(prevProps, prevState) {
        const { datetime } = this.state;
        const { datetime: prevDatetime } = prevState;
        if (datetime !== prevDatetime) this.props.onData(datetime);
    }

    dateInputHandler = async (event) => {
        await this.setState({ date: event.target.value });
        const timeArray = this.state.time.split(":");
        const hours = timeArray[0];
        const minutes = timeArray[1];
        const seconds = timeArray[2];
        const dateArray = this.state.date.split("-");
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2];
        const new_datetime = new Date(
            year,
            month - 1,
            day,
            hours,
            minutes,
            seconds
        );
        await this.setState({
            datetime: new_datetime.valueOf(),
        });
    };

    timeInputHandler = (event) => {
        this.setState({ time: event.target.value });
        const timeArray = event.target.value.split(":");
        const hours = timeArray[0];
        const minutes = timeArray[1];
        const seconds = timeArray[2];
        const dateArray = this.state.date.split("-");
        const year = dateArray[0];
        const month = dateArray[1];
        const day = dateArray[2];
        const new_datetime = new Date(
            year,
            month,
            day,
            hours,
            minutes,
            seconds
        );
        this.setState({
            datetime: new_datetime.valueOf(),
        });
    };

    render() {
        let { datetime } = this.state;
        if (typeof datetime === "number") datetime = new Date(datetime);
        let timeValue, dateValue;

        try {
            const year = datetime.getFullYear();
            let month = datetime.getMonth() + 1;
            let date = datetime.getDate();
            let hours = datetime.getHours();
            let minutes = datetime.getMinutes();
            let seconds = datetime.getSeconds();

            if (date.toString().length === 1) date = `0${date}`;
            if (month.toString().length === 1) month = `0${month}`;
            if (hours.toString().length === 1) hours = `0${hours}`;
            if (minutes.toString().length === 1) minutes = `0${minutes}`;
            if (seconds.toString().length === 1) seconds = `0${seconds}`;

            timeValue = `${hours}:${minutes}:${seconds}`;
            dateValue = `${year}-${month}-${date}`;
        } catch (err) {
            timeValue = "NA";
            dateValue = "NA";
        }

        return (
            <div className={`col-lg-${this.props.cols} row form-group`}>
                <label className="col-12">{this.props.label}</label>
                <div className="col-6">
                    <input
                        className="form-control"
                        type="date"
                        value={dateValue}
                        onChange={this.dateInputHandler}
                    />
                </div>
                <div className="col-6">
                    <input
                        className="form-control"
                        type="time"
                        value={timeValue}
                        onChange={this.timeInputHandler}
                        step="2"
                    />
                </div>
            </div>
        );
    }
}

export default datetime;
