import React from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import { Link } from "react-router-dom";
import "./guidelines.css";

export default class Guidelines extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Research Guidelines"
                    tagline="KAIJOR International Academic Journal"
                />
                <div className="container main w3-animate-zoom">
                    <a
                        href="https://firebasestorage.googleapis.com/v0/b/research-2831b.appspot.com/o/additional%20uploads%2Furdu_guidelines.pdf?alt=media&token=1f6dac7c-d446-4381-9dfa-5b27c1f7e122"
                        target="blank"
                        style={{ color: "Blue", textDecoration: "none" }}
                    >
                        <h6>Click here for guidelines for urdu Authors</h6>
                    </a>
                    <h2>Criteria for Publication</h2>
                    <p>
                        Outlined below is the mandatory criteria for any article
                        to be considered for publication in the Kanzuliman
                        Academic International Journal On Razawiyât&nbsp;
                        <i>KAIJOR</i>. Failing to fulfill these criteria will
                        result in rejection of the article by the editorial
                        team. Article adheres to the manuscript preparation
                        guidelines explained below.
                    </p>
                    <ul>
                        <li>
                            Article should be in Microsoft Word or In-page
                            format only.
                        </li>
                        <li>
                            Each article should be Submitted in Online mode
                            only.
                        </li>
                        <li>
                            Article Length should not more than 13 Pages or 8000
                            words.
                        </li>
                        <li>
                            Minimum Article Length should be 3 Pages or 1600
                            words.
                        </li>
                        <li>
                            While the thesis length should be minimum 25 Pages
                            or 20000 words.
                        </li>
                        <li>
                            Articles should be written in double column format,
                            using <i>Times New Roman font</i>,
                            <i> 08 point font size</i> or as per Paper Template.
                            Keep the layout of the text as simple as possible.
                        </li>
                        <li>
                            All figures provided are of high resolution,
                            preferably 300dpi
                        </li>
                        <li>
                            References should follow the &nbsp;
                            <a
                                href="https://sciresol.s3.us-east-2.amazonaws.com/IJST/Author-Guidelines/Vancouverstyle-references.pdf"
                                style={{
                                    color: "Blue",
                                    textDecoration: "none",
                                }}
                                target="blank"
                            >
                                Vancouver Style of Referencing
                            </a>
                        </li>
                    </ul>

                    <h2>Manuscript Preparation Guidelines</h2>
                    <p>
                        Articles submitted to Kanzuliman Academic International
                        Journal On Razawiyât <i>KAIJOR</i> should confirm to the
                        guidelines indicated below. Before you submit, please
                        study the author checklist sample provided at the end of
                        this document. Following is the chronological order of
                        topics to be included in the article
                    </p>
                    <ol>
                        <li>Title</li>
                        <li>Abstract</li>
                        <li>Keyword</li>
                        <li>Introduction</li>
                        <li>
                            Concept Headings (Including Statiscal methodology,
                            if any)
                        </li>
                        <li>Discussion</li>
                        <li>Conclusion</li>
                        <li>Acknowledgement (if any)</li>
                        <li>References</li>
                    </ol>
                    <p>
                        <b>NOTE </b>
                        In order to maintain the cultural uniqueness of Arabic
                        Language the following verses of sermon may be added
                        before Introduction
                    </p>
                    <p
                        className="text-center"
                        style={{ fontSize: "25px", fontWeight: "bold" }}
                    >
                        الحمد لله الذي هدانا، والصلاة والسلام علي رسوله
                        الاعليٰ،وعلي آله وصحبه نجوم الهديٰ ،وثبّتنا الله علي
                        ترويج افكار الرضا
                    </p>

                    <h5>Electronics Format</h5>
                    <p>
                        Authors are requested to send their articles in MS Word
                        (.doc) format. In case of any difficulty with the
                        manuscript submission process or concern regarding the
                        suitability of your files, please contact us at&nbsp;
                        <span style={{ color: "Blue", textDecoration: "none" }}>
                            kaijor.kanzuliman@gmail.com
                        </span>
                    </p>

                    <h5>Article Title</h5>
                    <p>
                        The title should be concise and specific to the topic of
                        the article Avoid using abbreviations in the title.
                        Titles should be presented in title case, meaning that
                        all words except for prepositions, articles, and
                        conjunctions should be capitalized. All botanical names
                        should be in italics.
                    </p>

                    <h5>Author’s Names and Affiliations</h5>
                    <p>
                        Provide first names or initials (if used), middle names
                        or initials (if used), and surnames for all authors.
                        Affiliation details should include—department,
                        university or organization, city, state and country for
                        all authors. One of the authors should be designated as
                        the corresponding author with asterisk (*) against
                        his/her name. Only the corresponding author’s email
                        address should be provided in the article. It is the
                        corresponding author’s responsibility to ensure that the
                        author list and the summary of the author contributions
                        to the study are accurate and complete
                    </p>
                    <h5>Abstract and Keywords</h5>
                    <p>
                        The abstract introduces the article and should be
                        at-least 200 words but not exceed 350 words. It should
                        mention the techniques used without going into
                        methodological detail and should summarize the most
                        important results. Please do not include any citations
                        in the abstract and avoid using abbreviations if
                        possible. Authors should provide 10-15 keywords for
                        indexing purposes. Keywords should be written in title
                        case and separated by comma. Avoid general and plural
                        terms and multiple concepts (avoid for example, ‘and’,
                        ‘of’).
                    </p>
                    <h5>Headings</h5>
                    <p>
                        For a better understanding of the content in the
                        article, we encourage authors to number the article
                        headings in Arabic style format. Headings should follow
                        title case, meaning that all words except for
                        prepositions, articles, and conjunctions should be
                        capitalized. All botanical names should be in italics.
                        For example: 1. Kanzul Iman Foundation (KIF), 1.1
                        Kanzuliman Islamic Library (KIL)
                    </p>

                    <h5>Abbreviations</h5>
                    <p>
                        All abbreviations should be defined on first use in the
                        text along with the abbreviation in parenthesis. E.g.
                        International Standard (IS)
                    </p>

                    <h5>Acknowledgments</h5>
                    <p>
                        People who contributed to the work but do not fit the
                        criteria for authors should be listed in the
                        Acknowledgments, along with their contributions. Authors
                        are requested to ensure that anyone named in the
                        Acknowledgments agrees to being so named.
                    </p>

                    <h5>References</h5>
                    <ul>
                        <li>
                            Only published or accepted manuscripts should be
                            included in the reference list. Meetings, abstracts,
                            conference talks, or papers that have been submitted
                            but not yet accepted should not be cited.
                        </li>
                        <li>
                            In text citations: References cited in text should
                            conform to the Vancouver style. Please refer the
                            Vancouver Style of Referencing.
                        </li>
                        <li>
                            Reference List: This should only contain references
                            to those works which you have cited in your text. It
                            should appear at the end of your text. It should be
                            arranged numerically by citation number. Examples
                            are indicated below.
                        </li>
                    </ul>

                    <h2>Review and Decisions</h2>
                    <ul>
                        <li>
                            Manuscripts that are judged to be of insufficient
                            quality or unlikely to be competitive enough for
                            publication will be rejected during initial
                            screening. The remaining manuscripts go through a
                            review process, and possible decisions are: accept
                            as is, minor revision, major revision, or reject.
                            Authors should submit back their revisions within
                            2-3 days in the case of minor revision, or 10 days
                            in the case of major revision.
                        </li>
                        <li>
                            Only papers written in the English language shall be
                            accepted, and the authors are fully responsible for
                            the texts, citations and references.
                        </li>
                        <li>
                            Each corresponding author will receive one copy of
                            the Journal with certificates(soft copy only).
                            Individual author Certificates may be supplied upon
                            request and must be paid by the author Rs
                            150/-(Single Author) for soft copy for hard copy of
                            certificates for maximum of FIVE INDIVIDUAL AUTHORS
                            must be paid by the Author Rs 150/-(SINGLE AUTHOR )
                        </li>
                        <li>
                            A note on plagiarism: There is a zero-tolerance
                            policy towards plagiarism (including
                            self-plagiarism) in our journals. Manuscripts are
                            screened for plagiarism before or during
                            publication, and if found they will be rejected at
                            any stage of processing.
                        </li>
                    </ul>

                    <h2>Submission</h2>
                    <p>
                        Manuscripts Submission&nbsp;
                        <Link
                            to="/authors/online-paper-submission"
                            style={{ color: "blue", textDecoration: "none" }}
                        >
                            online{" "}
                        </Link>
                        or offline
                    </p>
                    <ul>
                        <li>
                            Please embed figures and tables in the manuscript to
                            become one single file for submission. Once
                            submission is complete, the system will generate a
                            manuscript ID later stage user can track paper
                            status by online .
                        </li>
                        <li>
                            The manuscript must be submitted to the Editor by
                            e-mail submission kaiojr.kanzuliman@gmail.com or
                            through an online submission form. After the
                            successful submission of manuscript the
                            corresponding author will be acknowledged within
                            five days .
                        </li>
                        <li>
                            Original Research Articles not previously published
                            and not being considered for publication elsewhere
                            only should be submitted. Corresponding authors must
                            declare that the manuscript is submitted on behalf
                            of all authors. Copyright belongs to the publisher
                            upon acceptance of the manuscript. Rejected
                            manuscript will not be returned to the authors.
                            Submission of a manuscript signifies acceptance of
                            journal’s guidelines for authors.
                        </li>
                    </ul>

                    <h2>Modifications</h2>
                    <p>
                        Modification can be allowed only before publication,
                        once paper get published modifications are not allowed.
                    </p>
                </div>
            </div>
        );
    }
}
