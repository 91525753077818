const validations = {
    isNull: (data) => (data.length === 0 ? false : data.trim().length),
    isNumber: (data) =>
        data.length === 0 ? false : data.trim().match(/^\d{10}$/),
    isInRange: (lower, upper, data) =>
        data.trim().length >= lower && data.trim().length <= upper,
    isChar: (data) => data.trim().match(/^[A-Za-z]+$/),

    removeSpaces: (data) => (data.length === 0 ? "" : data.trim()),

    name: (firstName, lastName) => `${firstName.trim()} ${lastName.trim()}`,

    email: (email) =>
        email
            .trim()
            .match(
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
            ),

    mobile: (mobile) => {
        mobile = mobile.trim();
        mobile = mobile.split(" ").join("");
        const mobileNumber = mobile.split(")")[1];
        const validMobileNumber = /^\d{10}$/;
        return mobileNumber.match(validMobileNumber);
    },

    nameValidator: (name) => ({
        invalidNameLimit: !validations.isInRange(2, 20, name),
        invalidNameChar: !(validations.isChar(name) !== null),
    }),

    minimumLength: (value, min) => {
        return value.trim().length >= min;
    },

    maximumLength: (value, max) => {
        return value.trim().length <= max;
    },
};

export default validations;
