import React from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import validators from "./utilities/validations";
import "../../../node_modules/font-awesome/css/font-awesome.min.css";
import "./styles.css";

import Number from "../formGroup/mobile";
import Field from "../formGroup/Field";
import axios from "axios";
import { appConfig } from "../../config";

export class contact extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            firstname: "",
            lastname: "",
            email: "",
            number: "",
            message: "",
            code: "+91",
            errorfirstName: true,
            errorlastName: true,
            erroremail: true,
            errormobile: true,
            validate: false,
            invalidNumber: false,
            isNull: false,
            disable: false,
            showError: false,
            showSpinner: false,
        };
    }

    clickHandler = async (event) => {
        event.preventDefault();

        await this.setState({ validate: true, showError: false });
        if (!this.errorHandler()) return;
        await this.setState({ disable: true, showSpinner: true });

        try {
            const informationSent = {
                name: validators.name(
                    this.state.firstName,
                    this.state.lastName
                ),
                email: validators.email(this.state.email),
                mobile: `${this.state.code} ${this.state.number}`,

                message: validators.removeSpaces(this.state.message),
            };

            if (
                window.location.hostname !== "localhost" &&
                window.location.hostname !== "127.0.0.1"
            ) {
                try {
                    const requestSent = {
                        subject: `${informationSent.name} has sent a Message`,
                        text: `${informationSent.name} sent you a message, 
Message: ${informationSent.message},
Name: ${informationSent.name},
Email: ${informationSent.email},
Contact: ${informationSent.mobile},
                        
                        
Regards,
${informationSent.name}
                        `,
                    };
                    await axios.post(
                        `${appConfig.emailEndpoint}sendEmail`,
                        requestSent
                    );
                } catch (err) {
                    console.log("MailError: Unabel to send email", err);
                }
            }

            this.props.history.push("/join/success");
        } catch (err) {
            await this.setState({
                disable: false,
                showError: true,
                showSpinner: false,
            });
        }
    };

    errorHandler = () => {
        if (this.state.errorfirstName) return false;
        if (this.state.errorlastName) return false;
        if (this.state.errormobile) return false;
        if (this.state.erroremail) return false;

        return true;
    };

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Contact Us"
                    tagline="Taleem | Ilm | Razawiyāt"
                />
                <div className="container main">
                    <center>
                        <h3>
                            We would love to hear from you, share your
                            experience about Kanzuliman.org
                        </h3>
                    </center>

                    {this.state.showError && (
                        <div className="alert alert-danger" role="alert">
                            Some error has occured, please check your network
                            connection and try again!
                        </div>
                    )}

                    <form className="container row mt-5">
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(firstName) => this.setState({ firstName })}
                            onChange={(errorfirstName) =>
                                this.setState({ errorfirstName })
                            }
                            label="First Name *"
                            validations={[
                                ["required", "Please provide your first name"],
                                ["min", 2],
                                ["max", 20],
                            ]}
                            type="text"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(lastName) => this.setState({ lastName })}
                            onChange={(errorlastName) =>
                                this.setState({ errorlastName })
                            }
                            label="Last Name *"
                            validations={[
                                ["required"],
                                ["min", 2],
                                ["max", 20],
                            ]}
                            type="text"
                        />
                        <Field
                            cols="6"
                            validate={this.state.validate}
                            onData={(email) => this.setState({ email })}
                            onChange={(erroremail) =>
                                this.setState({ erroremail })
                            }
                            label="Email Address *"
                            validations={[["required"], ["email"]]}
                            type="email"
                        />
                        <Number
                            onNumber={(number) => this.setState({ number })}
                            onCode={(code) => this.setState({ code })}
                            cols="6"
                            validate={this.state.validate}
                            emitError={(errormobile) =>
                                this.setState({ errormobile })
                            }
                        />
                        <Field
                            cols="12"
                            validate={this.state.validate}
                            onData={(message) => this.setState({ message })}
                            label="Message"
                            validations={[]}
                            onChange={(x) => x}
                            type="textarea"
                        />
                        <div className="col-12 mt-3 form-group">
                            <button
                                onClick={this.clickHandler}
                                type="submit"
                                className="btn btn-dark"
                                disabled={this.state.disable}
                            >
                                {!this.state.showSpinner && <span>Send</span>}
                                {this.state.showSpinner && (
                                    <i className="fa fa-spinner fa-spin fa-1x" />
                                )}
                            </button>
                        </div>
                    </form>
                    <div className="row mt-5 container information">
                        <div
                            className="card-alt col-lg-3 col-md-6 col-sm-12"
                            style={{ borderRadius: "25px" }}
                        >
                            <center>
                                <i className="fa fa-address-card fa-5x" />
                            </center>
                            <div className="mt-4">
                                <h6>Come Visit Us At:-</h6>
                                <p className="mt-3">
                                    1st F, Khanqah-e-Aaliyah Qadriya Razawiya
                                    Nooria Tehsinia,
                                    <br /> Allama Tehseen Raza Khan Street,
                                    Kankar Tola, Old City,
                                    <br /> Bareilly Shareef, India - 243005
                                </p>
                            </div>
                        </div>
                        <div
                            className="card col-lg-3 col-md-6 col-sm-12"
                            style={{ borderRadius: "25px" }}
                        >
                            <center>
                                <i className="fa fa-map-marker fa-5x" />
                            </center>
                            <div className="mt-4">
                                <h6>Visit our R&D headquaters:-</h6>
                                <p className="mt-3">
                                    Swaleh Nagar, Kahkashan Enclave, NH 24,
                                    Swale Nagar, Bareilly, Uttar Pradesh 243502
                                </p>
                            </div>
                        </div>
                        <div
                            className="card-alt col-lg-3 col-md-6 col-sm-12"
                            style={{ borderRadius: "25px" }}
                        >
                            <center>
                                <i className="fa fa-envelope fa-5x" />
                            </center>
                            <div className="mt-4">
                                <h6>Mail us at:-</h6>
                                <p className="mt-3">
                                    info.kanzuliman@gmail.com
                                    <br />
                                    kaijor.kanzuliman@gmail.com
                                </p>
                            </div>
                        </div>
                        <div
                            className="card col-lg-3 col-md-6 col-sm-12"
                            style={{ borderRadius: "25px" }}
                        >
                            <center>
                                <i className="fa fa-mobile fa-5x" />
                            </center>
                            <div className="mt-4">
                                <h6>Call us at:-</h6>
                                <p className="mt-3">
                                    <ul>
                                        <li>+91 892 376 1145 | Office</li>
                                        <li>
                                            +91 950 621 5324 | KAIJOR Kanzuliman
                                            Reasearch and Academic Journal
                                        </li>
                                    </ul>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default contact;
