import React from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import { db } from "../../firebase";
import Card from "./utilities/card";
import advisoryMessage from "../../assets/advisoryMessage.jpg";
import cheifEditorMessage from "../../assets/cheifEditorMessage.jpg";

export class board extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "",
            tagline: "",
            currentURLParamOption: "",
            data: [],
            loading: false,
            spinnerStyle: {
                width: "5rem",
                height: "5rem",
                marginTop: "10rem",
            },
        };
    }

    async fetchDataFromFirebaseDB(role) {
        this.setState({
            loading: true,
        });
        let data = [];
        const response = await db
            .collection("users")
            .where("role", "==", role)
            .get();
        response.forEach((doc) => {
            let temp = {};
            temp = doc.data();
            temp.id = doc.id;
            if (temp.isVerified) {
                data.push(temp);
            }
        });
        data.sort((first, second) => {
            if (first.priorityID < second.priorityID) {
                return -1;
            } else {
                return 1;
            }
        });
        this.setState({
            data: data,
            loading: false,
        });
    }

    async componentDidUpdate() {
        if (
            this.props.match.params.option !== this.state.currentURLParamOption
        ) {
            switch (this.props.match.params.option) {
                case "editorial-board":
                    this.setState({
                        title: "Editorial Board",
                        tagline: "Members of editorial board of KAIJOR",
                        currentURLParamOption: "editorial-board",
                    });
                    this.fetchDataFromFirebaseDB("editorial");
                    break;
                case "reviewer-board":
                    this.setState({
                        title: "Reviewer Board",
                        tagline: "Members of reviewer board of KAIJOR",
                        currentURLParamOption: "reviewer-board",
                    });
                    this.fetchDataFromFirebaseDB("reviewer");
                    break;
                case "associate-editorial-board":
                    this.setState({
                        title: "Associate Editorial Board",
                        tagline:
                            "Members of associate editorial board of KAIJOR",
                        currentURLParamOption: "associate-editorial-board",
                    });
                    this.fetchDataFromFirebaseDB("associate-editorial");
                    break;
                case "volunteer-board":
                    this.setState({
                        title: "Our Vounteers",
                        tagline: "Volunteers of KAIJOR | Kanzuliman Foundation",
                        currentURLParamOption: "volunteer-board",
                    });
                    this.fetchDataFromFirebaseDB("volunteer");
                    break;
                case "advisory-board":
                    this.setState({
                        title: "Advisory Board",
                        tagline: "Members of advisory board of KAIJOR",
                        currentURLParamOption: "advisory-board",
                    });
                    this.fetchDataFromFirebaseDB("advisory-member");
                    break;
                case "advisory-message":
                    this.setState({
                        title: "Advisory Message",
                        tagline: "Message from Advisory board of KAIJOR",
                        currentURLParamOption: "advisory-message",
                    });
                    break;
                case "editorial-message":
                    this.setState({
                        title: "Cheif Editor's Message",
                        tagline: "Message from Cheif Editor of KAIJOR",
                        currentURLParamOption: "editorial-message",
                    });
                    break;
                default:
                    break;
            }
        }
    }

    async componentDidMount() {
        this.componentDidUpdate();
    }

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading={this.state.title}
                    tagline={this.state.tagline}
                />
                {this.props.match.params.option === "advisory-message" && (
                    <div style={{ marginTop: "200px" }}>
                        <div className="container">
                            <h1>
                                <i>A message from Advisory chair</i>
                            </h1>
                            <h3>
                                Professor Mawlana Afroz Qadri
                                <br />
                                Dallas University, <br />
                                Capetown South Africa
                            </h3>
                        </div>
                        <div className="text-center">
                            <img
                                src={advisoryMessage}
                                alt="Advisory Message"
                                height="100%"
                            />
                        </div>
                    </div>
                )}
                {this.props.match.params.option === "editorial-message" && (
                    <div style={{ marginTop: "200px" }}>
                        <div className="container">
                            <h1>
                                <i>A message from Editorial Chair</i>
                            </h1>
                            <h3>
                                Hazrat Allama Mawlana Qazi Shaheed Aalam Razawi
                                <br />
                                Jamia Nooriya Razviya
                                <br />
                                Bareilly Shareef
                            </h3>
                        </div>
                        <div className="text-center">
                            <img
                                src={cheifEditorMessage}
                                alt="Cheif Editors Message"
                                height="100%"
                            />
                        </div>
                    </div>
                )}
                <div
                    className="container"
                    style={{ marginTop: "150px", marginBottom: "200px" }}
                >
                    {this.state.loading && (
                        <div className="text-center">
                            <div
                                class="spinner-border text-success"
                                style={this.state.spinnerStyle}
                                role="status"
                            >
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    )}
                    {!this.state.loading &&
                        this.state.data.map((record, key) => {
                            return <Card key={key} information={record} />;
                        })}
                    {!this.state.loading &&
                        this.props.match.params.option !==
                            "editorial-message" &&
                        this.props.match.params.option !== "advisory-message" &&
                        this.state.data.length === 0 && (
                            <div className="alert alert-info" role="alert">
                                Please check your connection. Unable to load
                                data
                            </div>
                        )}
                </div>
            </div>
        );
    }
}

export default board;
