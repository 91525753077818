import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { appConfig } from "./config";

let firebaseConfig;
if (
    window.location.hostname === "research-2831b.web.app" ||
    window.location.hostname === "research-2831b.firebaseapp.com" ||
    window.location.hostname === "research.kanzuliman.org"
) {
    firebaseConfig = appConfig.productionConfig;
} else {
    firebaseConfig = appConfig.developmentConfig;
}

const app = firebase.initializeApp(firebaseConfig);

export const auth = app.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export default app;
