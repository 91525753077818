import React from "react";
import "./topnav.css";
import logo from "../../assets/circlelogo.png";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function TopNav() {
    const { currentUser, logout, admin } = useAuth();
    const history = useHistory();

    async function handleLogout() {
        try {
            await logout();
            history.push("/");
        } catch {}
    }
    return (
        <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-black top-nav">
            <Link to="/">
                <Link className="navbar-brand" to="/">
                    <img src={logo} className="logo" alt="logo" /> Kanzuliman
                    Research
                </Link>
            </Link>
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav">
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle dropdown-btn"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            About
                        </button>
                        <div
                            className="dropdown-menu dropdown-content"
                            aria-labelledby="dropdownMenu2"
                        >
                            <Link to="/about">
                                <button className="dropdown-item" type="button">
                                    Publication
                                </button>
                            </Link>
                            <Link to="/president">
                                <button className="dropdown-item" type="button">
                                    President's Message
                                </button>
                            </Link>
                            <Link to="/mission">
                                <button className="dropdown-item" type="button">
                                    Mission And Scope
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle dropdown-btn"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Our Board
                        </button>
                        <div
                            className="dropdown-menu dropdown-content"
                            aria-labelledby="dropdownMenu2"
                        >
                            <Link to="/board/advisory-message">
                                <button className="dropdown-item" type="button">
                                    Advisory Message
                                </button>
                            </Link>
                            <Link to="/board/advisory-board">
                                <button className="dropdown-item" type="button">
                                    Advisory Board
                                </button>
                            </Link>
                            <Link to="/board/editorial-message">
                                <button className="dropdown-item" type="button">
                                    Cheif Editor's Message
                                </button>
                            </Link>
                            <Link to="/board/editorial-board">
                                <button className="dropdown-item" type="button">
                                    Editorial Board
                                </button>
                            </Link>
                            <Link to="/board/associate-editorial-board">
                                <button className="dropdown-item" type="button">
                                    Associate Editorial
                                </button>
                            </Link>
                            <Link to="/board/reviewer-board">
                                <button className="dropdown-item" type="button">
                                    Reviewer Board
                                </button>
                            </Link>
                            <Link to="/board/volunteer-board">
                                <button className="dropdown-item" type="button">
                                    Our Volunteers
                                </button>
                            </Link>
                        </div>
                    </div>

                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle dropdown-btn"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Archives
                        </button>
                        <div
                            className="dropdown-menu dropdown-content"
                            aria-labelledby="dropdownMenu2"
                        >
                            {/* 
                            <Link to="/research/books">
                                <button className="dropdown-item" type="button">
                                    Research Books
                                </button>
                            </Link>
                            <Link to="/research/papers">
                                <button className="dropdown-item" type="button">
                                    Research Papers
                                </button>
                            </Link>
                            <Link to="/research/reviews">
                                <button className="dropdown-item" type="button">
                                    Research Reviews
                                </button>
                            </Link>
                            <Link to="/research/others">
                                <button className="dropdown-item" type="button">
                                    Other Publications
                                </button>
                            </Link>
                            <Link to="/research/caseStudies">
                                <button className="dropdown-item" type="button">
                                    Research Case Studies
                                </button>
                            </Link> */}
                            <Link to="/research/volumes">
                                <button className="dropdown-item" type="button">
                                    Volumes
                                </button>
                            </Link>
                            <Link to="/research/specialIssues">
                                <button className="dropdown-item" type="button">
                                    Special Issues
                                </button>
                            </Link>
                            <Link to="/research/pastPublications">
                                <button className="dropdown-item" type="button">
                                    Past Publications
                                </button>
                            </Link>
                            <Link to="/research/thesis">
                                <button className="dropdown-item" type="button">
                                    Research Thesis
                                </button>
                            </Link>
                            <Link to="/research/officialDownloads">
                                <button className="dropdown-item" type="button">
                                    Official Downloads
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle dropdown-btn"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            For Authors
                        </button>
                        <div
                            className="dropdown-menu dropdown-content"
                            aria-labelledby="dropdownMenu2"
                        >
                            <button className="dropdown-item" type="button">
                                Research Tools
                            </button>
                            <Link to="/authors/online-paper-submission">
                                <button className="dropdown-item" type="button">
                                    Online Paper Submission
                                </button>
                            </Link>
                            <Link to="/authors/guidelines">
                                <button className="dropdown-item" type="button">
                                    Guidelines
                                </button>
                            </Link>
                            <button className="dropdown-item" type="button">
                                Downloads
                            </button>
                        </div>
                    </div>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle dropdown-btn"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Activities
                        </button>
                        <div
                            className="dropdown-menu dropdown-content"
                            aria-labelledby="dropdownMenu2"
                        >
                            <Link to="activities/view-events">
                                <button className="dropdown-item" type="button">
                                    Events
                                </button>
                            </Link>
                            <button className="dropdown-item" type="button">
                                Projects And Funds
                            </button>
                        </div>
                    </div>
                    <div className="dropdown">
                        <button
                            className="btn dropdown-toggle dropdown-btn"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            Join Us
                        </button>
                        <div
                            className="dropdown-menu dropdown-content"
                            aria-labelledby="dropdownMenu2"
                        >
                            <Link to="/join/contact">
                                <button className="dropdown-item" type="button">
                                    Contact Us
                                </button>
                            </Link>
                            <Link to="/join/volunteer">
                                <button className="dropdown-item" type="button">
                                    Volunteer Form
                                </button>
                            </Link>
                            <Link to="/join/application/advisory-application">
                                <button className="dropdown-item" type="button">
                                    Apply as Advisory Member
                                </button>
                            </Link>
                            <Link to="/join/application/associate-editorial">
                                <button className="dropdown-item" type="button">
                                    Apply as Associate Editorial
                                </button>
                            </Link>
                            <Link to="/join/application/editorial">
                                <button className="dropdown-item" type="button">
                                    Apply as Editorial
                                </button>
                            </Link>
                            <Link to="/join/application/reviewer">
                                <button className="dropdown-item" type="button">
                                    Apply as Reviewer
                                </button>
                            </Link>
                            <Link to="/join/journal">
                                <button className="dropdown-item" type="button">
                                    Journal SponsorShip Form
                                </button>
                            </Link>
                        </div>
                    </div>
                    <div className="dropdown">
                        <a
                            className="btn dropdown-btn"
                            href="https://pages.razorpay.com/pl_FMO2Br91KUr3Ya/view"
                            target="blank"
                        >
                            Donate
                        </a>
                    </div>
                    {!currentUser ? (
                        <>
                            <div className="dropdown">
                                <Link className="btn dropdown-btn" to="/login">
                                    Login
                                </Link>
                            </div>
                            <div className="dropdown">
                                <Link className="btn dropdown-btn" to="/signup">
                                    Register
                                </Link>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                    {currentUser !== null && currentUser !== undefined ? (
                        <>
                            <div className="dropdown">
                                <button
                                    className="btn dropdown-toggle dropdown-btn"
                                    type="button"
                                    id="dropdownProfile"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {currentUser.email}
                                </button>
                                <div
                                    className="dropdown-menu dropdown-content"
                                    aria-labelledby="dropdownProfile"
                                >
                                    <Link to="/updateprofile">
                                        <button
                                            className="dropdown-item"
                                            type="button"
                                        >
                                            Update Profile
                                        </button>
                                    </Link>
                                    {admin === true && (
                                        <Link to="/admin">
                                            <button
                                                className="dropdown-item"
                                                type="button"
                                            >
                                                Admin Panel
                                            </button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                            <div className="dropdown">
                                <button
                                    className="btn dropdown-btn"
                                    onClick={handleLogout}
                                >
                                    LogOut
                                </button>
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </ul>
            </div>
        </nav>
    );
}
