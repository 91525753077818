import React from "react";
import { Button, Modal } from "react-bootstrap";

import Field from "../../formGroup/Field";
import Datetime from "../../formGroup/datetime";
import Number from "../../formGroup/mobile";
import Files from "../../formGroup/files";

import { db, storage } from "../../../firebase";
import { v4 } from "uuid";
import Utils from "../../authors/utilities";

class NewEvent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            eventID:
                this.props.data !== undefined ? this.props.data.eventID : "",
            eventTitle:
                this.props.data !== undefined ? this.props.data.eventTitle : "",
            eventDescription:
                this.props.data !== undefined
                    ? this.props.data.eventDescription
                    : "",
            eventDateAndTime:
                this.props.data !== undefined
                    ? this.props.data.eventDateAndTime
                    : "",
            eventPlace:
                this.props.data !== undefined ? this.props.data.eventPlace : "",
            speakers:
                this.props.data !== undefined ? this.props.data.speakers : "",
            streamLinks:
                this.props.data !== undefined
                    ? this.props.data.streamLinks
                    : "",
            coordinatorName:
                this.props.data !== undefined
                    ? this.props.data.coordinatorName
                    : "",
            code:
                this.props.data !== undefined
                    ? this.props.data.coordinatorMobile.split(" ")[0]
                    : "",
            coordinatorMobile:
                this.props.data !== undefined
                    ? this.props.data.coordinatorMobile.split(" ")[1]
                    : "",
            eventType:
                this.props.data !== undefined ? this.props.data.eventType : "",
            eventPosterFile:
                this.props.data !== undefined
                    ? this.props.data.eventPosterFile
                    : "",
            eventMOMFile:
                this.props.data !== undefined
                    ? this.props.data.eventMOMFile
                    : "",
            eventBrochureFile:
                this.props.data !== undefined
                    ? this.props.data.eventBrochureFile
                    : "",
            eventVisibility:
                this.props.data !== undefined
                    ? this.props.data.eventVisibility
                    : false,
            error: "",
            loading: false,
        };
        this.handleEventAddition = this.handleEventAddition.bind(this);
    }

    async handleEventAddition() {
        this.setState({ loading: true, error: "" });

        let eventPosterFileChanged = false,
            eventMOMFileChanged = false,
            eventBrochureFileChanged = false;

        if (this.props.update) {
            eventPosterFileChanged =
                this.state.eventPosterFile !== this.props.data.eventPosterFile;
            eventMOMFileChanged =
                this.state.eventMOMFile !== this.props.data.eventMOMFile;
            eventBrochureFileChanged =
                this.state.eventBrochureFile !==
                this.props.data.eventBrochureFile;
        }

        const requestSent = {
            eventID: this.state.eventID,
            eventTitle: this.state.eventTitle,
            eventDescription: this.state.eventDescription,
            eventDateAndTime: this.state.eventDateAndTime,
            eventPlace: this.state.eventPlace,
            speakers: this.state.speakers,
            streamLinks: this.state.streamLinks,
            coordinatorName: this.state.coordinatorName,
            coordinatorMobile: `${this.state.code} ${this.state.coordinatorMobile}`,
            eventType: this.state.eventType,
            eventPosterFile:
                (this.props.update && eventPosterFileChanged) ||
                !this.props.update
                    ? v4() +
                      Utils.getFileExtension(this.state.eventPosterFile.name)
                    : this.state.eventPosterFile,
            eventMOMFile:
                (this.props.update && eventMOMFileChanged) || !this.props.update
                    ? v4() +
                      Utils.getFileExtension(this.state.eventMOMFile.name)
                    : this.state.eventMOMFile,
            eventBrochureFile:
                (this.props.update && eventBrochureFileChanged) ||
                !this.props.update
                    ? v4() +
                      Utils.getFileExtension(this.state.eventBrochureFile.name)
                    : this.state.eventBrochureFile,
            eventVisibility: this.state.eventVisibility,
        };

        let eventPosterFile = new File(
            [this.state.eventPosterFile],
            requestSent.eventPosterFile
        );

        let eventMOMFile = new File(
            [this.state.eventMOMFile],
            requestSent.eventMOMFile
        );

        let eventBrochureFile = new File(
            [this.state.eventBrochureFile],
            requestSent.eventBrochureFile
        );

        try {
            if (this.props.update) {
                if (eventPosterFileChanged) {
                    try {
                        await storage
                            .ref()
                            .child(
                                `events/uploads/${this.props.data.eventPosterFile}`
                            )
                            .delete();
                    } catch (err) {
                        console.log(
                            "Error: unable to delete previous file: ",
                            err
                        );
                        this.setState({
                            error: "Some error occurred please check your connection",
                        });
                    }
                }
                if (eventMOMFileChanged) {
                    try {
                        await storage
                            .ref()
                            .child(
                                `events/uploads/${this.props.data.eventMOMFile}`
                            )
                            .delete();
                    } catch (err) {
                        console.log(
                            "Error: unable to delete previous file: ",
                            err
                        );
                        this.setState({
                            error: "Some error occurred please check your connection",
                        });
                    }
                }
                if (eventBrochureFileChanged) {
                    try {
                        await storage
                            .ref()
                            .child(
                                `events/uploads/${this.props.data.eventBrochureFile}`
                            )
                            .delete();
                    } catch (err) {
                        console.log(
                            "Error: unable to delete previous file: ",
                            err
                        );
                        this.setState({
                            error: "Some error occurred please check your connection",
                        });
                    }
                }
            }
            if (
                (this.props.update && eventBrochureFileChanged) ||
                !this.props.update
            ) {
                await storage
                    .ref()
                    .child(`events/uploads/${eventBrochureFile.name}`)
                    .put(eventBrochureFile, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(eventBrochureFile.name)
                        ),
                    });
            }
            if (
                (this.props.update && eventPosterFileChanged) ||
                !this.props.update
            ) {
                await storage
                    .ref()
                    .child(`events/uploads/${eventPosterFile.name}`)
                    .put(eventPosterFile);
            }
            if (
                (this.props.update && eventMOMFileChanged) ||
                !this.props.update
            ) {
                await storage
                    .ref()
                    .child(`events/uploads/${eventMOMFile.name}`)
                    .put(eventMOMFile, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(eventMOMFile.name)
                        ),
                    });
            }
            if (this.props.update) {
                await db
                    .collection("events")
                    .doc(this.props.data.id)
                    .set(requestSent);
            } else {
                await db.collection("events").add(requestSent);
            }
            await this.props.updateComponentData();
            this.props.hide();
        } catch (err) {
            console.log("Error occured: Unable to save event info ", err);
            this.setState({
                loading: false,
                error: "Error occured unable to save event info, please try again",
            });
        }

        this.setState({
            loading: false,
            error: "",
        });
    }

    render() {
        return (
            <>
                <Modal
                    show={this.props.show}
                    onHide={this.props.hide}
                    centered
                    scrollable
                    size="xl"
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Add New Event</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <Field
                                cols="6"
                                validate={false}
                                onData={(eventID) => this.setState({ eventID })}
                                value={this.state.eventID}
                                label="Event ID "
                                type="text"
                                validations={[]}
                                onChange={(x) => x}
                            />
                            <Field
                                cols="6"
                                validate={false}
                                onData={(eventTitle) =>
                                    this.setState({ eventTitle })
                                }
                                value={this.state.eventTitle}
                                label="Event Title "
                                type="text"
                                validations={[]}
                                onChange={(x) => x}
                            />
                            <Field
                                cols="6"
                                validate={false}
                                onData={(eventDescription) =>
                                    this.setState({ eventDescription })
                                }
                                value={this.state.eventDescription}
                                label="Event Description "
                                type="textarea"
                                validations={[]}
                                onChange={(x) => x}
                            />
                            <Datetime
                                label="Event Date and Time"
                                cols="6"
                                datetime={this.state.eventDateAndTime}
                                onData={(eventDateAndTime) =>
                                    this.setState({ eventDateAndTime })
                                }
                            />
                            <Field
                                cols="6"
                                validate={false}
                                onData={(eventPlace) =>
                                    this.setState({ eventPlace })
                                }
                                value={this.state.eventPlace}
                                label="Event Place "
                                type="text"
                                validations={[]}
                                onChange={(x) => x}
                            />
                            <Field
                                cols="6"
                                validate={false}
                                onData={(speakers) =>
                                    this.setState({ speakers })
                                }
                                value={this.state.speakers}
                                label="Speakers "
                                type="textarea"
                                validations={[]}
                                onChange={(x) => x}
                            />
                            <Field
                                cols="6"
                                validate={false}
                                onData={(streamLinks) =>
                                    this.setState({ streamLinks })
                                }
                                value={this.state.streamLinks}
                                label="Stream Links "
                                type="textarea"
                                validations={[]}
                                onChange={(x) => x}
                            />
                            <Field
                                cols="6"
                                validate={false}
                                onData={(coordinatorName) =>
                                    this.setState({ coordinatorName })
                                }
                                value={this.state.coordinatorName}
                                label="Coordinator Name "
                                type="text"
                                validations={[]}
                                onChange={(x) => x}
                            />
                            <Number
                                onNumber={(coordinatorMobile) =>
                                    this.setState({ coordinatorMobile })
                                }
                                onCode={(code) => this.setState({ code })}
                                cols="6"
                                validate={false}
                                dataNumber={this.state.coordinatorMobile}
                                dataCode={this.state.code}
                                emitError={(x) => x}
                            />
                            <Field
                                cols="6"
                                validate={false}
                                onData={(eventType) =>
                                    this.setState({ eventType })
                                }
                                value={this.state.eventType}
                                label="Event Type"
                                type="select"
                                options={[
                                    "Conference",
                                    "Symposium",
                                    "Workshop",
                                    "Seminar",
                                    "Webinar",
                                    "Poster presentation",
                                    "General Meeting",
                                    "Research Meet",
                                    "Other",
                                ]}
                                validations={[]}
                                onChange={(x) => x}
                            />
                            <Files
                                label="Event Cover Image"
                                onData={(eventPosterFile) =>
                                    this.setState({ eventPosterFile })
                                }
                            />
                            <Files
                                label="Event MOM (pdf)"
                                onData={(eventMOMFile) =>
                                    this.setState({ eventMOMFile })
                                }
                            />
                            <Files
                                label="Event Brochure"
                                onData={(eventBrochureFile) =>
                                    this.setState({ eventBrochureFile })
                                }
                            />
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={this.props.hide}>
                            Close
                        </Button>
                        <Button
                            variant="dark"
                            onClick={this.handleEventAddition}
                            disabled={this.state.loading}
                        >
                            {this.state.loading ? (
                                <i className="fa fa-spinner fa-spin fa-1x" />
                            ) : (
                                <span>Submit</span>
                            )}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default NewEvent;
