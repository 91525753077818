import React, { Component } from "react";
import TableRow from "./tableRow";

export class table extends Component {
    constructor(props) {
        super(props);

        this.state = {
            search: "",
            arrayJournalRelease: this.props.arrayJournalRelease,
        };
    }

    render() {
        return (
            <div className="container">
                <div className="row justify-content-end">
                    <div className="col-4 form-group container m-3">
                        <div className="input-group">
                            <span
                                className="input-group-text"
                                id="basic-addon1"
                            >
                                <i
                                    className="fa fa-search"
                                    aria-hidden="true"
                                />
                            </span>
                            <input
                                className="form-control"
                                placeholder="Type to search..."
                                onChange={(event) =>
                                    this.setState({
                                        search: event.target.value,
                                    })
                                }
                            />
                        </div>
                    </div>
                </div>
                <table className="table table-hover table-responsive-sm">
                    <thead>
                        <tr>
                            <th scope="col">Title</th>
                            <th scope="col">Author</th>
                            <th scope="col">Submitted On</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.array.map((data) => {
                            if (
                                (this.props.type === "VERIFIED" &&
                                    data.status === "VERIFIED") ||
                                (this.props.type === "NOT_VERIFIED" &&
                                    data.status === "NOT_VERIFIED")
                            )
                                return (
                                    <TableRow
                                        filter={this.state.search.trim()}
                                        showStatus={this.props.type === "ALL"}
                                        data={data}
                                        arrayJournalRelease={
                                            this.state.arrayJournalRelease
                                        }
                                        role={this.props.type}
                                        update={this.props.update}
                                    />
                                );
                            return null;
                        })}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default table;
