import React from "react";
import { DeleteModal, EditModal, VerifyModal, InfoModal } from "./modals";

export class tableRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableClass: "table-danger",
            showEdit: false,
            showDelete: false,
            showVerify: false,
            showInfo: false,
        };
    }

    customDateTime = (datetime) => {
        const DateTime = new Date(datetime);
        return DateTime.toLocaleString();
    };

    render() {
        let show = "false";
        const filter = this.props.filter.toUpperCase();

        Object.keys(this.props.data).forEach((key) => {
            if (
                key !== "status" ||
                key !== "_id" ||
                key !== "__v" ||
                key !== "id"
            ) {
                let value = this.props.data[key];
                if (typeof value === "number") {
                    value = value.toString();
                }

                if (value !== null && typeof value === "string") {
                    if (value.toUpperCase().indexOf(filter) > -1) {
                        show = true;
                    }
                }
            }
        });
        if (show === true) {
            return (
                <>
                    <tr
                        className={
                            this.props.data.status === "VERIFIED"
                                ? "table-success"
                                : "table-danger"
                        }
                    >
                        <td className={this.props.data.language}>
                            {this.props.data.title}
                        </td>
                        <td className={this.props.data.language}>
                            {this.props.data.name}
                        </td>
                        {this.props.showStatus && (
                            <td>
                                {this.props.data.status === "NOT_VERIFIED" && (
                                    <>Pending</>
                                )}
                                {this.props.data.status === "VERIFIED" && (
                                    <>Verified</>
                                )}
                            </td>
                        )}
                        <td>
                            {this.props.data.timestamp
                                ? `${this.customDateTime(
                                      this.props.data.timestamp
                                  )}`
                                : "No timestamp"}
                        </td>
                        <td>
                            <div className="row">
                                <span
                                    style={{ color: "Blue" }}
                                    className="col"
                                    title="Edit"
                                    onClick={() =>
                                        this.setState({ showEdit: true })
                                    }
                                >
                                    <i
                                        title="Edit"
                                        className="tooltip-test fa fa-pencil"
                                        aria-hidden="true"
                                    ></i>
                                </span>
                                {this.props.data.status === "NOT_VERIFIED" &&
                                    this.props.role === "NOT_VERIFIED" && (
                                        <span
                                            style={{ color: "Green" }}
                                            className="col"
                                            onClick={() =>
                                                this.setState({
                                                    showVerify: true,
                                                })
                                            }
                                        >
                                            <i
                                                className="tooltip-test fa fa-check"
                                                title="Verify"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    )}
                                {this.props.data.status === "VERIFIED" &&
                                    this.props.role === "VERIFIED" && (
                                        <span
                                            style={{ color: "Red" }}
                                            className="col"
                                            onClick={() =>
                                                this.setState({
                                                    showUnVerify: true,
                                                })
                                            }
                                        >
                                            <i
                                                className="tooltip-test fa fa-times"
                                                title="UnVerify"
                                                aria-hidden="true"
                                            />
                                        </span>
                                    )}
                                <span
                                    className="col"
                                    onClick={() =>
                                        this.setState({ showInfo: true })
                                    }
                                >
                                    <i
                                        className="tooltip-test fa fa-eye"
                                        title="Information"
                                        aria-hidden="true"
                                    />
                                </span>
                                <span
                                    style={{ color: "Red" }}
                                    className="col"
                                    onClick={() =>
                                        this.setState({ showDelete: true })
                                    }
                                >
                                    <i
                                        className="tooltip-test fa fa-trash"
                                        title="Delete"
                                        aria-hidden="true"
                                    />
                                </span>
                            </div>
                        </td>
                    </tr>
                    {this.state.showEdit ? (
                        <EditModal
                            show={this.state.showEdit}
                            hide={() => this.setState({ showEdit: false })}
                            data={this.props.data}
                            arrayJournalRelease={this.props.arrayJournalRelease}
                            type={this.props.type}
                            update={this.props.update}
                        />
                    ) : (
                        <></>
                    )}
                    {this.state.showDelete ? (
                        <DeleteModal
                            show={this.state.showDelete}
                            hide={() => this.setState({ showDelete: false })}
                            data={this.props.data}
                            type={this.props.type}
                            update={this.props.update}
                        />
                    ) : (
                        <></>
                    )}
                    {this.state.showVerify ? (
                        <VerifyModal
                            show={this.state.showVerify}
                            hide={() => this.setState({ showVerify: false })}
                            data={this.props.data}
                            type={this.props.type}
                            update={this.props.update}
                            verify={true}
                        />
                    ) : (
                        <></>
                    )}
                    {this.state.showUnVerify ? (
                        <VerifyModal
                            show={this.state.showUnVerify}
                            hide={() => this.setState({ showUnVerify: false })}
                            data={this.props.data}
                            type={this.props.type}
                            update={this.props.update}
                            verify={false}
                        />
                    ) : (
                        <></>
                    )}
                    {this.state.showInfo ? (
                        <InfoModal
                            show={this.state.showInfo}
                            hide={() => this.setState({ showInfo: false })}
                            data={this.props.data}
                            type={this.props.type}
                        />
                    ) : (
                        <></>
                    )}
                </>
            );
        }
        return null;
    }
}

export default tableRow;
