import React, { Component } from "react";
import { Button, Modal } from "react-bootstrap";
import { db, storage } from "../../../../firebase";
import Files from "../../../formGroup/files";

import Number from "../../../formGroup/mobile";
import Languages from "../../../formGroup/languages";

import Field from "../../../formGroup/Field";
import designations from "../../../formGroup/utilities/designations";
import countries from "../../../formGroup/utilities/countries";
import { v4 } from "uuid";
import Utils from "../../../authors/utilities";

export class EditModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.data.name,
            address: this.props.data.address,
            city: this.props.data.city,
            PINCode: this.props.data.PINCode,
            state: this.props.data.state,
            country: this.props.data.country,
            code:
                this.props.data.mobile !== ""
                    ? this.props.data.mobile.split(" ")[0]
                    : "",
            number:
                this.props.data.mobile !== ""
                    ? this.props.data.mobile.split(" ")[1]
                    : "",
            alternateCode1:
                this.props.data.alternateMobile1 !== undefined &&
                this.props.data.alternateMobile1 !== ""
                    ? this.props.data.alternateMobile1.split(" ")[0]
                    : "",
            alternateNumber1:
                this.props.data.alternateMobile1 !== undefined &&
                this.props.data.alternateMobile1 !== ""
                    ? this.props.data.alternateMobile1.split(" ")[1]
                    : "",
            alternateCode2:
                this.props.data.alternateMobile2 !== undefined &&
                this.props.data.alternateMobile2 !== ""
                    ? this.props.data.alternateMobile2.split(" ")[0]
                    : "",
            alternateNumber2:
                this.props.data.alternateMobile2 !== undefined &&
                this.props.data.alternateMobile2 !== ""
                    ? this.props.data.alternateMobile2.split(" ")[1]
                    : "",
            email: this.props.data.email,
            designation: this.props.data.designation,

            /// for advisory but not volunteer
            affiliation: this.props.data.affiliation,

            // additional properties for others except volunteers
            alternateEmail1:
                this.props.data.alternateEmail1 !== undefined
                    ? this.props.data.alternateEmail1
                    : "",
            alternateEmail2:
                this.props.data.alternateEmail1 !== undefined
                    ? this.props.data.alternateEmail2
                    : "",
            language: this.props.data.language,
            qualifications: this.props.data.qualifications,
            frequency: this.props.data.frequency,
            experience: this.props.data.experience,
            researchArea: this.props.data.researchArea,
            additionalInformation: this.props.data.additionalInformation,
            resumeFile: this.props.data.resumeFile,
            resumeVisibility: this.props.data.resumeVisibility,
            priorityID:
                this.props.data.priorityID !== undefined
                    ? this.props.data.priorityID
                    : "",
            loading: false,
            error: "",
        };
    }

    async handleVolunteerEditSubmit() {
        const requestSent = {
            name: this.state.name,
            address: this.state.address,
            city: this.state.city,
            PINCode: this.state.PINCode,
            state: this.state.state,
            country: this.state.country,
            email: this.state.email,
            alternateEmail1: this.state.alternateEmail1,
            alternateEmail2: this.state.alternateEmail2,
            mobile: `${this.state.code} ${this.state.number}`,
            alternateMobile1: `${this.state.alternateCode1} ${this.state.alternateNumber1}`,
            alternateMobile2: `${this.state.alternateCode2} ${this.state.alternateNumber2}`,
            designation: this.state.designation,
            role: this.props.data.role,
            isVerified: this.props.data.isVerified,
            priorityID: this.state.priorityID,
        };

        try {
            await db
                .collection("users")
                .doc(this.props.data.id)
                .set(requestSent);
        } catch (err) {
            console.log("Error: unable to update record information:", err);
            this.setState({
                error: "Some error occurred please check your connection",
            });
        }
    }

    async handleOtherEditSubmit() {
        const resumeFileChanged =
            this.state.resumeFile !== this.props.data.resumeFile;

        const requestSent = {
            name: this.state.name,
            address: this.state.address,
            city: this.state.city,
            PINCode: this.state.PINCode,
            state: this.state.state,
            country: this.state.country,
            email: this.state.email,
            alternateEmail1: this.state.alternateEmail1,
            alternateEmail2: this.state.alternateEmail2,
            mobile: `${this.state.code} ${this.state.number}`,
            alternateMobile1: `${this.state.alternateCode1} ${this.state.alternateNumber1}`,
            alternateMobile2: `${this.state.alternateCode2} ${this.state.alternateNumber2}`,
            language: this.state.language,
            designation: this.state.designation,
            qualifications: this.state.qualifications,
            frequency: this.state.frequency,
            experience: this.state.experience,
            affiliation: this.state.affiliation,
            researchArea: this.state.researchArea,
            additionalInformation: this.state.additionalInformation,
            resumeFile: resumeFileChanged
                ? v4() + Utils.getFileExtension(this.state.resumeFile.name)
                : this.state.resumeFile,
            resumeVisibility: this.state.resumeVisibility,
            role: this.props.data.role,
            isVerified: this.props.data.isVerified,
            priorityID: this.state.priorityID,
        };

        if (resumeFileChanged) {
            try {
                let resumeFile = new File(
                    [this.state.resumeFile],
                    requestSent.resumeFile
                );
                await storage
                    .ref()
                    .child(`users/resumes/${resumeFile.name}`)
                    .put(resumeFile, {
                        contentType: Utils.getFileContentType(
                            Utils.getFileExtension(resumeFile.name)
                        ),
                    });
            } catch (err) {
                console.log("Error: unable to upload new file: ", err);
                this.setState({
                    error: "Some error occurred please check your connection",
                });
            }

            try {
                await storage
                    .ref()
                    .child(`users/resumes/${this.props.data.resumeFile}`)
                    .delete();
            } catch (err) {
                console.log("Error: unable to delete previous file: ", err);
                this.setState({
                    error: "Some error occurred please check your connection",
                });
            }
        }
        try {
            await db
                .collection("users")
                .doc(this.props.data.id)
                .set(requestSent);
        } catch (err) {
            console.log("Error: unable to update record information:", err);
            this.setState({
                error: "Some error occurred please check your connection",
            });
        }
    }

    async handleAdvisoryEditSubmit() {
        const requestSent = {
            name: this.state.name,
            address: this.state.address,
            city: this.state.city,
            PINCode: this.state.PINCode,
            state: this.state.state,
            country: this.state.country,
            email: this.state.email,
            alternateEmail1: this.state.alternateEmail1,
            alternateEmail2: this.state.alternateEmail2,
            mobile: `${this.state.code} ${this.state.number}`,
            alternateMobile1: `${this.state.alternateCode1} ${this.state.alternateNumber1}`,
            alternateMobile2: `${this.state.alternateCode2} ${this.state.alternateNumber2}`,
            affiliation: this.state.affiliation,
            role: this.props.data.role,
            isVerified: this.props.data.isVerified,
            priorityID: this.state.priorityID,
        };

        try {
            await db
                .collection("users")
                .doc(this.props.data.id)
                .set(requestSent);
        } catch (err) {
            console.log("Error: unable to update record information:", err);
            this.setState({
                error: "Some error occurred please check your connection",
            });
        }
    }

    handleEditSubmit = async () => {
        this.setState({ loading: true, error: "" });

        if (this.props.data.role === "volunteer") {
            await this.handleVolunteerEditSubmit();
        } else if (this.props.data.role === "advisory-member") {
            await this.handleAdvisoryEditSubmit();
        } else {
            await this.handleOtherEditSubmit();
        }

        this.setState({ loading: false });
        this.props.updateComponentData(this.props.data.role);
        this.props.hide();
    };

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hide}
                centered
                scrollable
                size="xl"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Edit {this.props.data.name}'s details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <Field
                            cols="6"
                            validate={false}
                            onData={(priorityID) =>
                                this.setState({ priorityID })
                            }
                            onChange={(x) => x}
                            value={this.state.priorityID}
                            label="Display Priority"
                            validations={[]}
                            type="text"
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(name) => this.setState({ name })}
                            onChange={(x) => x}
                            value={this.state.name}
                            label="Name"
                            validations={[]}
                            type="text"
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(address) => this.setState({ address })}
                            onChange={(x) => x}
                            value={this.state.address}
                            label="Address"
                            validations={[]}
                            type="text"
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(city) => this.setState({ city })}
                            onChange={(x) => x}
                            value={this.state.city}
                            label="City"
                            validations={[]}
                            type="text"
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(PINCode) => this.setState({ PINCode })}
                            onChange={(x) => x}
                            value={this.state.PINCode}
                            label="PIN Code"
                            validations={[]}
                            type="text"
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(state) => this.setState({ state })}
                            onChange={(x) => x}
                            value={this.state.state}
                            label="State"
                            validations={[]}
                            type="text"
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(country) => this.setState({ country })}
                            onChange={(x) => x}
                            label="Country"
                            validations={[]}
                            type="select"
                            options={countries}
                            value={this.state.country}
                        />
                        <Number
                            onNumber={(number) => this.setState({ number })}
                            onCode={(code) => this.setState({ code })}
                            cols="6"
                            validate={this.state.validate}
                            dataNumber={this.state.number}
                            dataCode={this.state.code}
                            emitError={(x) => x}
                        />
                        <Number
                            onNumber={(alternateNumber1) =>
                                this.setState({ alternateNumber1 })
                            }
                            onCode={(alternateCode1) =>
                                this.setState({ alternateCode1 })
                            }
                            cols="6"
                            validate={this.state.validate}
                            dataNumber={this.state.alternateNumber1}
                            dataCode={this.state.alternateCode1}
                            emitError={(x) => x}
                        />
                        <Number
                            onNumber={(alternateNumber2) =>
                                this.setState({ alternateNumber2 })
                            }
                            onCode={(alternateCode2) =>
                                this.setState({ alternateCode2 })
                            }
                            cols="6"
                            validate={this.state.validate}
                            dataNumber={this.state.alternateNumber2}
                            dataCode={this.state.alternateCode2}
                            emitError={(x) => x}
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(email) => this.setState({ email })}
                            onChange={(x) => x}
                            value={this.state.email}
                            label="Email Address"
                            validations={[]}
                            type="email"
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(alternateEmail1) =>
                                this.setState({ alternateEmail1 })
                            }
                            onChange={(x) => x}
                            value={this.state.alternateEmail1}
                            label="Alternate Email 1"
                            validations={[]}
                            type="email"
                        />
                        <Field
                            cols="6"
                            validate={false}
                            onData={(alternateEmail2) =>
                                this.setState({ alternateEmail2 })
                            }
                            onChange={(x) => x}
                            value={this.state.alternateEmail2}
                            label="Alternate Email 2"
                            validations={[]}
                            type="email"
                        />
                        {this.props.data.role !== "advisory-member" && (
                            <Field
                                cols="6"
                                validate={false}
                                onData={(designation) =>
                                    this.setState({ designation })
                                }
                                onChange={(x) => x}
                                label="Designation"
                                validations={[]}
                                type="select"
                                options={designations}
                                value={this.state.designation}
                            />
                        )}
                        {this.props.data.role !== "volunteer" && (
                            <Field
                                cols="6"
                                validate={false}
                                onData={(affiliation) =>
                                    this.setState({ affiliation })
                                }
                                onChange={(x) => x}
                                value={this.state.affiliation}
                                label="Affiliation (University/College/Company)"
                                validations={[]}
                                type="text"
                            />
                        )}
                        {this.props.data.role !== "volunteer" &&
                            this.props.data.role !== "advisory-member" && (
                                <>
                                    <Languages
                                        cols="6"
                                        type={`${this.props.type}ship`}
                                        onData={(language) =>
                                            this.setState({ language })
                                        }
                                        validate={this.state.validate}
                                        data={this.state.language}
                                    />

                                    <Field
                                        cols="6"
                                        validate={false}
                                        onData={(qualifications) =>
                                            this.setState({ qualifications })
                                        }
                                        onChange={(x) => x}
                                        value={this.state.qualifications}
                                        label="Qualifications"
                                        validations={[]}
                                        type="text"
                                    />
                                    <Field
                                        cols="6"
                                        validate={false}
                                        onData={(frequency) =>
                                            this.setState({ frequency })
                                        }
                                        onChange={(x) => x}
                                        label="Frequency"
                                        validations={[]}
                                        type="select"
                                        options={[
                                            "1 Article per year",
                                            "2 Articles per year",
                                            "3 Articles per year",
                                        ]}
                                        value={this.state.frequency}
                                    />
                                    <Field
                                        cols="6"
                                        validate={false}
                                        onData={(experience) =>
                                            this.setState({ experience })
                                        }
                                        onChange={(x) => x}
                                        value={this.state.experience}
                                        label="Total Academic Experience (in years)"
                                        validations={[]}
                                        type="text"
                                    />
                                    <Field
                                        cols="6"
                                        validate={false}
                                        onData={(researchArea) =>
                                            this.setState({ researchArea })
                                        }
                                        onChange={(x) => x}
                                        value={this.state.researchArea}
                                        label="Research Area"
                                        validations={[]}
                                        type="text"
                                    />
                                    <div className="ml-3">
                                        <input
                                            type="checkbox"
                                            onChange={(e) =>
                                                this.setState({
                                                    resumeVisibility:
                                                        e.target.checked,
                                                })
                                            }
                                            checked={
                                                this.state.resumeVisibility
                                            }
                                        />
                                        <label className="ml-2">
                                            Resume Visibility
                                        </label>
                                    </div>
                                    <Field
                                        cols="12"
                                        validate={false}
                                        onData={(additionalInformation) =>
                                            this.setState({
                                                additionalInformation,
                                            })
                                        }
                                        onChange={(x) => x}
                                        value={this.state.additionalInformation}
                                        label="Additional Information"
                                        validations={[]}
                                        type="textarea"
                                    />
                                    <Files
                                        label="Resume / CV *"
                                        onData={(resumeFile) =>
                                            this.setState({ resumeFile })
                                        }
                                    />
                                </>
                            )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.hide}>
                        Close
                    </Button>
                    {this.state.loading ? (
                        <button class="btn btn-primary" type="button" disabled>
                            <span
                                class="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </button>
                    ) : (
                        <Button
                            variant="primary"
                            onClick={this.handleEditSubmit}
                        >
                            Update
                        </Button>
                    )}
                </Modal.Footer>
            </Modal>
        );
    }
}

export class DeleteModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: "",
        };
    }

    async deleteEventHandler() {
        this.setState({ loading: true, error: "" });
        try {
            if (this.props.data.resumeFile) {
                await storage
                    .ref()
                    .child(`users/resumes/${this.props.data.resumeFile}`)
                    .delete();
            }
            await db.collection("users").doc(this.props.data.id).delete();
            this.setState({
                loading: false,
                error: "",
            });
            this.props.updateComponentData(this.props.data.role);
            this.props.hide();
        } catch (err) {
            console.log(err);
            await this.setState({
                loading: false,
                error: "Please check your connection, unable to delete record",
            });
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hide}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        Delete {this.props.data.name}'s details
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-danger" role="alert">
                        {this.state.error}
                    </div>
                    Are you sure you want to delete this?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.hide}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={() => this.deleteEventHandler()}
                        disabled={this.state.loading}
                    >
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export class VerifyModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: "",
        };
    }

    verifyEventHandler = async () => {
        this.setState({ loading: true, error: "" });
        try {
            let requestSent = this.props.data;
            requestSent.isVerified = !this.props.data.isVerified;
            await db
                .collection("users")
                .doc(this.props.data.id)
                .set(requestSent);
            this.setState({
                loading: false,
                error: "",
            });
            this.props.updateComponentData(this.props.data.role);
            this.props.hide();
        } catch (err) {
            console.log(err);
            await this.setState({
                loading: false,
                error: "Please check your connection, unable to complete operation",
            });
        }
    };

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hide}
                centered
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Verify {this.props.data.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-danger" role="alert">
                        {this.state.error}
                    </div>
                    Are you sure you want to very this person?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.hide}>
                        Cancel
                    </Button>
                    <Button
                        disabled={this.state.loading}
                        variant="success"
                        onClick={() => this.verifyEventHandler()}
                    >
                        Verify
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export class InfoModal extends Component {
    capitalize = (word) =>
        typeof word !== "string"
            ? ""
            : word.charAt(0).toUpperCase() + word.slice(1);

    constructor(props) {
        super(props);
        this.state = {
            resumeFileURL: "",
        };
    }

    async componentDidMount() {
        if (this.props.data.resumeFile) {
            const resumeFileURL = await storage
                .ref()
                .child(`users/resumes/${this.props.data.resumeFile}`)
                .getDownloadURL();
            this.setState({ resumeFileURL });
        }
    }

    render() {
        return (
            <Modal
                show={this.props.show}
                onHide={this.props.hide}
                centered
                scrollable
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.data.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Attribute</th>
                                <th scope="col">Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(this.props.data).map((key) => {
                                if (key === "reviewerShipFrequency")
                                    return (
                                        <tr>
                                            <td>Reviewership Frequency</td>
                                            <td>{this.props.data[key]}</td>
                                        </tr>
                                    );
                                else if (key === "researchArea")
                                    return (
                                        <tr>
                                            <td>Research Area</td>
                                            <td>{this.props.data[key]}</td>
                                        </tr>
                                    );
                                else if (key === "additionalInformation")
                                    return (
                                        <tr>
                                            <td>Additional Information</td>
                                            <td>{this.props.data[key]}</td>
                                        </tr>
                                    );
                                else if (key === "resumeFile")
                                    return (
                                        <tr>
                                            <td>Resume File</td>
                                            <td>
                                                <a
                                                    href={
                                                        this.state.resumeFileURL
                                                    }
                                                    target="blank"
                                                >
                                                    view resume
                                                </a>
                                            </td>
                                        </tr>
                                    );
                                else if (key === "_id" || key === "__v")
                                    return null;

                                return (
                                    <tr>
                                        <td>{this.capitalize(key)}</td>
                                        <td>{this.props.data[key]}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Modal.Body>
            </Modal>
        );
    }
}
