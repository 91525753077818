import React from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";

import Number from "../formGroup/mobile";
import Languages from "../formGroup/languages";
import Field from "../formGroup/Field";
import Files from "../formGroup/files";
import axios from "axios";
import { appConfig } from "../../config";

import validators from "./utilities/validations";
import designations from "./utilities/designations";
import countries from "../formGroup/utilities/countries";

import { db, storage } from "../../firebase";
import { v4 } from "uuid";

import Utils from "../authors/utilities";

export class ApplicationForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: "Editorial Application",
            currentMode: "editorial",
            role: "editorial",
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            state: "",
            country: "",
            PINCode: "",
            code: "+91",
            number: "",
            designation: "",
            email: "",
            qualifications: "",
            language: "",
            languageArabic: false,
            languageEnglish: false,
            languageUrdu: false,
            languagePersian: false,
            languageHindi: false,
            frequency: "",
            experience: "",
            affiliation: "",
            researchArea: "",
            additionalInformation: "",
            errorfirstName: true,
            errorlastName: true,
            erroraddress: true,
            errorcity: true,
            errorcountry: true,
            errormobile: true,
            errorstate: true,
            errorPINCode: true,
            errordesignation: true,
            erroremail: true,
            errorqualifications: true,
            errorfrequency: true,
            errorexperience: true,
            erroraffiliation: true,
            errorresearchArea: true,
            validate: false,
            disable: false,
            showError: false,
            showSpinner: false,
            resumeFile: undefined,
            showResumeFileError: false,
            termsAndConditionCheck: false,
        };
    }

    async componentDidUpdate() {
        if (
            this.props.match.params.applicationMode !== this.state.currentMode
        ) {
            switch (this.props.match.params.applicationMode) {
                case "advisory-application":
                    this.setState({
                        title: "Advisory Membership Application",
                        role: "advisory-member",
                        currentMode: "advisory-application",
                    });
                    break;
                case "associate-editorial":
                    this.setState({
                        title: "Associate Editorial Application",
                        role: "associate-editorial",
                        currentMode: "associate-editorial",
                    });
                    break;
                case "editorial":
                    this.setState({
                        title: "Editorial Application",
                        role: "editorial",
                        currentMode: "editorial",
                    });
                    break;
                case "reviewer":
                    this.setState({
                        title: "Reviewer Application",
                        role: "reviewer",
                        currentMode: "reviewer",
                    });
                    break;
                default:
                    break;
            }
        }
    }

    async componentDidMount() {
        this.componentDidUpdate();
    }

    async handleAdvisoryApplicationSubmit() {
        await this.setState({ validate: true, showError: false });
        if (!this.errorHandler()) return;
        await this.setState({ disable: true, showSpinner: true });
        const requestSent = {
            name: validators.name(this.state.firstName, this.state.lastName),
            address: this.state.address,
            city: this.state.city,
            PINCode: this.state.PINCode,
            state: this.state.state,
            country: this.state.country,
            email: validators.email(this.state.email),
            alternateEmail1: "",
            alternateEmail2: "",
            designation: validators.removeSpaces(this.state.designation),
            mobile: `${this.state.code} ${this.state.number}`,
            alternateMobile1: "",
            alternateMobile2: "",
            affiliation: validators.removeSpaces(this.state.affiliation),
            role: this.state.role,
            isVerified: false,
        };
        try {
            await db.collection("users").add(requestSent);
        } catch (err) {
            console.log("Error: Unable to send avisory application: ", err);
        }
        /// utilising email notification only in production mode
        if (
            window.location.hostname !== "localhost" &&
            window.location.hostname !== "127.0.0.1"
        ) {
            try {
                const mailOptions = {
                    subject: `New ${this.state.currentMode} Application from ${requestSent.name}`,
                    text: `Hey Admin, greeting from Developers Kanzuliman, ${requestSent.name} has submiited a new ${this.state.currentMode} Application, kindly review the application and provide suitable response.
Name: ${requestSent.name}
Email: ${requestSent.email}
Contact: ${requestSent.mobile}



Regards,
Developers Kanzuliman
                `,
                    html: "",
                };
                await axios.post(
                    `${appConfig.emailEndpoint}sendEmail`,
                    mailOptions
                );
            } catch (err) {
                console.log("MailError: Unabel to send email", err);
            }
        }
        this.props.history.push("/join/success");
    }

    async handleApplicationOtherThanAdvisory() {
        await this.setState({ validate: true, showError: false });
        if (!this.errorHandler()) return;
        this.setState({
            showResumeFileError: false,
        });

        await this.setState({ disable: true, showSpinner: true });

        const requestSent = {
            name: validators.name(this.state.firstName, this.state.lastName),
            address: this.state.address,
            city: this.state.city,
            PINCode: this.state.PINCode,
            state: this.state.state,
            country: this.state.country,
            email: validators.email(this.state.email),
            alternateEmail1: "",
            alternateEmail2: "",
            designation: validators.removeSpaces(this.state.designation),
            mobile: `${this.state.code} ${this.state.number}`,
            alternateMobile1: "",
            alternateMobile2: "",
            qualifications: validators.removeSpaces(this.state.qualifications),
            frequency: this.state.frequency,
            experience: validators.removeSpaces(this.state.experience),
            affiliation: validators.removeSpaces(this.state.affiliation),
            researchArea: validators.removeSpaces(this.state.researchArea),
            additionalInformation: validators.removeSpaces(
                this.state.additionalInformation
            ),
            language: this.state.language,
            resumeFile:
                this.state.resumeFile !== undefined
                    ? v4() + Utils.getFileExtension(this.state.resumeFile.name)
                    : "",
            role: this.state.role,
            resumeVisibility: false,
            isVerified: false,
        };

        try {
            let resumeFile = new File(
                [this.state.resumeFile],
                requestSent.resumeFile
            );

            await storage
                .ref()
                .child(`users/resumes/${resumeFile.name}`)
                .put(resumeFile, {
                    contentType: Utils.getFileContentType(
                        Utils.getFileExtension(resumeFile.name)
                    ),
                });

            await db.collection("users").add(requestSent);
        } catch (err) {
            await this.setState({
                disable: false,
                showError: true,
                showSpinner: false,
            });
        }
        /// utilising email notification only in production mode
        if (
            window.location.hostname !== "localhost" &&
            window.location.hostname !== "127.0.0.1"
        ) {
            try {
                const mailOptions = {
                    subject: `New ${this.state.currentMode} Application from ${requestSent.name}`,
                    text: `Hey Admin, greeting from Developers Kanzuliman, ${requestSent.name} has submiited a new ${this.state.currentMode} Application, kindly review the application and provide suitable response.
        Name: ${requestSent.name}
        Email: ${requestSent.email}
        Contact: ${requestSent.mobile}
        
        
        
        Regards,
        Developers Kanzuliman
                        `,
                    html: "",
                };
                await axios.post(
                    `${appConfig.emailEndpoint}sendEmail`,
                    mailOptions
                );
            } catch (err) {
                console.log("MailError: Unabel to send email", err);
            }
        }
        this.props.history.push("/join/success");
    }

    handleApplicationFromSubmit = async (event) => {
        event.preventDefault();
        if (this.state.role === "advisory-member") {
            await this.handleAdvisoryApplicationSubmit();
        } else {
            await this.handleApplicationOtherThanAdvisory();
        }
    };

    errorHandler = () => {
        if (this.state.errorfirstName) return false;
        if (this.state.errorlastName) return false;
        if (this.state.erroraddress) return false;
        if (this.state.errorcity) return false;
        if (this.state.errorcountry) return false;
        if (this.state.errormobile) return false;
        if (this.state.erroremail) return false;
        if (this.state.erroraffiliation) return false;

        if (this.state.role !== "advisory-member") {
            if (!this.state.language) return false;
            if (this.state.errordesignation) return false;
            if (this.state.errorqualifications) return false;
            if (this.state.errorlanguage) return false;
            if (this.state.errorfrequency) return false;
            if (this.state.errorexperience) return false;
            if (this.state.errorresearchArea) return false;
        }
        return true;
    };

    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading={this.state.title}
                    tagline="Kanzuliman Research Publication"
                />
                <div className="container main">
                    <div className="container">
                        {(this.state.currentMode === "editorial" ||
                            this.state.currentMode ===
                                "associate-editorial") && (
                            <>
                                <p>
                                    The KAIJOR welcomes competent experts,
                                    Professionals and academicians to join us as
                                    an {this.state.currentMode} member. Being an{" "}
                                    {this.state.currentMode} member is a matter
                                    of prestige and personal achievement. KAIJOR
                                    follows a strict set of guidelines while
                                    accepting the applicant as a part of
                                    editorial team of KAIJOR. The minimum set of
                                    qualifications for being a part of{" "}
                                    {this.state.currentMode}
                                    team of KAIJOR are listed below
                                </p>
                                <ul>
                                    <li>
                                        Candidate should be a holder of PhD or
                                        equivalent degree from any recognized
                                        university.
                                    </li>
                                    <li>
                                        Candidate should be an author with a
                                        minimum of three publications in reputed
                                        journals.
                                    </li>
                                </ul>
                                <span className="">
                                    Please fill the form below to apply as an
                                    {this.state.currentMode} member of
                                    Kanzuliman Academic Research Journal.
                                </span>
                            </>
                        )}
                        {this.state.currentMode === "reviewer" && (
                            <>
                                <p>
                                    The KAIJOR welcomes competent academicians
                                    to join us as reviewers. Being a reviewer is
                                    a matter of prestige and personal
                                    achievement. KAIJOR follows a strict set of
                                    guidelines while accepting the applicant as
                                    a reviewer of KAIJOR. The minimum set of
                                    qualifications for being a reviewer of
                                    KAIJOR are listed below
                                </p>
                                <ul>
                                    <li>
                                        Candidate should be a holder of Master
                                        or Equivalent degree from a recognized
                                        university.
                                    </li>
                                    <li>
                                        Candiate should be an author with a
                                        minimum of 1 publications in reputed
                                        journals.
                                    </li>
                                </ul>
                                <span className="">
                                    Please fill the form below to apply as an
                                    reviewer member of Kanzuliman Academic
                                    Research Journal.
                                </span>
                            </>
                        )}
                        {this.state.currentMode === "advisory-application" && (
                            <>
                                <p>
                                    The KAIJOR welcomes competent academicians
                                    to join us as a advisory member. Being a
                                    advisory Member is a matter of great
                                    prestige and personal achievement. The
                                    minimum set of elligibility for being a
                                    member of advisory board of KAIJOR is listed
                                    below.
                                </p>
                                <ul>
                                    <li>
                                        Candidate should be at high
                                        proffessional rank such as Head of any
                                        Department of any recognized Institute
                                        or Religious Organisation.
                                    </li>
                                </ul>
                                <span className="">
                                    Please fill the form below to apply as a
                                    advisory board member for KAIJOR. We will
                                    respond to you soon once you submit the
                                    application.
                                </span>
                            </>
                        )}
                    </div>
                    <form className="mt-4">
                        {this.state.showError && (
                            <div className="alert alert-danger" role="alert">
                                Some error has occured, please check your
                                network connection and try again!
                            </div>
                        )}

                        {this.state.showResumeFileError && (
                            <div className="alert alert-danger" role="alert">
                                Please upload a valid Resume file pdf / docx
                            </div>
                        )}

                        <div className="row mt-5">
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(firstName) =>
                                    this.setState({ firstName })
                                }
                                onChange={(errorfirstName) =>
                                    this.setState({ errorfirstName })
                                }
                                label="First Name *"
                                validations={[
                                    [
                                        "required",
                                        "Please provide your first name",
                                    ],
                                    ["min", 2],
                                    ["max", 20],
                                ]}
                                type="text"
                                placeholder="Type in your first name"
                            />
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(lastName) =>
                                    this.setState({ lastName })
                                }
                                onChange={(errorlastName) =>
                                    this.setState({ errorlastName })
                                }
                                label="Last Name *"
                                validations={[
                                    ["required"],
                                    ["min", 2],
                                    ["max", 20],
                                ]}
                                type="text"
                                placeholder="Type in your last name"
                            />
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(email) => this.setState({ email })}
                                onChange={(erroremail) =>
                                    this.setState({ erroremail })
                                }
                                label="Email Address *"
                                validations={[["required"], ["email"]]}
                                type="email"
                                placeholder="Preffered Institutional Email Address"
                            />
                            <Number
                                onNumber={(number) => this.setState({ number })}
                                onCode={(code) => this.setState({ code })}
                                cols="6"
                                validate={this.state.validate}
                                emitError={(errormobile) =>
                                    this.setState({ errormobile })
                                }
                                placeholder="Type in your mobile number"
                            />
                            <Field
                                cols="6"
                                validate={this.state.validate}
                                onData={(affiliation) =>
                                    this.setState({ affiliation })
                                }
                                onChange={(erroraffiliation) =>
                                    this.setState({ erroraffiliation })
                                }
                                label="Affiliation (University/College/Company) *"
                                validations={[
                                    [
                                        "required",
                                        "Please enter your affiliation",
                                    ],
                                ]}
                                type="text"
                                placeholder="Institute / University / College / Company name"
                            />
                            <Field
                                cols="12"
                                validate={this.state.validate}
                                onData={(address) => this.setState({ address })}
                                onChange={(erroraddress) =>
                                    this.setState({ erroraddress })
                                }
                                label="Address *"
                                validations={[["required"]]}
                                type="text"
                                placeholder="Your reachable address"
                            />
                            <Field
                                cols="4"
                                validate={this.state.validate}
                                onData={(city) => this.setState({ city })}
                                onChange={(errorcity) =>
                                    this.setState({ errorcity })
                                }
                                label="City  *"
                                validations={[["required"]]}
                                type="text"
                                placeholder="City you are residing in"
                            />
                            <Field
                                cols="4"
                                validate={this.state.validate}
                                onData={(state) => this.setState({ state })}
                                onChange={(errorstate) =>
                                    this.setState({ errorstate })
                                }
                                label="State *"
                                validations={[["required"]]}
                                type="text"
                                placeholder="State you are residing in"
                            />
                            <Field
                                cols="4"
                                validate={this.state.validate}
                                onData={(PINCode) => this.setState({ PINCode })}
                                onChange={(errorPINCode) =>
                                    this.setState({ errorPINCode })
                                }
                                label="PIN Code *"
                                validations={[["required"]]}
                                type="text"
                                placeholder="Type in your area PIN Code"
                            />
                            <Field
                                cols="12"
                                validate={this.state.validate}
                                onData={(country) => this.setState({ country })}
                                onChange={(errorcountry) =>
                                    this.setState({ errorcountry })
                                }
                                label="Country *"
                                validations={[
                                    ["required", "Please select an option"],
                                ]}
                                type="select"
                                options={countries}
                                value={this.state.country}
                            />
                            {this.state.role !== "advisory-member" && (
                                <>
                                    <Field
                                        cols="6"
                                        validate={this.state.validate}
                                        onData={(qualifications) =>
                                            this.setState({ qualifications })
                                        }
                                        onChange={(errorqualifications) =>
                                            this.setState({
                                                errorqualifications,
                                            })
                                        }
                                        label="Qualifications *"
                                        validations={[["required"], ["min", 2]]}
                                        type="text"
                                        placeholder="Your highest qualification"
                                    />
                                    <Field
                                        cols="6"
                                        validate={this.state.validate}
                                        onData={(designation) =>
                                            this.setState({ designation })
                                        }
                                        onChange={(errordesignation) =>
                                            this.setState({ errordesignation })
                                        }
                                        label="Designation *"
                                        validations={[["required"]]}
                                        type="select"
                                        options={designations}
                                        placeholder="Your current designation / proffessional position"
                                    />
                                    <Field
                                        cols="4"
                                        validate={this.state.validate}
                                        onData={(frequency) =>
                                            this.setState({ frequency })
                                        }
                                        onChange={(errorfrequency) =>
                                            this.setState({ errorfrequency })
                                        }
                                        label={
                                            this.state.isEditor
                                                ? "Frequency of Editorship *"
                                                : "Frequencey of Reviewership *"
                                        }
                                        validations={[
                                            [
                                                "required",
                                                "Please select an option",
                                            ],
                                        ]}
                                        type="select"
                                        options={[
                                            "1 Article per year",
                                            "2 Articles per year",
                                            "3 Articles per year",
                                        ]}
                                    />
                                    <Field
                                        cols="4"
                                        validate={this.state.validate}
                                        onData={(experience) =>
                                            this.setState({ experience })
                                        }
                                        onChange={(errorexperience) =>
                                            this.setState({ errorexperience })
                                        }
                                        label="Total Academic Experience (in years) *"
                                        validations={[
                                            [
                                                "required",
                                                "Please enter your Academic Experience",
                                            ],
                                        ]}
                                        type="number"
                                        placeholder="Your research expeience"
                                    />
                                    <Languages
                                        cols="4"
                                        type={
                                            this.state.isEditor
                                                ? "Editorship"
                                                : "Reviewership"
                                        }
                                        onData={(language) =>
                                            this.setState({ language })
                                        }
                                        validate={this.state.validate}
                                    />
                                    <Field
                                        cols="6"
                                        validate={this.state.validate}
                                        onData={(researchArea) =>
                                            this.setState({ researchArea })
                                        }
                                        onChange={(errorresearchArea) =>
                                            this.setState({ errorresearchArea })
                                        }
                                        label="Research Area *"
                                        validations={[["required"]]}
                                        type="text"
                                        placeholder="Your area of research"
                                    />

                                    <Field
                                        cols="12"
                                        validate={false}
                                        onData={(additionalInformation) =>
                                            this.setState({
                                                additionalInformation,
                                            })
                                        }
                                        onChange={(x) => x}
                                        label="Additional Information"
                                        validations={[]}
                                        type="textarea"
                                        placeholder="Any additional information or comments"
                                    />
                                    <Files
                                        label="Resume / CV *"
                                        onData={(resumeFile) =>
                                            this.setState({ resumeFile })
                                        }
                                    />
                                </>
                            )}
                            <div className="ml-3">
                                <input
                                    type="checkbox"
                                    onChange={(e) =>
                                        this.setState({
                                            termsAndConditionCheck:
                                                e.target.checked,
                                        })
                                    }
                                />
                                <span className="ml-2 text-muted">
                                    I hearby declare that all the information
                                    above is correct in my knowledge and I agree
                                    with the terms and conditions of Kanzuliman
                                    foundation, KAIJOR research publications.
                                </span>
                            </div>
                            <div className="col-12 mt-3 form-group">
                                <button
                                    onClick={this.handleApplicationFromSubmit}
                                    type="submit"
                                    className="btn btn-dark"
                                    disabled={
                                        this.state.disable ||
                                        !this.state.termsAndConditionCheck
                                    }
                                >
                                    {!this.state.showSpinner && (
                                        <span>Join Us</span>
                                    )}
                                    {this.state.showSpinner && (
                                        <i className="fa fa-spinner fa-spin fa-1x" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default ApplicationForm;
