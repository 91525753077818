import React from "react";
import TopNav from "../layout/topnav";
import Header from "../layout/header";
import "./about.css";
import { Link } from "react-router-dom";

class About extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="About"
                    tagline="Kanzuliman Research Publication"
                />
                <h1 className="container w3-animate-zoom mt-5">
                    Welcome to KAIJOR
                </h1>
                <div className="container mt-5 w3-animate-zoom">
                    <p className="w3-animate-opacity">
                        <strong>
                            Kanzuliman Academic International Journal on
                            Razawiyāt{" "}
                        </strong>
                        , is a unique kind of an academic journal that views
                        issues, agendas and knowledge with the lens of
                        Razawiyât. We aim to aid the world of research and
                        development with knowledge that ushers a wave of
                        reception of the work undertaken by{" "}
                        <i>
                            Aalahazrat Imam Ahmad Raza Khan Fazil-e-Barelvi
                            Alaihir’rahma
                        </i>
                        . At
                        <strong>&nbsp;KAIJOR</strong> we ensure the creation of
                        an arena that supplements the knowledge world with a
                        high grade quality of publication of peer reviewed and
                        refereed international journals from diverse fields.
                        KAIJOR’s major objectives are to establish a platform
                        for keen researchers, learned academicians,
                        professional, practitioners and students to generate and
                        create knowledge in the form of well-articulated,
                        quality wise empirical and theoretical research papers,
                        case studies, literature reviews and book reviews.
                    </p>
                    <br />
                    <h3 className="w3-animate-zoom">Journal Description</h3>
                    <p className="w3-animate-opacity">
                        Kanzuliman Research Publication Team presents Kanzuliman
                        Academic International Journal on Razawiyât KAIJOR
                        Online: ISSN: (Applied) I as a Multilingual and Peer
                        Reviewed International Journal publishing per year in
                        Electronic form (E-Journal) and Hard Print (P-Journal).
                        KAIJOR publishes original articles, Research
                        Contribution, Technical Reports and Survey. It is an
                        unparalleled resource for key advances in the field of
                        Islam and Razawiyat. The journals aim to publish a broad
                        range of open access Islamic Journal KAIJOR on the
                        subject of Razawiyāt, eminent editorials from throughout
                        the world, fast online submission, rapid publication,
                        high visibility, expert peer-reviewed research that will
                        serve to create an innovative and constructive platform
                        for information and will enlighten the world with the
                        true message of Sufism and Islamic facts as highlighted
                        by Aalahazrat Imam Ahmad Raza Khan in his writings,
                        ideas and ideology towards Islam based upon the early
                        traditions globally known as Sufism popular with the
                        name Barelwism in the Indian sub-continent.
                    </p>
                    <br />
                    <h3 className="w3-animate-zoom">Call For Papers</h3>
                    <p className="w3-animate-opacity">
                        Kanzuliman Publication Team is delighted to announce
                        Call for Multi-Lingual Research/ Review Papers and Short
                        Communications for the first issue of Kanzuliman
                        Academic International Journal on Razawiyât (KAIJOR) I
                        Online: ISSN: (Applied) I in English, Hindi, Urdu,
                        Persian and Arabic Language.
                    </p>
                    <ul className="mt-3">
                        <li>
                            Submit online at
                            <a
                                href="http://www.research.kanzuliman.org/our-publications/"
                                style={{
                                    color: "blue",
                                    textDecoration: "none",
                                }}
                            >
                                &nbsp;Kanzuliman Publication
                            </a>
                        </li>
                        <li>
                            e-mail
                            <span
                                style={{
                                    color: "blue",
                                    textDecoration: "none",
                                }}
                            >
                                &nbsp;kaijor.kanzuliman@gmail.com{" "}
                            </span>
                        </li>
                        <li>
                            Research Helpline{" "}
                            <span
                                style={{
                                    color: "blue",
                                    textDecoration: "none",
                                }}
                            >
                                +91 9506215324
                            </span>{" "}
                            or
                            <span
                                style={{
                                    color: "blue",
                                    textDecoration: "none",
                                }}
                            >
                                &nbsp;+91 8840593363
                            </span>
                        </li>
                    </ul>
                    <p className="w3-animate-opacity">
                        Kanzuliman Online Publication invites you to submit your
                        work on the subject of Razawiyāt in the form of
                        research, reports, review articles, technical briefs,
                        thesis report or a case study in the relevant research
                        area of the Journal which is Razawiyāt. Authors are also
                        invited to share their work, Kanzuliman Publication will
                        take care to get these works published in the kind
                        interest of the development of Razawiyāt.
                    </p>
                    <br />
                    Initial Submission <strong>Stage First</strong> Open Round
                    the Year (Online or Offline)
                    <ul className="mt-3">
                        <li>
                            Submit Online{" "}
                            <Link
                                to="/authors/online-paper-submission"
                                style={{
                                    color: "blue",
                                    textDecoration: "none",
                                }}
                            >
                                click here
                            </Link>
                        </li>
                        <li>
                            Submit Offline
                            <span
                                style={{
                                    color: "blue",
                                    textDecoration: "none",
                                }}
                            >
                                &nbsp;kaijor.kanzuliman@gmail.com{" "}
                            </span>
                        </li>
                    </ul>
                    Final Submission <strong>Stage Two</strong> Confirmation of
                    All formalities (Pending/Non-Pending) Checklist through
                    intimation email or Personal Phone Call (Open for December
                    only)
                    <ul className="mt-3">
                        <li>
                            Accepted Languages: English, Hindi, Urdu, Persian,
                            Arabic
                        </li>
                        <li>
                            Publication frequency: Single Volume per Year
                            (Containing Language wise 5 issue).
                        </li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default About;
