import React from "react";
import Header from "./layout/header";
import "./home.css";
import TopNav from "./layout/topnav";
import HomeBody from "./homebody";

class Home extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Kanzuliman Research"
                    tagline="Kanzuliman Academic International Journal on Razawiyāt (KAIJOR) world's first academic research journal on the subject of Razawiyāt"
                />
                <HomeBody />
                <div className="container mt-5" />
            </div>
        );
    }
}

export default Home;
