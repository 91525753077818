import React from "react";
import "./header.css";

class Header extends React.Component {
    constructor(props) {
        super();
    }

    componentDidMount() {
        if (this.props.heading !== "Kanzuliman Research") {
            window.scrollTo(0, 700);
        } else {
            window.scrollTo(0, 0);
        }
    }

    render() {
        let style =
            this.props.heading !== "Kanzuliman Research"
                ? "header"
                : "header w3-container w3-center w3-animate-zoom";
        return (
            <div>
                <div className={style}>
                    <div className="text-center">
                        <span
                            className="text-white"
                            style={{
                                fontSize: "55px",
                                fontWeight: "bold",
                            }}
                        >
                            {this.props.heading}
                        </span>
                        <h3 className="text-center text-white">
                            <strong>{this.props.tagline}</strong>
                        </h3>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
