import React from "react";
import Header from "../layout/header";
import TopNav from "../layout/topnav";

class PastPublications extends React.Component {
    render() {
        return (
            <div>
                <TopNav />
                <Header
                    heading="Past Publications"
                    tagline="Kanzuliman Research Publications"
                />
                <div className="container mt-5" />
            </div>
        );
    }
}

export default PastPublications;
