import React, { useState } from "react";
import { Card, Button, Alert, Container } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useHistory } from "react-router-dom";
import TopNav from "../layout/topnav";
import Header from "../layout/header";

export default function Dashboard() {
    const [error, setError] = useState("");
    const { currentUser, logout } = useAuth();
    const history = useHistory();

    async function handleLogout() {
        setError("");

        try {
            await logout();
            history.push("/login");
        } catch {
            setError("Failed to log out");
        }
    }

    return (
        <>
            <TopNav />
            <Header
                heading="Kanzuliman Research"
                tagline="Kanzuliman Academic International Journal on Razawiyāt (KAIJOR) world's first academic research journal on the subject of Razawiyāt"
            />
            <Container>
                <Card>
                    <Card.Body>
                        <h2 className="text-center mb-4">Profile</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <strong>Email:</strong> {currentUser.email}
                        <Link
                            to="/updateprofile"
                            className="btn btn-primary w-100 mt-3"
                        >
                            Update Profile
                        </Link>
                    </Card.Body>
                </Card>
                <div className="w-100 text-center mt-2">
                    <Button
                        variant="link"
                        onClick={handleLogout}
                        className="btn-dark"
                    >
                        Log Out
                    </Button>
                </div>
            </Container>
        </>
    );
}
