import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/circlelogo.png";

class TopNav extends React.Component {
    render() {
        return (
            <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-black top-nav">
                <Link to="/">
                    <a className="navbar-brand" href="a">
                        <img src={logo} className="logo" alt="logo" />
                        KaznulIman Research
                    </a>
                </Link>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                    <ul className="navbar-nav">
                        <Link
                            to="/admin/volunteer"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Volunteers</li>
                        </Link>
                        <Link
                            to="/admin/reviewer"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Reviewers</li>
                        </Link>
                        <Link
                            to="/admin/editorial"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Editorials</li>
                        </Link>
                        <Link
                            to="/admin/sponsor"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Sponsors</li>
                        </Link>
                        <Link
                            to="/admin/associate-editorial"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Associate Editorial</li>
                        </Link>
                        <Link
                            to="/admin/advisory-member"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Advisory</li>
                        </Link>
                        <Link
                            to="/admin/archives"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Archives</li>
                        </Link>
                        <Link
                            to="/admin/events"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Events</li>
                        </Link>
                        <Link
                            to="/admin/contactList"
                            style={{ textDecoration: "none" }}
                        >
                            <li className="nav-link">Contact List</li>
                        </Link>
                    </ul>
                </div>
            </nav>
        );
    }
}

export default TopNav;
